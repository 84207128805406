import React from "react";

import { FormRules } from "@homesusa/form";
import {
  XmlCommunityService,
  XmlListingService,
  XmlPlanService,
  AlertService,
  XmlPanelAlerts
} from "modules/dashboard/interfaces/services";

export const DashboardContext = React.createContext<{
  xmlServices: XmlCommunityService &
    XmlListingService &
    XmlPlanService &
    XmlPanelAlerts;
  alertServices: AlertService;
  marketRules: FormRules;
}>(Object.assign({}));
