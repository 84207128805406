import React, { Fragment } from "react";
import { Box, Typography, Menu, IconButton, Tooltip } from "@mui/material";
import { Send as SendIcon, WatchLater } from "@mui/icons-material";

import { DatePicker } from "@homesusa/form";
import { AppContext, SubmitIconButton } from "@homesusa/layout";
import { DashboardContext } from "modules/dashboard/context/dashboard";
import { useRemoveElemFromGrid } from "modules/dashboard/hooks";
import { AlertType } from "modules/dashboard/enums";
import { classes } from "./list-later.style";

export const ListLaterButton = ({
  listId
}: {
  listId: string;
}): JSX.Element => {
  const {
    xmlServices: { listLater }
  } = React.useContext(DashboardContext);
  const { addAlert } = React.useContext(AppContext);
  const [date, setDate] = React.useState<Date | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const removeElementFromAlertGrid = useRemoveElemFromGrid(
    AlertType.XmlHomesToListNow
  );

  /* methods */
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setDate(undefined);
  };

  const handleChange = (date: Date | undefined): void => {
    setDate(date);
  };

  const tommorrow = React.useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return date;
  }, []);

  return (
    <Fragment>
      <Tooltip title="List Later">
        <IconButton
          color="info"
          onClick={handleClick}
          aria-label="list later"
          aria-haspopup="true"
        >
          <WatchLater />
        </IconButton>
      </Tooltip>

      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        sx={classes.menu}
      >
        <Typography sx={{ mb: 1 }} color={"GrayText"}>
          When?
        </Typography>
        <Box sx={classes.dateForm}>
          <DatePicker
            label="Select a Date"
            required
            onChange={handleChange}
            defaultValue={date}
            minDate={tommorrow}
          />
          <SubmitIconButton
            title="List Later"
            buttonProps={{
              sx: { mt: 0.3 },
              color: "success",
              disabled: !date
            }}
            onClick={async (): Promise<void> => {
              if (date) {
                await listLater(listId, date);
                addAlert({
                  message: "Home was successfully IMPORTED",
                  variant: "success"
                });
                removeElementFromAlertGrid(listId);
                handleClose();
              }
            }}
          >
            <SendIcon />
          </SubmitIconButton>
        </Box>
      </Menu>
    </Fragment>
  );
};
