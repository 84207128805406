import { Format } from "@homesusa/core";
import { Columns } from "@homesusa/grid-table";
import { XmlListing } from "../interfaces/alerts/xml";

export const XmlListingGridColumns: Columns<XmlListing> = [
  {
    Header: "Builder",
    accessor: "builder"
  },
  {
    Header: "Subdivision",
    accessor: "subdivision"
  },
  {
    Header: "Address",
    accessor: (data: XmlListing): string => data.street1,
    id: "street1"
  },
  {
    Header: "Price",
    accessor: (data: XmlListing): string => Format.Money(data.price)
  },
  {
    Header: "Phone",
    accessor: (data: XmlListing): string => data.phone
  },
  {
    Header: "Obtained",
    accessor: (data: XmlListing): string => Format.Date(data.obtainedAt),
    id: "sysCreatedOn"
  }
];
