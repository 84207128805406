import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { FormCommunityRules } from "modules/communities/interfaces/form-community-rules.interface";

import {
  Accessibility,
  AccessibilityLabel,
  Appliances,
  AppliancesLabel,
  ConstructionMaterial,
  ConstructionMaterialLabel,
  CoolingSystem,
  CoolingSystemLabel,
  ExteriorFeatures,
  ExteriorFeaturesLabel,
  Fencing,
  FencingLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Floors,
  FloorsLabel,
  Foundation,
  FoundationLabel,
  GarageDescription,
  GarageDescriptionLabel,
  GreenCertification,
  GreenCertificationLabel,
  GreenWaterFeatures,
  GreenWaterFeaturesLabel,
  HeatSystem,
  HeatSystemLabel,
  HousingStyle,
  HousingStyleLabel,
  InteriorFeatures,
  InteriorFeaturesLabel,
  LaundryLocation,
  LaundryLocationLabel,
  NeighborhoodAmenities,
  NeighborhoodAmenitieslabel,
  PatioAndPorchFeatures,
  PatioAndPorchFeaturesLabel,
  Pool,
  PoolLabel,
  RoofDescription,
  RoofDescriptionLabel,
  SecurityFeatures,
  SecurityFeaturesLabel,
  Utilities,
  UtilitiesLabel
} from "markets/dfw/enums";

export const utilitiesRules: FormRules | FormCommunityRules = {
  garageSpaces: {
    label: "Garage - Number of Spaces",
    requiredToSubmit: true
  },
  garageDescription: {
    label: "Garage Description",
    options: getOptionsFromEnumAndMap(
      Object.values(GarageDescription),
      GarageDescriptionLabel
    ),
    rows: 2,
    lastOptions: [GarageDescription.None],
    noneOption: [GarageDescription.None]
  },
  hasGarage: {
    label: "Garage YN"
  },
  garageWidth: {
    label: "Garage Width"
  },
  garageLength: {
    label: "Garage Length"
  },
  carpotSpaces: {
    label: "Carpot Spaces",
    requiredToSubmit: true
  },
  hasAttachedGarage: {
    label: "Attached Garage YN"
  },
  interiorFeatures: {
    label: "Interior Features",
    options: getOptionsFromEnumAndMap(
      Object.values(InteriorFeatures),
      InteriorFeaturesLabel
    ),
    requiredToSubmit: true
  },
  isSmartHome: {
    label: "Smart Home Features"
  },
  numFireplaces: { label: "Fireplace - Number", requiredToSubmit: true },
  fireplaceDescription: {
    label: "Fireplace Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2,
    requiredToCommunitySubmit: true,
    lastOptions: [FireplaceDescription.None],
    noneOption: [FireplaceDescription.None]
  },
  floors: {
    label: "Flooring",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Floors), FloorsLabel)
  },
  appliances: {
    label: "Appliances",
    options: getOptionsFromEnumAndMap(
      Object.values(Appliances),
      AppliancesLabel
    ),
    lastOptions: [Appliances.None],
    noneOption: [Appliances.None],
    requiredToSubmit: true
  },
  hasAccessibility: {
    label: "Accessibility"
  },
  accessibility: {
    label: "Accessibility Features",
    options: getOptionsFromEnumAndMap(
      Object.values(Accessibility),
      AccessibilityLabel
    )
  },
  securityFeatures: {
    label: "Alarm/Security Type",
    options: getOptionsFromEnumAndMap(
      Object.values(SecurityFeatures),
      SecurityFeaturesLabel
    )
  },
  housingStyle: {
    label: "Housing Style",
    options: getOptionsFromEnumAndMap(
      Object.values(HousingStyle),
      HousingStyleLabel
    )
  },
  exteriorFeatures: {
    label: "Exterior",
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeatures),
      ExteriorFeaturesLabel
    )
  },
  patioAndPorchFeatures: {
    label: "Patio & Porch Features",
    options: getOptionsFromEnumAndMap(
      Object.values(PatioAndPorchFeatures),
      PatioAndPorchFeaturesLabel
    )
  },
  roofDescription: {
    label: "Roof",
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    ),
    requiredToSubmit: true
  },
  foundation: {
    label: "Foundation",
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    ),
    requiredToSubmit: true
  },
  constructionMaterials: {
    label: "Construction",
    options: getOptionsFromEnumAndMap(
      Object.values(ConstructionMaterial),
      ConstructionMaterialLabel
    ),
    requiredToSubmit: true
  },
  fencing: {
    label: "Fence",
    options: getOptionsFromEnumAndMap(Object.values(Fencing), FencingLabel),
    lastOptions: [Fencing.None],
    noneOption: [Fencing.None]
  },
  hasPool: {
    label: "Pool Private"
  },
  pool: {
    label: "Pool Private Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Pool), PoolLabel),
    rows: 2
  },
  utilitiesDescription: {
    label: "Utilities",
    rows: 2,
    options: getOptionsFromEnumAndMap(Object.values(Utilities), UtilitiesLabel),
    requiredToSubmit: true
  },
  hasMudDistrict: {
    label: "MUD District"
  },
  isPropertyInPID: {
    label: "Is Property In PID?"
  },
  isSpecialTaxingAuthority: {
    label: "Special Taxing Authority"
  },
  heatSystem: {
    label: "Heating System",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    ),
    lastOptions: [HeatSystem.None],
    noneOption: [HeatSystem.None]
  },

  greenCertification: {
    label: "Green Energy Features",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenCertification),
      GreenCertificationLabel
    )
  },
  coolingSystem: {
    label: "Cooling",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    )
  },
  greenWater: {
    label: "Green Water Features",
    options: getOptionsFromEnumAndMap(
      Object.values(GreenWaterFeatures),
      GreenWaterFeaturesLabel
    )
  },
  neighborhoodAmenities: {
    label: "Neighborhood Amenities",
    options: getOptionsFromEnumAndMap(
      Object.values(NeighborhoodAmenities),
      NeighborhoodAmenitieslabel
    )
  },
  adultCommunity: {
    label: "Adult Community"
  },
  lotDimension: { label: "Lot Dimensions" },
  laundryFeatures: {
    label: "Laundry Features",
    options: getOptionsFromEnumAndMap(
      Object.values(LaundryLocation),
      LaundryLocationLabel
    )
  }
};
