import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";

import { getGatewayMarket } from "core/utils";

import { XmlListing } from "../interfaces/alerts/xml";
import { ListType } from "../enums";
import { XmlAlertFilter } from "../interfaces";
import { XmlListingService } from "../interfaces/services";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const XmlListingAlertService = (market: MarketCode): XmlListingService => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/xml-listings`;

  return {
    getListings: async (
      params: XmlAlertFilter
    ): Promise<GridResponse<XmlListing>> => {
      const { listingsWithInvalidPlans = false, ...otherParams } = params;
      const response = await axios.get<GridResponse<XmlListing>>(apiUrl, {
        params: { ...otherParams, listingsWithInvalidPlans }
      });
      return response.data;
    },

    processListing: async (id: string, type: ListType): Promise<string> => {
      const result: { data: string } = await axios.post(`${apiUrl}/${id}`, {
        type
      });
      return result.data;
    },

    listLater: async (id: string, listOn: Date): Promise<void> => {
      await axios.patch(`${apiUrl}/${id}`, listOn);
    },

    deleteListing: async (id: string): Promise<void> =>
      axios.delete(`${apiUrl}/${id}`),

    restoreListing: async (id: string): Promise<void> =>
      axios.patch(`${apiUrl}/${id}/restore`)
  };
};

export default XmlListingAlertService;
