import { getOptionsFromEnumAndMap } from "@homesusa/core";
import { FieldRulesProps, FormRules } from "@homesusa/form";
import {
  AcceptableFinancing,
  AcceptableFinancingLabel,
  HoaRequirement,
  HoaRequirementLabel,
  BillingFrequency,
  BillingFrequencyLabel
} from "markets/har/enums";

const moneyRule = (label: string): FieldRulesProps => ({
  label,
  numberFormat: {
    prefix: "$",
    thousandSeparator: true
  }
});

export const buyersAgentCommissionRules: FormRules = {
  buyersAgentCommission: {
    label: "Buyer Agency Compensation",
    requiredToSubmit: true,
    numberFormat: {
      decimalScale: 2
    }
  },
  readableBuyersAgentCommission: { label: "Buyer Agency Compensation" },
  buyersAgentCommissionType: { label: "Buyer Agency Compensation Type" }
};

export const financialRules: FormRules = {
  taxRate: {
    label: "Tax",
    requiredToSubmit: false,
    maxLength: 14,
    numberFormat: {
      min: 0,
      thousandSeparator: true,
      decimalScale: 12
    }
  },
  hoaRequirement: {
    label: "HOA Requirement",
    requiredToSubmit: true,
    lastOptions: [HoaRequirement.No],
    options: getOptionsFromEnumAndMap(
      Object.values(HoaRequirement),
      HoaRequirementLabel
    )
  },
  hoaName: { label: "HOA Name" },
  hoaFee: moneyRule("HOA Fee"),
  billingFrequency: {
    label: "Billing Frequency",
    options: getOptionsFromEnumAndMap(
      Object.values(BillingFrequency),
      BillingFrequencyLabel
    )
  },
  hoaMgmtCo: { label: "HOA Mgmt Co" },
  hoaPhone: { label: "HOA Phone #" },
  hasOtherFees: { label: "Other Mandatory Fees" },
  otherFeeAmount: moneyRule("Other Mandatory Fee Amount"),
  taxYear: {
    label: "Tax Year",
    maxLength: 4
  },
  acceptableFinancing: {
    label: "Financing Considered",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(AcceptableFinancing),
      AcceptableFinancingLabel
    )
  },
  otherFeesInclude: {
    label: "Other Mandatory Fees Include"
  }
};
