import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { LinearProgress } from "@mui/material";

import { AuthCompanyContext } from "@homesusa/auth";
import { getMarketRules } from "markets/utils";
import XmlCommunityAlertService from "../services/xml-community-alerts.service";
import XmlListingAlertService from "../services/xml-listing-alerts.service";
import XmlPlanAlertService from "../services/xml-plan-alerts.service";
import {
  getAlert,
  getAlertsCount,
  markAsResolved
} from "../services/alerts.service";
import { DashboardContext } from "../context/dashboard";
import {
  getAlerts,
  getAlertsByType
} from "modules/dashboard/services/xml-panel-alerts.service";

export const DashboardOutlet = (): JSX.Element => {
  const { currentMarket } = React.useContext(AuthCompanyContext);
  const context = React.useMemo(() => {
    return {
      xmlServices: {
        ...XmlListingAlertService(currentMarket),
        ...XmlCommunityAlertService(currentMarket),
        ...XmlPlanAlertService(currentMarket),
        getAlerts: getAlerts,
        getAlertsByType: getAlertsByType
      },
      alertServices: {
        getAlert: getAlert(currentMarket),
        getAlertsCount: getAlertsCount(currentMarket),
        markAsResolved: markAsResolved(currentMarket)
      },
      marketRules: getMarketRules(currentMarket).formFields
    };
  }, [currentMarket]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <DashboardContext.Provider value={context}>
        <Outlet />
      </DashboardContext.Provider>
    </Suspense>
  );
};
