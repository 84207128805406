export enum RoomFeatures {
  BreakfastArea = "breakfastArea",
  BreakfastBar = "breakfastBar",
  ButlerPantry = "butlerPantry",
  CeilingFans = "ceilingFans",
  CrownMolding = "crownMolding",
  CenterIsland = "centerIsland",
  CorianCounters = "corianCounters",
  DiningArea = "diningArea",
  EatInKitchen = "eatInKitchen",
  GourmetKitchen = "gourmetKitchen",
  GraniteCounters = "graniteCounters",
  OpenToFamilyRoom = "opentoFamilyRoom",
  Pantry = "pantry",
  PlumbedForIcemaker = "plumbedforIcemaker",
  QuartzCounters = "quartzCounters",
  RecessedLighting = "recessedLighting",
  StoneCounters = "stoneCounters",
  TileCounters = "tileCounters",
  TrayCeilings = "trayCeilings",
  VaultedCeilings = "vaultedCeilings",
  ElectricDryerHookup = "electricDryerHookup",
  GasDryerHookup = "gasDryerHookup",
  LaundryChute = "laundryChute",
  LaundrySink = "laundrySink",
  None = "none",
  StackableWDConnections = "stackableConnections",
  WasherHookup = "washerHookup",
  DoubleVanity = "doubleVanity",
  FullBath = "fullBath",
  GardenTub = "gardenTub",
  JettedTub = "jettedTub",
  LowFlowPlumbingFixtures = "lowFlowPlumbingFixtures",
  SeparateShower = "separateShower",
  SeeRemarks = "seeRemarks",
  SoakingTub = "soakingTub",
  HighCeilings = "highCeilings",
  HisHerClosets = "hisHerClosets",
  PrimaryBedroomSittingStudyRoom = "primaryBedroomSittingStudyRoom",
  WalkInClosets = "walkInClosets",
  WalkInShower = "walkInShower",
  WiredForData = "wiredforData",
  WiredForSound = "wiredforSound"
}
export const RoomFeaturesLabel = new Map<RoomFeatures, string>([
  [RoomFeatures.BreakfastArea, "Breakfast Area"],
  [RoomFeatures.BreakfastBar, "Breakfast Bar"],
  [RoomFeatures.ButlerPantry, "Butler Pantry"],
  [RoomFeatures.CenterIsland, "Center Island"],
  [RoomFeatures.CorianCounters, "Corian Counters"],
  [RoomFeatures.DiningArea, "Dining Area"],
  [RoomFeatures.EatInKitchen, "Eat In Kitchen"],
  [RoomFeatures.GourmetKitchen, "Gourmet Kitchen"],
  [RoomFeatures.GraniteCounters, "Granite Counters"],
  [RoomFeatures.OpenToFamilyRoom, "Open to Family Room"],
  [RoomFeatures.Pantry, "Pantry"],
  [RoomFeatures.PlumbedForIcemaker, "Plumbed for Icemaker"],
  [RoomFeatures.QuartzCounters, "Quartz Counters"],
  [RoomFeatures.RecessedLighting, "Recessed Lighting"],
  [RoomFeatures.StoneCounters, "Stone Counters"],
  [RoomFeatures.TileCounters, "Tile Counters"],
  [RoomFeatures.TrayCeilings, "Tray Ceiling(s)"],
  [RoomFeatures.VaultedCeilings, "Vaulted Ceiling(s)"],
  [RoomFeatures.ElectricDryerHookup, "Electric Dryer Hookup"],
  [RoomFeatures.GasDryerHookup, "Gas Dryer Hookup"],
  [RoomFeatures.LaundryChute, "Laundry Chute"],
  [RoomFeatures.LaundrySink, "Laundry Sink"],
  [RoomFeatures.None, "None"],
  [RoomFeatures.StackableWDConnections, "Stackable W/D Connections"],
  [RoomFeatures.WasherHookup, "Washer Hookup"],
  [RoomFeatures.DoubleVanity, "Double Vanity"],
  [RoomFeatures.FullBath, "Full Bath"],
  [RoomFeatures.GardenTub, "Garden Tub"],
  [RoomFeatures.JettedTub, "Jetted Tub"],
  [RoomFeatures.LowFlowPlumbingFixtures, "Low Flow Plumbing Fixtures"],
  [RoomFeatures.SeparateShower, "Separate Shower"],
  [RoomFeatures.SeeRemarks, "See Remarks"],
  [RoomFeatures.SoakingTub, "Soaking Tub"],
  [RoomFeatures.CeilingFans, "Ceiling Fan(s)"],
  [RoomFeatures.CrownMolding, "Crown Molding"],
  [RoomFeatures.HighCeilings, "High Ceilings"],
  [RoomFeatures.HisHerClosets, "His and Hers Closets"],
  [
    RoomFeatures.PrimaryBedroomSittingStudyRoom,
    "Primary Bedroom Sitting/Study Room"
  ],
  [RoomFeatures.WalkInClosets, "Walk-In Closets"],
  [RoomFeatures.WalkInShower, "Walk-In Shower"],
  [RoomFeatures.WiredForData, "Wired for Data"],
  [RoomFeatures.WiredForSound, "Wired for Sound"]
]);

export const KitchenRoomFeatures = [
  RoomFeatures.BreakfastArea,
  RoomFeatures.BreakfastBar,
  RoomFeatures.ButlerPantry,
  RoomFeatures.CenterIsland,
  RoomFeatures.CorianCounters,
  RoomFeatures.DiningArea,
  RoomFeatures.EatInKitchen,
  RoomFeatures.GourmetKitchen,
  RoomFeatures.GraniteCounters,
  RoomFeatures.OpenToFamilyRoom,
  RoomFeatures.Pantry,
  RoomFeatures.PlumbedForIcemaker,
  RoomFeatures.QuartzCounters,
  RoomFeatures.RecessedLighting,
  RoomFeatures.StoneCounters,
  RoomFeatures.SeeRemarks,
  RoomFeatures.TileCounters,
  RoomFeatures.TrayCeilings,
  RoomFeatures.VaultedCeilings
];

export const LaundryRoomFeatures = [
  RoomFeatures.ElectricDryerHookup,
  RoomFeatures.GasDryerHookup,
  RoomFeatures.LaundryChute,
  RoomFeatures.LaundrySink,
  RoomFeatures.None,
  RoomFeatures.StackableWDConnections,
  RoomFeatures.WasherHookup
];
export const PrimaryBathroomFeatures = [
  RoomFeatures.CorianCounters,
  RoomFeatures.DoubleVanity,
  RoomFeatures.FullBath,
  RoomFeatures.GardenTub,
  RoomFeatures.GraniteCounters,
  RoomFeatures.JettedTub,
  RoomFeatures.LowFlowPlumbingFixtures,
  RoomFeatures.QuartzCounters,
  RoomFeatures.SeparateShower,
  RoomFeatures.SeeRemarks,
  RoomFeatures.SoakingTub,
  RoomFeatures.StoneCounters,
  RoomFeatures.TileCounters,
  RoomFeatures.TrayCeilings,
  RoomFeatures.VaultedCeilings,
  RoomFeatures.WalkInShower
];
export const PrimaryBedroomFeatures = [
  RoomFeatures.CeilingFans,
  RoomFeatures.CrownMolding,
  RoomFeatures.HighCeilings,
  RoomFeatures.HisHerClosets,
  RoomFeatures.PrimaryBedroomSittingStudyRoom,
  RoomFeatures.RecessedLighting,
  RoomFeatures.SeeRemarks,
  RoomFeatures.TrayCeilings,
  RoomFeatures.VaultedCeilings,
  RoomFeatures.WalkInClosets,
  RoomFeatures.WiredForData,
  RoomFeatures.WiredForSound
];
