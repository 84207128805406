import React from "react";
import { CheckCircle, InsertDriveFile } from "@mui/icons-material";

import { Columns } from "@homesusa/grid-table";
import { XmlListing } from "../interfaces/alerts/xml";
import { ListType } from "../enums";
import { XmlListingGridColumns } from "../constants";
import {
  ProcessButton,
  DeleteButton,
  ListLaterButton
} from "../components/xml-list-now-buttons";
import { useHasRole } from "@homesusa/auth";
import { RoleEmployee } from "@homesusa/core";

type columnXml = Columns<XmlListing>;

export const useXmlGridColumns = (): columnXml => {
  const { hasEmployeeRole } = useHasRole();
  const isReadOnly = React.useMemo(
    () =>
      hasEmployeeRole([
        RoleEmployee.Readonly,
        RoleEmployee.CompanyAdminReadonly
      ]),
    [hasEmployeeRole]
  );
  const baseColumns = React.useMemo(() => [...XmlListingGridColumns], []);
  const columns = React.useMemo(() => {
    if (isReadOnly) {
      return baseColumns;
    } else {
      return [
        ...baseColumns,
        {
          Header: "Actions",
          disableSortBy: true,
          accessor: (data: XmlListing): string => data.id,
          disableTooltipCell: true,
          Cell: function ListNow({ value }: { value: string }): JSX.Element {
            return (
              <div className="actions">
                <ProcessButton
                  listingId={value}
                  listType={ListType.Now}
                  iconButton={<CheckCircle />}
                  color="success"
                />
                <ListLaterButton listId={value} />
                <ProcessButton
                  listingId={value}
                  listType={ListType.Compare}
                  iconButton={<InsertDriveFile />}
                />
                <DeleteButton listingId={value} />
              </div>
            );
          }
        }
      ];
    }
  }, [isReadOnly, baseColumns]);

  return columns;
};
