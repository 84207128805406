import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  ActiveUnderContract = "activeUnderContract",
  Pending = "pending",
  CompSold = "compSold",
  ComingSoon = "comingSoon",
  Withdrawn = "withdrawn"
}

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.ActiveUnderContract, "Active Under Contract"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.CompSold, "Sold"],
  [MlsStatus.ComingSoon, "Temp Off Market"],
  [MlsStatus.Withdrawn, "Withdrawn"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.ActiveUnderContract, MlsStatusColor.Active],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.CompSold, MlsStatusColor.Sold],
  [MlsStatus.ComingSoon, MlsStatusColor.Withdrawn],
  [MlsStatus.Withdrawn, MlsStatusColor.Withdrawn]
]);

export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.ActiveUnderContract,
  MlsStatus.CompSold
];
export const MlsStatusWithoutStatusFields: MlsStatus[] = [MlsStatus.Active];
