import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { XmlStatus } from "core/enums";
import { approveXmlPlan } from "core/services/xml.services";
import { XmlPlan } from "../interfaces/alerts/xml";
import { AlertBaseFilter } from "../interfaces";
import { XmlPlanService } from "../interfaces/services";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const XmlPlanAlertService = (market: MarketCode): XmlPlanService => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;

  return {
    getAwaitingApprovalPlans: async (
      filter: AlertBaseFilter
    ): Promise<GridResponse<XmlPlan>> => {
      const params = {
        ...filter,
        xmlStatus: XmlStatus.AwaitingApproval
      };
      return axios
        .get<GridResponse<XmlPlan>>(apiUrl, { params })
        .then((response) => response.data);
    },

    approvePlan: approveXmlPlan(market)
  };
};

export default XmlPlanAlertService;
