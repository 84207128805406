import React from "react";
import { Grid } from "@mui/material";

import { AnimatedContainer, useRefresh } from "@homesusa/core";
import { useDocumentTitle } from "@homesusa/layout";
import { AlertSummaryProvider, ChartDashboardProvider } from "../context";
import { LeftPanel, RightPanel } from "./chart-dashboard";
import { XmlAlertsContext } from "../context/xml-alerts";

export const ChartDashboard = (): JSX.Element => {
  useDocumentTitle("Dashboard");
  const [, refreshAlerts] = useRefresh();
  const [hideRemineders, refreshReminders] = useRefresh();
  return (
    <ChartDashboardProvider>
      <AnimatedContainer>
        <XmlAlertsContext.Provider value={{ refreshAlerts, refreshReminders }}>
          <AlertSummaryProvider>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <LeftPanel />
              </Grid>
              {!hideRemineders && (
                <Grid item xs={8}>
                  <RightPanel />
                </Grid>
              )}
            </Grid>
          </AlertSummaryProvider>
        </XmlAlertsContext.Provider>
      </AnimatedContainer>
    </ChartDashboardProvider>
  );
};
