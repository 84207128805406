import axios from "axios";

import { GridResponse } from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";
import { XmlStatus } from "core/enums";
import { approveXmlCommunity } from "core/services/xml.services";
import { XmlSubdivision } from "../interfaces/alerts/xml";
import { AlertBaseFilter } from "../interfaces";
import { XmlCommunityService } from "../interfaces/services";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

const XmlCommunityAlertService = (market: MarketCode): XmlCommunityService => {
  const gatewayMarket = getGatewayMarket(market);
  const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/sale-communities`;
  return {
    getAwaitingApprovalCommunities: async (
      filter: AlertBaseFilter
    ): Promise<GridResponse<XmlSubdivision>> => {
      const params = {
        ...filter,
        xmlStatus: XmlStatus.AwaitingApproval
      };
      return axios
        .get<GridResponse<XmlSubdivision>>(apiUrl, { params })
        .then((response) => response.data);
    },
    approveCommunity: approveXmlCommunity(market)
  };
};

export default XmlCommunityAlertService;
