import {
  getOptionsFromEnumAndMap,
  getOptionsFromEnumAndRecord
} from "@homesusa/core";
import { FormRules } from "@homesusa/form";
import { FormCommunityRules } from "modules/communities/interfaces/form-community-rules.interface";
import {
  CoolingSystem,
  CoolingSystemLabel,
  ExteriorFeatures,
  ExteriorFeaturesLabel,
  Fencing,
  FencingLabel,
  FireplaceDescription,
  FireplaceDescriptionLabel,
  Foundation,
  FoundationLabel,
  HeatSystem,
  HeatSystemLabel,
  InteriorFeatures,
  InteriorFeaturesLabel,
  LaundryLocation,
  LaundryLocationLabel,
  LaundryFeatures,
  LaundryFeaturesLabel,
  Pool,
  PoolLabel,
  RoofDescription,
  RoofDescriptionLabel,
  ConstructionType,
  ConstructionTypeLabel,
  CommunityFeatures,
  CommunityFeaturesLabel,
  WaterFeatures,
  WaterFeaturesLabel,
  WaterSewerFeatures,
  WaterSeweerFeaturesLabel,
  WaterHeaterDescription,
  WaterHeaterDescriptionLabel
} from "markets/amarillo/enums";

export const utilitiesRules: FormRules | FormCommunityRules = {
  interiorFeatures: {
    label: "Interior Features",
    options: getOptionsFromEnumAndMap(
      Object.values(InteriorFeatures),
      InteriorFeaturesLabel
    )
  },
  numFireplaces: {
    label: "Fireplace - Number",
    numberFormat: {
      max: 5
    },
    maxLength: 1
  },
  fireplaceDescription: {
    label: "Fireplace Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(
      Object.values(FireplaceDescription),
      FireplaceDescriptionLabel
    ),
    rows: 2,
    lastOptions: [FireplaceDescription.None],
    noneOption: [FireplaceDescription.None],
    max: 5
  },
  laundryFeatures: {
    label: "Laundry Features",
    options: getOptionsFromEnumAndMap(
      Object.values(LaundryFeatures),
      LaundryFeaturesLabel
    )
  },
  laundryLocation: {
    label: "Laundry location",
    options: getOptionsFromEnumAndRecord(
      Object.values(LaundryLocation),
      LaundryLocationLabel
    ),
    noneOption: [LaundryLocation.None],
    lastOptions: [LaundryLocation.None],
    max: 2
  },
  garageSpaces: {
    label: "Garage Stall",
    requiredToSubmit: true,
    numberFormat: {
      max: 10
    },
    maxLength: 2
  },
  hasAttachedGarage: {
    label: "Attached Garage YN"
  },
  hasDetachedGarage: {
    label: "Detached Garage"
  },
  exteriorFeatures: {
    label: "Exterior",
    options: getOptionsFromEnumAndMap(
      Object.values(ExteriorFeatures),
      ExteriorFeaturesLabel
    )
  },
  fence: {
    label: "Fence",
    options: getOptionsFromEnumAndRecord(Object.values(Fencing), FencingLabel),
    lastOptions: [Fencing.None],
    noneOption: [Fencing.None],
    max: 2
  },
  roofDescription: {
    label: "Roof",
    options: getOptionsFromEnumAndMap(
      Object.values(RoofDescription),
      RoofDescriptionLabel
    ),
    max: 2
  },
  constructionType: {
    label: "Construction Type",
    options: getOptionsFromEnumAndRecord(
      Object.values(ConstructionType),
      ConstructionTypeLabel
    ),
    max: 2
  },
  foundation: {
    label: "Foundation",
    options: getOptionsFromEnumAndMap(
      Object.values(Foundation),
      FoundationLabel
    ),
    requiredToSubmit: true
  },
  pool: {
    label: "Pool",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndMap(Object.values(Pool), PoolLabel),
    rows: 2,
    noneOption: [Pool.None],
    lastOptions: [Pool.None]
  },
  communityFeatures: {
    label: "Community Features",
    options: getOptionsFromEnumAndRecord(
      Object.values(CommunityFeatures),
      CommunityFeaturesLabel
    ),
    rows: 4,
    max: 3,
    noneOption: [CommunityFeatures.None],
    lastOptions: [CommunityFeatures.None]
  },
  otherCommunityFeatures: {
    label: "Other Community Features",
    maxLength: 300
  },
  waterFeature: {
    label: "Water Features",
    options: getOptionsFromEnumAndRecord(
      Object.values(WaterFeatures),
      WaterFeaturesLabel
    )
  },
  waterSewer: {
    label: "Water Sewer",
    options: getOptionsFromEnumAndRecord(
      Object.values(WaterSewerFeatures),
      WaterSeweerFeaturesLabel
    ),
    lastOptions: [WaterSewerFeatures.None],
    noneOption: [WaterSewerFeatures.None]
  },
  heatSystem: {
    label: "Heating System",
    options: getOptionsFromEnumAndMap(
      Object.values(HeatSystem),
      HeatSystemLabel
    ),
    lastOptions: [HeatSystem.None],
    noneOption: [HeatSystem.None],
    max: 3
  },
  coolingSystem: {
    label: "A/C",
    options: getOptionsFromEnumAndMap(
      Object.values(CoolingSystem),
      CoolingSystemLabel
    ),
    max: 3,
    noneOption: [CoolingSystem.None],
    lastOptions: [CoolingSystem.None]
  },
  waterHeaterDescription: {
    label: "Water Heater Description",
    requiredToSubmit: true,
    options: getOptionsFromEnumAndRecord(
      Object.values(WaterHeaterDescription),
      WaterHeaterDescriptionLabel
    ),
    noneOption: [WaterHeaterDescription.None],
    lastOptions: [WaterHeaterDescription.None]
  },
  waterHeater: {
    label: "Water Heater"
  },
  annualPIDFee: {
    label: "Annual PID Fee"
  }
};
