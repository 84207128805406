export enum MiddleSchool {
  NineThgrade = "nineThgrade",
  AMConsolidated = "amConsolidated",
  Abbott = "abbott",
  Acton = "acton",
  AdWheat = "adWheat",
  Adams = "adams",
  Agnew = "agnew",
  Aguadulce = "aguadulce",
  Alabama = "alabama",
  Alaska = "alaska",
  Albagolden = "albagolden",
  Aledo = "aledo",
  Allen = "allen",
  Alpine = "alpine",
  Alter = "alter",
  Alto = "alto",
  Alvord = "alvord",
  Anahuac = "anahuac",
  Anderson = "anderson",
  Andrews = "andrews",
  Anguilla = "anguilla",
  AnnRichards = "annRichards",
  Anna = "anna",
  Anson = "anson",
  Anthony = "anthony",
  Aquilla = "aquilla",
  ArborCreek = "arborCreek",
  Archercity = "archercity",
  Argyle = "argyle",
  Arizona = "arizona",
  Arkansas = "arkansas",
  ArmandoChapa = "armandoChapa",
  Armstrong = "armstrong",
  Arnold = "arnold",
  ArthurLDavila = "arthurLDavila",
  Aruba = "aruba",
  Ash = "ash",
  Aspermont = "aspermont",
  Athens = "athens",
  Atkins = "atkins",
  Atlanta = "atlanta",
  Atwell = "atwell",
  Aubrey = "aubrey",
  Austin = "austin",
  Australia = "australia",
  Austwellti = "austwellti",
  Avalon = "avalon",
  Avery = "avery",
  Avinger = "avinger",
  Axtell = "axtell",
  Bahamas = "bahamas",
  Baird = "baird",
  Baker = "baker",
  BalchSprings = "balchSprings",
  Balmorhea = "balmorhea",
  Bangs = "bangs",
  Barbados = "barbados",
  Barnes = "barnes",
  Bartlett = "bartlett",
  Barwise = "barwise",
  Bassett = "bassett",
  Bastrop = "bastrop",
  Baycity = "baycity",
  Belize = "belize",
  Bellevue = "bellevue",
  Belton = "belton",
  Benbrook = "benbrook",
  Benjamin = "benjamin",
  Berry = "berry",
  BesseColeman = "besseColeman",
  BettyeMyers = "bettyeMyers",
  BillHays = "billHays",
  Black = "black",
  Blackwell = "blackwell",
  Blalack = "blalack",
  Blanco = "blanco",
  Bland = "bland",
  Blanket = "blanket",
  Bloomburg = "bloomburg",
  Blueridge = "blueridge",
  Bluffdale = "bluffdale",
  Blum = "blum",
  BobbyRayAftonMartin = "bobbyRayAftonMartin",
  BobbySummers = "bobbySummers",
  Boerne = "boerne",
  Boggess = "boggess",
  Boles = "boles",
  Bonham = "bonham",
  BordenCountySchool = "bordenCountySchool",
  Borger = "borger",
  BorgerIntermediate = "borgerIntermediate",
  BorgerMiddle = "borgerMiddle",
  BorgfeldArea = "borgfeldArea",
  Bosqueville = "bosqueville",
  Boulter = "boulter",
  Bowie = "bowie",
  Bowman = "bowman",
  Boyd = "boyd",
  Bradley = "bradley",
  Brady = "brady",
  Brazil = "brazil",
  Bremond = "bremond",
  Brentwood = "brentwood",
  Brewer = "brewer",
  Briarhill = "briarhill",
  Bridgeport = "bridgeport",
  Briesemeis = "briesemeis",
  Brock = "brock",
  Bronte = "bronte",
  Brookeland = "brookeland",
  Brookesmith = "brookesmith",
  Brown = "brown",
  Browne = "browne",
  Brownfield = "brownfield",
  Brownsville = "brownsville",
  Brownwood = "brownwood",
  Brundrett = "brundrett",
  Bryson = "bryson",
  BuenaVistaSchool = "buenaVistaSchool",
  Bulgaria = "bulgaria",
  Bullard = "bullard",
  Burkburnett = "burkburnett",
  Burkeville = "burkeville",
  Burnet = "burnet",
  Bush = "bush",
  Bushland = "bushland",
  Bynum = "bynum",
  Byrd = "byrd",
  Caddomills = "caddomills",
  Cain = "cain",
  Calallen = "calallen",
  Caldwell = "caldwell",
  Calhoun = "calhoun",
  California = "california",
  Callisburg = "callisburg",
  Calvert = "calvert",
  Caminoreal = "caminoreal",
  Campbell = "campbell",
  Canada = "canada",
  Canutillo = "canutillo",
  Canyon = "canyon",
  CanyonIntermedJrHigh = "canyonIntermedJrHigh",
  CanyonRidge = "canyonRidge",
  Canyonhill = "canyonhill",
  Canyonvist = "canyonvist",
  Carlisle = "carlisle",
  Carpenter = "carpenter",
  Carroll = "carroll",
  Cary = "cary",
  CaymanIslands = "caymanIslands",
  Cayuga = "cayuga",
  Cedarcreek = "cedarcreek",
  Cedarpark = "cedarpark",
  Cedarvalle = "cedarvalle",
  Center = "center",
  Central = "central",
  CentralHeights = "centralHeights",
  Centralhei = "centralhei",
  Chapelhill = "chapelhill",
  CharleneMcKinzey = "charleneMcKinzey",
  Charles = "charles",
  Chavez = "chavez",
  Cheatham = "cheatham",
  Chico = "chico",
  Chile = "chile",
  Chillicoth = "chillicoth",
  Chinasprng = "chinasprng",
  Chireno = "chireno",
  Chisholmtr = "chisholmtr",
  Chisum = "chisum",
  ChoiceOfSchool = "choiceOfSchool",
  Christen = "christen",
  ChurchHill = "churchHill",
  Cigarroa = "cigarroa",
  Clack = "clack",
  Clark = "clark",
  Clarke = "clarke",
  Claude = "claude",
  ClaytonDowning = "claytonDowning",
  Cleveland = "cleveland",
  Clifton = "clifton",
  Cobb = "cobb",
  Cokesteven = "cokesteven",
  Collegesta = "collegesta",
  Colleyvill = "colleyvill",
  Collins = "collins",
  Colorado = "colorado",
  Comanche = "comanche",
  Comfort = "comfort",
  Commerce = "commerce",
  Community = "community",
  Comopickto = "comopickto",
  Comstock = "comstock",
  Connecticut = "connecticut",
  Connell = "connell",
  Coolidge = "coolidge",
  Coppelleas = "coppelleas",
  Coppellnor = "coppellnor",
  Coppellwes = "coppellwes",
  Cordova = "cordova",
  CostaRica = "costaRica",
  Covington = "covington",
  Craig = "craig",
  Crandall = "crandall",
  Cranfillsg = "cranfillsg",
  Crawford = "crawford",
  CreekValley = "creekValley",
  Creekview = "creekview",
  Crenshaw = "crenshaw",
  Crockett = "crockett",
  Crosby = "crosby",
  CrossPlains = "crossPlains",
  Crosstimbe = "crosstimbe",
  Crowley = "crowley",
  Crownover = "crownover",
  Cullen = "cullen",
  Cumby = "cumby",
  Cunningham = "cunningham",
  Curtis = "curtis",
  CurtisteneSMccowan = "curtisteneSMccowan",
  Dade = "dade",
  Daggett = "daggett",
  Davis = "davis",
  Dawson = "dawson",
  DeZavala = "deZavala",
  Decalb = "decalb",
  Decatur = "decatur",
  Deerpark = "deerpark",
  DelValle = "delValle",
  Delay = "delay",
  Delrio = "delrio",
  Desertview = "desertview",
  DesiertoBello = "desiertoBello",
  DesotoEast = "desotoEast",
  DesotoWest = "desotoWest",
  Dessau = "dessau",
  Devine = "devine",
  Dezavala = "dezavala",
  Dieterich = "dieterich",
  Doddcity = "doddcity",
  Dogan = "dogan",
  DominicanRepublic = "dominicanRepublic",
  Douglasben = "douglasben",
  Douglass = "douglass",
  Dowell = "dowell",
  DrJackCockrill = "drJackCockrill",
  DrippingSprings = "drippingSprings",
  Driscoll = "driscoll",
  Dublin = "dublin",
  Dunbar = "dunbar",
  Dwight = "dwight",
  Early = "early",
  Eastcentra = "eastcentra",
  Easternhil = "easternhil",
  Eastland = "eastland",
  Eastmontan = "eastmontan",
  Eastside = "eastside",
  Eastwood = "eastwood",
  Ector = "ector",
  EdWillkie = "edWillkie",
  Eden = "eden",
  Edgewood = "edgewood",
  Edison = "edison",
  EdwardsJohnsonMemorial = "edwardsJohnsonMemorial",
  Eisenhower = "eisenhower",
  ElSalvador = "elSalvador",
  Elder = "elder",
  Elkhart = "elkhart",
  Elysianfie = "elysianfie",
  Ensor = "ensor",
  Era = "era",
  Ereckson = "ereckson",
  Eustace = "eustace",
  Evans = "evans",
  Fabens = "fabens",
  Fairway = "fairway",
  Fannin = "fannin",
  Fannindell = "fannindell",
  Faubion = "faubion",
  Field = "field",
  Florence = "florence",
  Florida = "florida",
  Forbes = "forbes",
  Ford = "ford",
  ForestOak = "forestOak",
  Forestburg = "forestburg",
  Forestwood = "forestwood",
  Forney = "forney",
  ForrestPark = "forrestPark",
  FortDavis = "fortDavis",
  FortStockton = "fortStockton",
  Fossilhill = "fossilhill",
  Foster = "foster",
  Fowler = "fowler",
  France = "france",
  FrankSeale = "frankSeale",
  FrankSeales = "frankSeales",
  Frankford = "frankford",
  Franklin = "franklin",
  Frankston = "frankston",
  Fredericks = "fredericks",
  Frost = "frost",
  Fruitvale = "fruitvale",
  Fulkes = "fulkes",
  Furlough = "furlough",
  GalenaPark = "galenaPark",
  Garcia = "garcia",
  Garner = "garner",
  Garrison = "garrison",
  Gary = "gary",
  Gaston = "gaston",
  Georgetown = "georgetown",
  Georgia = "georgia",
  Germany = "germany",
  Gholson = "gholson",
  Gladewater = "gladewater",
  Glencrest = "glencrest",
  Godley = "godley",
  Goldburg = "goldburg",
  Goldthwaite = "goldthwaite",
  Goliad = "goliad",
  Gordon = "gordon",
  Gorman = "gorman",
  Graford = "graford",
  Granbury = "granbury",
  Grandsalin = "grandsalin",
  Grant = "grant",
  Grapevine = "grapevine",
  Greenville = "greenville",
  GreenwaysWestover = "greenwaysWestover",
  Greiner = "greiner",
  Griffin = "griffin",
  Grisham = "grisham",
  Groesbeck = "groesbeck",
  Groves = "groves",
  Grulla = "grulla",
  Guatemala = "guatemala",
  Guillen = "guillen",
  Gunter = "gunter",
  Gustine = "gustine",
  HWLang = "hwLang",
  Haasl = "haasl",
  Haggard = "haggard",
  Hall = "hall",
  Hallsville = "hallsville",
  Haltom = "haltom",
  Hamilton = "hamilton",
  Hamlin = "hamlin",
  Handley = "handley",
  HappyJuniorHigh = "happyJuniorHigh",
  Harlandale = "harlandale",
  Harper = "harper",
  Harris = "harris",
  Hartsbluff = "hartsbluff",
  Hawkins = "hawkins",
  Hawley = "hawley",
  Hedrick = "hedrick",
  Hemphill = "hemphill",
  Henderson = "henderson",
  Hendrick = "hendrick",
  Henry = "henry",
  HenryScott = "henryScott",
  Heritage = "heritage",
  Herman = "herman",
  HermanEUtley = "hermanEUtley",
  Hermliegh = "hermliegh",
  Highland = "highland",
  HighlandPark = "highlandPark",
  Highlandm = "highlandm",
  Highlandpa = "highlandpa",
  Hill = "hill",
  HillCountry = "hillCountry",
  Hillcrest = "hillcrest",
  Hillwood = "hillwood",
  Hogg = "hogg",
  Holliday = "holliday",
  Holloway = "holloway",
  Holmes = "holmes",
  Honduras = "honduras",
  Honeygrove = "honeygrove",
  Hood = "hood",
  Hopewell = "hopewell",
  Horizon = "horizon",
  Hornedo = "hornedo",
  Hotchkiss = "hotchkiss",
  Houston = "houston",
  Howard = "howard",
  Howe = "howe",
  Hubbard = "hubbard",
  Huckabay = "huckabay",
  Hudson = "hudson",
  HudsonBend = "hudsonBend",
  Huffines = "huffines",
  Hughes = "hughes",
  Hulcy = "hulcy",
  Humble = "humble",
  Hunt = "hunt",
  Hutchinson = "hutchinson",
  Hutto = "hutto",
  Idaho = "idaho",
  Illinois = "illinois",
  Incarnate = "incarnate",
  Indiana = "indiana",
  Indianridg = "indianridg",
  Indianspri = "indianspri",
  Indonesia = "indonesia",
  Ingram = "ingram",
  Intermedia = "intermedia",
  Iredell = "iredell",
  Irion = "irion",
  Irons = "irons",
  Irving = "irving",
  Italy = "italy",
  JMartinJacquet = "jMartinJacquet",
  Jackson = "jackson",
  Jacksonvil = "jacksonvil",
  Jamacia = "jamacia",
  James = "james",
  JamesCoble = "jamesCoble",
  Jamison = "jamison",
  Jarrell = "jarrell",
  JeanMcclung = "jeanMcclung",
  Jefferson = "jefferson",
  JerryLindaMoore = "jerryLindaMoore",
  JerryWalker = "jerryWalker",
  JimNed = "jimNed",
  JjWessendorff = "jjWessendorff",
  JohnMTidwell = "johnMTidwell",
  Johnson = "johnson",
  Jones = "jones",
  Jonesboro = "jonesboro",
  JoseJAlderete = "joseJAlderete",
  JuanDeDiosSalinas = "juanDeDiosSalinas",
  Judson = "judson",
  Junction = "junction",
  JWWilliams = "jwWilliams",
  Kaffie = "kaffie",
  Kansas = "kansas",
  Karnack = "karnack",
  Kazen = "kazen",
  KeeferCrossing = "keeferCrossing",
  Keller = "keller",
  Kenedy = "kenedy",
  Kennard = "kennard",
  Kennedy = "kennedy",
  Kennemer = "kennemer",
  Kentucky = "kentucky",
  Kerens = "kerens",
  Kerr = "kerr",
  Killian = "killian",
  Kimbrough = "kimbrough",
  King = "king",
  Kingsborou = "kingsborou",
  Kirby = "kirby",
  Kirkpatric = "kirkpatric",
  Kittyhawk = "kittyhawk",
  Kopperl = "kopperl",
  Kountze = "kountze",
  Krueger = "krueger",
  Krum = "krum",
  LaGrange = "laGrange",
  LaVillita = "laVillita",
  Labay = "labay",
  LadyBirdJohnson = "ladyBirdJohnson",
  Lagovista = "lagovista",
  LakeBelton = "lakeBelton",
  LakeDallas = "lakeDallas",
  LakeTravis = "lakeTravis",
  Lakeside = "lakeside",
  Lakeview = "lakeview",
  Lamar = "lamar",
  Lampasas = "lampasas",
  Lancaster = "lancaster",
  Laneville = "laneville",
  Lantana = "lantana",
  Lapoyner = "lapoyner",
  Latexo = "latexo",
  Lawler = "lawler",
  Leal = "leal",
  Leander = "leander",
  Lee = "lee",
  LelandEdge = "lelandEdge",
  LeoAdams = "leoAdams",
  LeonSablatura = "leonSablatura",
  Leonard = "leonard",
  Lexington = "lexington",
  Liberty = "liberty",
  LibertyEylau = "libertyEylau",
  Libertyhil = "libertyhil",
  Lincoln = "lincoln",
  LindaJobe = "lindaJobe",
  Lindsay = "lindsay",
  Linglevill = "linglevill",
  Lipan = "lipan",
  Liveoakrid = "liveoakrid",
  Loflin = "loflin",
  Lohn = "lohn",
  Lometa = "lometa",
  LoneStar = "loneStar",
  Loneoak = "loneoak",
  Long = "long",
  Longfellow = "longfellow",
  Lorena = "lorena",
  LoreneRogers = "loreneRogers",
  Loretto = "loretto",
  LosCuates = "losCuates",
  Louisiana = "louisiana",
  Lovejoy = "lovejoy",
  Lowell = "lowell",
  LowellSmith = "lowellSmith",
  Lowery = "lowery",
  Lowrance = "lowrance",
  LubbockCooper = "lubbockCooper",
  LucyRedeFranco = "lucyRedeFranco",
  Lufkin = "lufkin",
  LyndonBJohnson = "lyndonBJohnson",
  LynnLucas = "lynnLucas",
  MacarioGarcia = "macarioGarcia",
  Mackenzie = "mackenzie",
  Madison = "madison",
  Magoffin = "magoffin",
  Maine = "maine",
  Malakoff = "malakoff",
  Malone = "malone",
  MancePark = "mancePark",
  Mann = "mann",
  Manor = "manor",
  Marblefall = "marblefall",
  Marietta = "marietta",
  MarineCreek = "marineCreek",
  Marlin = "marlin",
  Marsh = "marsh",
  MarshallDurham = "marshallDurham",
  Mart = "mart",
  Martinsmil = "martinsmil",
  Maryland = "maryland",
  Massachusetts = "massachusetts",
  Maus = "maus",
  May = "may",
  MaynardJackson = "maynardJackson",
  Mcallister = "mcallister",
  Mccarroll = "mccarroll",
  Mcdonald = "mcdonald",
  Mckamy = "mckamy",
  Mclean = "mclean",
  Mcmath = "mcmath",
  Mcnair = "mcnair",
  Mcneil = "mcneil",
  Meacham = "meacham",
  Meadowbroo = "meadowbroo",
  MedinaValley = "medinaValley",
  Medinavall = "medinavall",
  Medlin = "medlin",
  Medrano = "medrano",
  Melissa = "melissa",
  Memorial = "memorial",
  Memphis = "memphis",
  Meridian = "meridian",
  Merkel = "merkel",
  Mexico = "mexico",
  Michigan = "michigan",
  Midlothian = "midlothian",
  Midway = "midway",
  Mildred = "mildred",
  Milford = "milford",
  Millergrov = "millergrov",
  Millsap = "millsap",
  Mineola = "mineola",
  Mineralwel = "mineralwel",
  Mississippi = "mississippi",
  Missouri = "missouri",
  MoisesVela = "moisesVela",
  Monnig = "monnig",
  Montague = "montague",
  Montana = "montana",
  Montgomery = "montgomery",
  Montwood = "montwood",
  Moody = "moody",
  MooreMstMagnet = "mooreMstMagnet",
  Moran = "moran",
  Morehead = "morehead",
  Morgan = "morgan",
  MorganMil = "morganMil",
  Morganmill = "morganmill",
  Morningsid = "morningsid",
  MotleyCountySchool = "motleyCountySchool",
  MountEnterprise = "mountEnterprise",
  MtValley = "mtValley",
  Mtcalm = "mtcalm",
  Muenster = "muenster",
  Murphy = "murphy",
  Nacogdoches = "nacogdoches",
  Nacona = "nacona",
  Navo = "navo",
  Neches = "neches",
  Nelson = "nelson",
  Nevada = "nevada",
  New = "new",
  NewBoston = "newBoston",
  NewHome = "newHome",
  NewMexico = "newMexico",
  NewSummerfield = "newSummerfield",
  NewYork = "newYork",
  Newbraunfe = "newbraunfe",
  Newcastle = "newcastle",
  Newdiana = "newdiana",
  Newton = "newton",
  Nicaragua = "nicaragua",
  Nimitz = "nimitz",
  Nocona = "nocona",
  Nolan = "nolan",
  Norichland = "norichland",
  Normangee = "normangee",
  NorthCarolina = "northCarolina",
  NorthDakota = "northDakota",
  NorthShore = "northShore",
  Northhopki = "northhopki",
  Northoaks = "northoaks",
  Northridge = "northridge",
  NtreisTestOnly = "ntreisTestOnly",
  Nueces = "nueces",
  Oakrun = "oakrun",
  Oakwood = "oakwood",
  Obrien = "obrien",
  Odom = "odom",
  Oglesby = "oglesby",
  Ohio = "ohio",
  Oklahoma = "oklahoma",
  Olfen = "olfen",
  Orecity = "orecity",
  Oregon = "oregon",
  Other = "other",
  Otto = "otto",
  OuidaBaley = "ouidaBaley",
  Overton = "overton",
  Ozona = "ozona",
  Paducah = "paducah",
  Page = "page",
  PaintCreek = "paintCreek",
  Palestine = "palestine",
  PaloPinto = "paloPinto",
  Paloalto = "paloalto",
  Panama = "panama",
  Panhandle = "panhandle",
  PantherCreek = "pantherCreek",
  Paradise = "paradise",
  Parkland = "parkland",
  PatHaganCheek = "patHaganCheek",
  PattonSprings = "pattonSprings",
  Pearson = "pearson",
  Peaster = "peaster",
  Penelope = "penelope",
  Pennsylvania = "pennsylvania",
  Perkins = "perkins",
  Permenter = "permenter",
  Perrin = "perrin",
  Perry = "perry",
  PerrytonJuniorHigh = "perrytonJuniorHigh",
  Pershing = "pershing",
  Peru = "peru",
  Peterson = "peterson",
  Pettus = "pettus",
  Pike = "pike",
  PilotPoint = "pilotPoint",
  Piner = "piner",
  Pinetree = "pinetree",
  PinnacleRandallJrHigh = "pinnacleRandallJrHigh",
  PinnacleWestover = "pinnacleWestover",
  Pioneer = "pioneer",
  Pittsburg = "pittsburg",
  PleasantGrove = "pleasantGrove",
  Poe = "poe",
  Polk = "polk",
  Ponder = "ponder",
  PortIsabel = "portIsabel",
  PortNeches = "portNeches",
  Post = "post",
  Pottsboro = "pottsboro",
  PrairieLea = "prairieLea",
  PrairieVa = "prairieVa",
  PrairieVista = "prairieVista",
  Prairiland = "prairiland",
  Prestwick = "prestwick",
  Priddy = "priddy",
  PuertoRico = "puertoRico",
  Quintenill = "quintenill",
  ROharaLanier = "rOharaLanier",
  Ranchland = "ranchland",
  Rancier = "rancier",
  Randolph = "randolph",
  Ranger = "ranger",
  Rawlinson = "rawlinson",
  Rayburn = "rayburn",
  Raynes = "raynes",
  Reagan = "reagan",
  RedLick = "redLick",
  RedOak = "redOak",
  Redwater = "redwater",
  Reed = "reed",
  Refugio = "refugio",
  Renner = "renner",
  Reynolds = "reynolds",
  Rhodes = "rhodes",
  Rice = "rice",
  RichardAllie = "richardAllie",
  Richards = "richards",
  Richardson = "richardson",
  Richland = "richland",
  Ridgeview = "ridgeview",
  Ringgold = "ringgold",
  RioVista = "rioVista",
  Riobravo = "riobravo",
  RisingStar = "risingStar",
  RiverRoad = "riverRoad",
  Riverside = "riverside",
  Roach = "roach",
  RobertHill = "robertHill",
  RobertLee = "robertLee",
  Robinson = "robinson",
  Roby = "roby",
  Rochester = "rochester",
  Rockportfu = "rockportfu",
  Rocksprings = "rocksprings",
  Rodriguez = "rodriguez",
  Rogers = "rogers",
  Roscoe = "roscoe",
  RosebudLott = "rosebudLott",
  Rosemont = "rosemont",
  Rossl = "rossl",
  Roxton = "roxton",
  RoyseCity = "royseCity",
  Rule = "rule",
  Runningbru = "runningbru",
  Rusk = "rusk",
  SAndS = "sAndS",
  Sabinal = "sabinal",
  Sabine = "sabine",
  Saintjo = "saintjo",
  Saltillo = "saltillo",
  SamTasby = "samTasby",
  Samrayburn = "samrayburn",
  SanAugustine = "sanAugustine",
  SanSaba = "sanSaba",
  Sanchez = "sanchez",
  Sanelizaro = "sanelizaro",
  Sanger = "sanger",
  Santo = "santo",
  Savoy = "savoy",
  Schimelpfe = "schimelpfe",
  Schultz = "schultz",
  Scoggins = "scoggins",
  Scotland = "scotland",
  Scurry = "scurry",
  Seadrift = "seadrift",
  Seagoville = "seagoville",
  Seymour = "seymour",
  ShadowRidge = "shadowRidge",
  Shepard = "shepard",
  Shepherd = "shepherd",
  Sherman = "sherman",
  Sidney = "sidney",
  SierraBlanca = "sierraBlanca",
  Sivellsven = "sivellsven",
  Slaton = "slaton",
  Slidell = "slidell",
  Slider = "slider",
  SloanCreek = "sloanCreek",
  Slocum = "slocum",
  Smith = "smith",
  Smithfield = "smithfield",
  Smithsonva = "smithsonva",
  SmylieWilson = "smylieWilson",
  Snook = "snook",
  Socorro = "socorro",
  SouthAfrica = "southAfrica",
  SouthDakota = "southDakota",
  Southard = "southard",
  Southpark = "southpark",
  Southside = "southside",
  Spain = "spain",
  Spence = "spence",
  Springbran = "springbran",
  Springhill = "springhill",
  Springoaks = "springoaks",
  Springtown = "springtown",
  Spur = "spur",
  StLucia = "stLucia",
  StVincent = "stVincent",
  Stafford = "stafford",
  Staley = "staley",
  Stamford = "stamford",
  Stevens = "stevens",
  Stewart = "stewart",
  Stinson = "stinson",
  Stockard = "stockard",
  Stone = "stone",
  Stonypoint = "stonypoint",
  Storey = "storey",
  Strawn = "strawn",
  Strickland = "strickland",
  Stripling = "stripling",
  Sulphurblu = "sulphurblu",
  Sulphurspr = "sulphurspr",
  SummerCreek = "summerCreek",
  Sunnyvale = "sunnyvale",
  Sunridge = "sunridge",
  Sweetwater = "sweetwater",
  Tafolla = "tafolla",
  Tasby = "tasby",
  Tatum = "tatum",
  Taylor = "taylor",
  Tejeda = "tejeda",
  Telles = "telles",
  Tennessee = "tennessee",
  TerraVista = "terraVista",
  TerraceHi = "terraceHi",
  Terrellwel = "terrellwel",
  Terry = "terry",
  Texas = "texas",
  ThePhilippines = "thePhilippines",
  Thompson = "thompson",
  Thrall = "thrall",
  ThreeLakes = "threeLakes",
  ThreeWay = "threeWay",
  Threeriver = "threeriver",
  Threeway = "threeway",
  Throckmort = "throckmort",
  Timberview = "timberview",
  Timberwood = "timberwood",
  Timpson = "timpson",
  Tioga = "tioga",
  Tippit = "tippit",
  Tison = "tison",
  Tolar = "tolar",
  TomBean = "tomBean",
  TomHarpool = "tomHarpool",
  Tornillo = "tornillo",
  Travis = "travis",
  Trent = "trent",
  Trenton = "trenton",
  Trinidad = "trinidad",
  TrinitySprings = "trinitySprings",
  Troup = "troup",
  Troy = "troy",
  Truman = "truman",
  Tulosomidw = "tulosomidw",
  Twain = "twain",
  Unionhill = "unionhill",
  Upchurch = "upchurch",
  Valley = "valley",
  Valleyview = "valleyview",
  Vandeventer = "vandeventer",
  Vanston = "vanston",
  Vega = "vega",
  Venus = "venus",
  Veribest = "veribest",
  Vernon = "vernon",
  Veterans = "veterans",
  Vidor = "vidor",
  Virginia = "virginia",
  VistaRidge = "vistaRidge",
  WFGeorge = "wfGeorge",
  Waco = "waco",
  Walker = "walker",
  Wallace = "wallace",
  WalnutGrove = "walnutGrove",
  WalnutSp = "walnutSp",
  Walnutbend = "walnutbend",
  Warren = "warren",
  Washington = "washington",
  Waskom = "waskom",
  Watauga = "watauga",
  Wayside = "wayside",
  Wedgwood = "wedgwood",
  Weis = "weis",
  Wells = "wells",
  West = "west",
  WestRidge = "westRidge",
  WestSabine = "westSabine",
  WestTexas = "westTexas",
  WestVirginia = "westVirginia",
  Westbrook = "westbrook",
  Wester = "wester",
  Westwood = "westwood",
  Wheatley = "wheatley",
  Wheeler = "wheeler",
  White = "white",
  Whiteoak = "whiteoak",
  Whitesboro = "whitesboro",
  Whitewrigh = "whitewrigh",
  Whitney = "whitney",
  Whittier = "whittier",
  Wiggs = "wiggs",
  Wildorado = "wildorado",
  Wilkinson = "wilkinson",
  WilliamRushing = "williamRushing",
  Williams = "williams",
  WilliamsIntermediate = "williamsIntermediate",
  WillowSprings = "willowSprings",
  WillowVistRiverRd = "willowVistRiverRd",
  Willspoint = "willspoint",
  Wilson = "wilson",
  Windthorst = "windthorst",
  Winfield = "winfield",
  Winona = "winona",
  Wolfecity = "wolfecity",
  Wood = "wood",
  Woodlakehi = "woodlakehi",
  WoodlandAcres = "woodlandAcres",
  Woodson = "woodson",
  Woodville = "woodville",
  Worley = "worley",
  Wortham = "wortham",
  Wrenn = "wrenn",
  Wylie = "wylie",
  Wyoming = "wyoming",
  Yantis = "yantis",
  Young = "young",
  Ysleta = "ysleta",
  Zephyr = "zephyr",
  Zumwalt = "zumwalt",
  SlayterCreek = "slayterCreek"
}

export const MiddleSchoolLabels: Record<MiddleSchool, string> = {
  [MiddleSchool.NineThgrade]: "9Thgrade",
  [MiddleSchool.AMConsolidated]: "A & M Consolidated",
  [MiddleSchool.Abbott]: "Abbott",
  [MiddleSchool.Acton]: "Acton",
  [MiddleSchool.AdWheat]: "Ad Wheat",
  [MiddleSchool.Adams]: "Adams",
  [MiddleSchool.Agnew]: "Agnew",
  [MiddleSchool.Aguadulce]: "Aguadulce",
  [MiddleSchool.Alabama]: "Alabama",
  [MiddleSchool.Alaska]: "Alaska",
  [MiddleSchool.Albagolden]: "Albagolden",
  [MiddleSchool.Aledo]: "Aledo",
  [MiddleSchool.Allen]: "Allen",
  [MiddleSchool.Alpine]: "Alpine",
  [MiddleSchool.Alter]: "Alter",
  [MiddleSchool.Alto]: "Alto",
  [MiddleSchool.Alvord]: "Alvord",
  [MiddleSchool.Anahuac]: "Anahuac",
  [MiddleSchool.Anderson]: "Anderson",
  [MiddleSchool.Andrews]: "Andrews",
  [MiddleSchool.Anguilla]: "Anguilla",
  [MiddleSchool.AnnRichards]: "Ann Richards",
  [MiddleSchool.Anna]: "Anna",
  [MiddleSchool.Anson]: "Anson",
  [MiddleSchool.Anthony]: "Anthony",
  [MiddleSchool.Aquilla]: "Aquilla",
  [MiddleSchool.ArborCreek]: "Arbor Creek",
  [MiddleSchool.Archercity]: "Archercity",
  [MiddleSchool.Argyle]: "Argyle",
  [MiddleSchool.Arizona]: "Arizona",
  [MiddleSchool.Arkansas]: "Arkansas",
  [MiddleSchool.ArmandoChapa]: "Armando Chapa",
  [MiddleSchool.Armstrong]: "Armstrong",
  [MiddleSchool.Arnold]: "Arnold",
  [MiddleSchool.ArthurLDavila]: "Arthur L Davila",
  [MiddleSchool.Aruba]: "Aruba",
  [MiddleSchool.Ash]: "Ash",
  [MiddleSchool.Aspermont]: "Aspermont",
  [MiddleSchool.Athens]: "Athens",
  [MiddleSchool.Atkins]: "Atkins",
  [MiddleSchool.Atlanta]: "Atlanta",
  [MiddleSchool.Atwell]: "Atwell",
  [MiddleSchool.Aubrey]: "Aubrey",
  [MiddleSchool.Austin]: "Austin",
  [MiddleSchool.Australia]: "Australia",
  [MiddleSchool.Austwellti]: "Austwellti",
  [MiddleSchool.Avalon]: "Avalon",
  [MiddleSchool.Avery]: "Avery",
  [MiddleSchool.Avinger]: "Avinger",
  [MiddleSchool.Axtell]: "Axtell",
  [MiddleSchool.Bahamas]: "Bahamas",
  [MiddleSchool.Baird]: "Baird",
  [MiddleSchool.Baker]: "Baker",
  [MiddleSchool.BalchSprings]: "Balch Springs",
  [MiddleSchool.Balmorhea]: "Balmorhea",
  [MiddleSchool.Bangs]: "Bangs",
  [MiddleSchool.Barbados]: "Barbados",
  [MiddleSchool.Barnes]: "Barnes",
  [MiddleSchool.Bartlett]: "Bartlett",
  [MiddleSchool.Barwise]: "Barwise",
  [MiddleSchool.Bassett]: "Bassett",
  [MiddleSchool.Bastrop]: "Bastrop",
  [MiddleSchool.Baycity]: "Baycity",
  [MiddleSchool.Belize]: "Belize",
  [MiddleSchool.Bellevue]: "Bellevue",
  [MiddleSchool.Belton]: "Belton",
  [MiddleSchool.Benbrook]: "Benbrook",
  [MiddleSchool.Benjamin]: "Benjamin",
  [MiddleSchool.Berry]: "Berry",
  [MiddleSchool.BesseColeman]: "Besse Coleman",
  [MiddleSchool.BettyeMyers]: "Bettye Myers",
  [MiddleSchool.BillHays]: "Bill Hays",
  [MiddleSchool.Black]: "Black",
  [MiddleSchool.Blackwell]: "Blackwell",
  [MiddleSchool.Blalack]: "Blalack",
  [MiddleSchool.Blanco]: "Blanco",
  [MiddleSchool.Bland]: "Bland",
  [MiddleSchool.Blanket]: "Blanket",
  [MiddleSchool.Bloomburg]: "Bloomburg",
  [MiddleSchool.Blueridge]: "Blueridge",
  [MiddleSchool.Bluffdale]: "Bluffdale",
  [MiddleSchool.Blum]: "Blum",
  [MiddleSchool.BobbyRayAftonMartin]: "Bobby Ray- Afton Martin",
  [MiddleSchool.BobbySummers]: "Bobby Summers",
  [MiddleSchool.Boerne]: "Boerne",
  [MiddleSchool.Boggess]: "Boggess",
  [MiddleSchool.Boles]: "Boles",
  [MiddleSchool.Bonham]: "Bonham",
  [MiddleSchool.BordenCountySchool]: "Borden County School",
  [MiddleSchool.Borger]: "Borger",
  [MiddleSchool.BorgerIntermediate]: "Borger Intermediate",
  [MiddleSchool.BorgerMiddle]: "Borger Middle",
  [MiddleSchool.BorgfeldArea]: "Borgfeld Area",
  [MiddleSchool.Bosqueville]: "Bosqueville",
  [MiddleSchool.Boulter]: "Boulter",
  [MiddleSchool.Bowie]: "Bowie",
  [MiddleSchool.Bowman]: "Bowman",
  [MiddleSchool.Boyd]: "Boyd",
  [MiddleSchool.Bradley]: "Bradley",
  [MiddleSchool.Brady]: "Brady",
  [MiddleSchool.Brazil]: "Brazil",
  [MiddleSchool.Bremond]: "Bremond",
  [MiddleSchool.Brentwood]: "Brentwood",
  [MiddleSchool.Brewer]: "Brewer",
  [MiddleSchool.Briarhill]: "Briarhill",
  [MiddleSchool.Bridgeport]: "Bridgeport",
  [MiddleSchool.Briesemeis]: "Briesemeis",
  [MiddleSchool.Brock]: "Brock",
  [MiddleSchool.Bronte]: "Bronte",
  [MiddleSchool.Brookeland]: "Brookeland",
  [MiddleSchool.Brookesmith]: "Brookesmith",
  [MiddleSchool.Brown]: "Brown",
  [MiddleSchool.Browne]: "Browne",
  [MiddleSchool.Brownfield]: "Brownfield",
  [MiddleSchool.Brownsville]: "Brownsville",
  [MiddleSchool.Brownwood]: "Brownwood",
  [MiddleSchool.Brundrett]: "Brundrett",
  [MiddleSchool.Bryson]: "Bryson",
  [MiddleSchool.BuenaVistaSchool]: "Buena Vista School",
  [MiddleSchool.Bulgaria]: "Bulgaria",
  [MiddleSchool.Bullard]: "Bullard",
  [MiddleSchool.Burkburnett]: "Burkburnett",
  [MiddleSchool.Burkeville]: "Burkeville",
  [MiddleSchool.Burnet]: "Burnet",
  [MiddleSchool.Bush]: "Bush",
  [MiddleSchool.Bushland]: "Bushland",
  [MiddleSchool.Bynum]: "Bynum",
  [MiddleSchool.Byrd]: "Byrd",
  [MiddleSchool.Caddomills]: "Caddomills",
  [MiddleSchool.Cain]: "Cain",
  [MiddleSchool.Calallen]: "Calallen",
  [MiddleSchool.Caldwell]: "Caldwell",
  [MiddleSchool.Calhoun]: "Calhoun",
  [MiddleSchool.California]: "California",
  [MiddleSchool.Callisburg]: "Callisburg",
  [MiddleSchool.Calvert]: "Calvert",
  [MiddleSchool.Caminoreal]: "Caminoreal",
  [MiddleSchool.Campbell]: "Campbell",
  [MiddleSchool.Canada]: "Canada",
  [MiddleSchool.Canutillo]: "Canutillo",
  [MiddleSchool.Canyon]: "Canyon",
  [MiddleSchool.CanyonIntermedJrHigh]: "Canyon Intermed./Jr High",
  [MiddleSchool.CanyonRidge]: "Canyon Ridge",
  [MiddleSchool.Canyonhill]: "Canyonhill",
  [MiddleSchool.Canyonvist]: "Canyonvist",
  [MiddleSchool.Carlisle]: "Carlisle",
  [MiddleSchool.Carpenter]: "Carpenter",
  [MiddleSchool.Carroll]: "Carroll",
  [MiddleSchool.Cary]: "Cary",
  [MiddleSchool.CaymanIslands]: "Cayman Islands",
  [MiddleSchool.Cayuga]: "Cayuga",
  [MiddleSchool.Cedarcreek]: "Cedarcreek",
  [MiddleSchool.Cedarpark]: "Cedarpark",
  [MiddleSchool.Cedarvalle]: "Cedarvalle",
  [MiddleSchool.Center]: "Center",
  [MiddleSchool.Central]: "Central",
  [MiddleSchool.CentralHeights]: "Central Heights",
  [MiddleSchool.Centralhei]: "Centralhei",
  [MiddleSchool.Chapelhill]: "Chapelhill",
  [MiddleSchool.CharleneMcKinzey]: "Charlene McKinzey",
  [MiddleSchool.Charles]: "Charles",
  [MiddleSchool.Chavez]: "Chavez",
  [MiddleSchool.Cheatham]: "Cheatham",
  [MiddleSchool.Chico]: "Chico",
  [MiddleSchool.Chile]: "Chile",
  [MiddleSchool.Chillicoth]: "Chillicoth",
  [MiddleSchool.Chinasprng]: "Chinasprng",
  [MiddleSchool.Chireno]: "Chireno",
  [MiddleSchool.Chisholmtr]: "Chisholmtr",
  [MiddleSchool.Chisum]: "Chisum",
  [MiddleSchool.ChoiceOfSchool]: "Choice Of School",
  [MiddleSchool.Christen]: "Christen",
  [MiddleSchool.ChurchHill]: "Church Hill",
  [MiddleSchool.Cigarroa]: "Cigarroa",
  [MiddleSchool.Clack]: "Clack",
  [MiddleSchool.Clark]: "Clark",
  [MiddleSchool.Clarke]: "Clarke",
  [MiddleSchool.Claude]: "Claude",
  [MiddleSchool.ClaytonDowning]: "Clayton Downing",
  [MiddleSchool.Cleveland]: "Cleveland",
  [MiddleSchool.Clifton]: "Clifton",
  [MiddleSchool.Cobb]: "Cobb",
  [MiddleSchool.Cokesteven]: "Cokesteven",
  [MiddleSchool.Collegesta]: "Collegesta",
  [MiddleSchool.Colleyvill]: "Colleyvill",
  [MiddleSchool.Collins]: "Collins",
  [MiddleSchool.Colorado]: "Colorado",
  [MiddleSchool.Comanche]: "Comanche",
  [MiddleSchool.Comfort]: "Comfort",
  [MiddleSchool.Commerce]: "Commerce",
  [MiddleSchool.Community]: "Community",
  [MiddleSchool.Comopickto]: "Comopickto",
  [MiddleSchool.Comstock]: "Comstock",
  [MiddleSchool.Connecticut]: "Connecticut",
  [MiddleSchool.Connell]: "Connell",
  [MiddleSchool.Coolidge]: "Coolidge",
  [MiddleSchool.Coppelleas]: "Coppelleas",
  [MiddleSchool.Coppellnor]: "Coppellnor",
  [MiddleSchool.Coppellwes]: "Coppellwes",
  [MiddleSchool.Cordova]: "Cordova",
  [MiddleSchool.CostaRica]: "Costa Rica",
  [MiddleSchool.Covington]: "Covington",
  [MiddleSchool.Craig]: "Craig",
  [MiddleSchool.Crandall]: "Crandall",
  [MiddleSchool.Cranfillsg]: "Cranfillsg",
  [MiddleSchool.Crawford]: "Crawford",
  [MiddleSchool.CreekValley]: "Creek Valley",
  [MiddleSchool.Creekview]: "Creekview",
  [MiddleSchool.Crenshaw]: "Crenshaw",
  [MiddleSchool.Crockett]: "Crockett",
  [MiddleSchool.Crosby]: "Crosby",
  [MiddleSchool.CrossPlains]: "Cross Plains",
  [MiddleSchool.Crosstimbe]: "Crosstimbe",
  [MiddleSchool.Crowley]: "Crowley",
  [MiddleSchool.Crownover]: "Crownover",
  [MiddleSchool.Cullen]: "Cullen",
  [MiddleSchool.Cumby]: "Cumby",
  [MiddleSchool.Cunningham]: "Cunningham",
  [MiddleSchool.Curtis]: "Curtis",
  [MiddleSchool.CurtisteneSMccowan]: "Curtistene S Mccowan",
  [MiddleSchool.Dade]: "Dade",
  [MiddleSchool.Daggett]: "Daggett",
  [MiddleSchool.Davis]: "Davis",
  [MiddleSchool.Dawson]: "Dawson",
  [MiddleSchool.DeZavala]: "de Zavala",
  [MiddleSchool.Decalb]: "Decalb",
  [MiddleSchool.Decatur]: "Decatur",
  [MiddleSchool.Deerpark]: "Deerpark",
  [MiddleSchool.DelValle]: "Del Valle",
  [MiddleSchool.Delay]: "Delay",
  [MiddleSchool.Delrio]: "Delrio",
  [MiddleSchool.Desertview]: "Desertview",
  [MiddleSchool.DesiertoBello]: "Desierto Bello",
  [MiddleSchool.DesotoEast]: "Desoto East",
  [MiddleSchool.DesotoWest]: "Desoto West",
  [MiddleSchool.Dessau]: "Dessau",
  [MiddleSchool.Devine]: "Devine",
  [MiddleSchool.Dezavala]: "Dezavala",
  [MiddleSchool.Dieterich]: "Dieterich",
  [MiddleSchool.Doddcity]: "Doddcity",
  [MiddleSchool.Dogan]: "Dogan",
  [MiddleSchool.DominicanRepublic]: "Dominican Republic",
  [MiddleSchool.Douglasben]: "Douglasben",
  [MiddleSchool.Douglass]: "Douglass",
  [MiddleSchool.Dowell]: "Dowell",
  [MiddleSchool.DrJackCockrill]: "Dr Jack Cockrill",
  [MiddleSchool.DrippingSprings]: "Dripping Springs",
  [MiddleSchool.Driscoll]: "Driscoll",
  [MiddleSchool.Dublin]: "Dublin",
  [MiddleSchool.Dunbar]: "Dunbar",
  [MiddleSchool.Dwight]: "Dwight",
  [MiddleSchool.Early]: "Early",
  [MiddleSchool.Eastcentra]: "Eastcentra",
  [MiddleSchool.Easternhil]: "Easternhil",
  [MiddleSchool.Eastland]: "Eastland",
  [MiddleSchool.Eastmontan]: "Eastmontan",
  [MiddleSchool.Eastside]: "Eastside",
  [MiddleSchool.Eastwood]: "Eastwood",
  [MiddleSchool.Ector]: "Ector",
  [MiddleSchool.EdWillkie]: "Ed Willkie",
  [MiddleSchool.Eden]: "Eden",
  [MiddleSchool.Edgewood]: "Edgewood",
  [MiddleSchool.Edison]: "Edison",
  [MiddleSchool.EdwardsJohnsonMemorial]: "Edwards-Johnson Memorial",
  [MiddleSchool.Eisenhower]: "Eisenhower",
  [MiddleSchool.ElSalvador]: "El Salvador",
  [MiddleSchool.Elder]: "Elder",
  [MiddleSchool.Elkhart]: "Elkhart",
  [MiddleSchool.Elysianfie]: "Elysianfie",
  [MiddleSchool.Ensor]: "Ensor",
  [MiddleSchool.Era]: "Era",
  [MiddleSchool.Ereckson]: "Ereckson",
  [MiddleSchool.Eustace]: "Eustace",
  [MiddleSchool.Evans]: "Evans",
  [MiddleSchool.Fabens]: "Fabens",
  [MiddleSchool.Fairway]: "Fairway",
  [MiddleSchool.Fannin]: "Fannin",
  [MiddleSchool.Fannindell]: "Fannindell",
  [MiddleSchool.Faubion]: "Faubion",
  [MiddleSchool.Field]: "Field",
  [MiddleSchool.Florence]: "Florence",
  [MiddleSchool.Florida]: "Florida",
  [MiddleSchool.Forbes]: "Forbes",
  [MiddleSchool.Ford]: "Ford",
  [MiddleSchool.ForestOak]: "Forest Oak",
  [MiddleSchool.Forestburg]: "Forestburg",
  [MiddleSchool.Forestwood]: "Forestwood",
  [MiddleSchool.Forney]: "Forney",
  [MiddleSchool.ForrestPark]: "Forrest Park",
  [MiddleSchool.FortDavis]: "Fort Davis",
  [MiddleSchool.FortStockton]: "Fort Stockton",
  [MiddleSchool.Fossilhill]: "Fossilhill",
  [MiddleSchool.Foster]: "Foster",
  [MiddleSchool.Fowler]: "Fowler",
  [MiddleSchool.France]: "France",
  [MiddleSchool.FrankSeale]: "Frank Seale",
  [MiddleSchool.FrankSeales]: "Frank Seales",
  [MiddleSchool.Frankford]: "Frankford",
  [MiddleSchool.Franklin]: "Franklin",
  [MiddleSchool.Frankston]: "Frankston",
  [MiddleSchool.Fredericks]: "Fredericks",
  [MiddleSchool.Frost]: "Frost",
  [MiddleSchool.Fruitvale]: "Fruitvale",
  [MiddleSchool.Fulkes]: "Fulkes",
  [MiddleSchool.Furlough]: "Furlough",
  [MiddleSchool.GalenaPark]: "Galena Park",
  [MiddleSchool.Garcia]: "Garcia",
  [MiddleSchool.Garner]: "Garner",
  [MiddleSchool.Garrison]: "Garrison",
  [MiddleSchool.Gary]: "Gary",
  [MiddleSchool.Gaston]: "Gaston",
  [MiddleSchool.Georgetown]: "Georgetown",
  [MiddleSchool.Georgia]: "Georgia",
  [MiddleSchool.Germany]: "Germany",
  [MiddleSchool.Gholson]: "Gholson",
  [MiddleSchool.Gladewater]: "Gladewater",
  [MiddleSchool.Glencrest]: "Glencrest",
  [MiddleSchool.Godley]: "Godley",
  [MiddleSchool.Goldburg]: "Goldburg",
  [MiddleSchool.Goldthwaite]: "Goldthwaite",
  [MiddleSchool.Goliad]: "Goliad",
  [MiddleSchool.Gordon]: "Gordon",
  [MiddleSchool.Gorman]: "Gorman",
  [MiddleSchool.Graford]: "Graford",
  [MiddleSchool.Granbury]: "Granbury",
  [MiddleSchool.Grandsalin]: "Grandsalin",
  [MiddleSchool.Grant]: "Grant",
  [MiddleSchool.Grapevine]: "Grapevine",
  [MiddleSchool.Greenville]: "Greenville",
  [MiddleSchool.GreenwaysWestover]: "Greenways/Westover",
  [MiddleSchool.Greiner]: "Greiner",
  [MiddleSchool.Griffin]: "Griffin",
  [MiddleSchool.Grisham]: "Grisham",
  [MiddleSchool.Groesbeck]: "Groesbeck",
  [MiddleSchool.Groves]: "Groves",
  [MiddleSchool.Grulla]: "Grulla",
  [MiddleSchool.Guatemala]: "Guatemala",
  [MiddleSchool.Guillen]: "Guillen",
  [MiddleSchool.Gunter]: "Gunter",
  [MiddleSchool.Gustine]: "Gustine",
  [MiddleSchool.HWLang]: "H.W. Lang",
  [MiddleSchool.Haasl]: "Haasl",
  [MiddleSchool.Haggard]: "Haggard",
  [MiddleSchool.Hall]: "Hall",
  [MiddleSchool.Hallsville]: "Hallsville",
  [MiddleSchool.Haltom]: "Haltom",
  [MiddleSchool.Hamilton]: "Hamilton",
  [MiddleSchool.Hamlin]: "Hamlin",
  [MiddleSchool.Handley]: "Handley",
  [MiddleSchool.HappyJuniorHigh]: "Happy Junior High",
  [MiddleSchool.Harlandale]: "Harlandale",
  [MiddleSchool.Harper]: "Harper",
  [MiddleSchool.Harris]: "Harris",
  [MiddleSchool.Hartsbluff]: "Hartsbluff",
  [MiddleSchool.Hawkins]: "Hawkins",
  [MiddleSchool.Hawley]: "Hawley",
  [MiddleSchool.Hedrick]: "Hedrick",
  [MiddleSchool.Hemphill]: "Hemphill",
  [MiddleSchool.Henderson]: "Henderson",
  [MiddleSchool.Hendrick]: "Hendrick",
  [MiddleSchool.Henry]: "Henry",
  [MiddleSchool.HenryScott]: "Henry Scott",
  [MiddleSchool.Heritage]: "Heritage",
  [MiddleSchool.Herman]: "Herman",
  [MiddleSchool.HermanEUtley]: "Herman E Utley",
  [MiddleSchool.Hermliegh]: "Hermliegh",
  [MiddleSchool.Highland]: "Highland",
  [MiddleSchool.HighlandPark]: "Highland Park",
  [MiddleSchool.Highlandm]: "Highlandm",
  [MiddleSchool.Highlandpa]: "Highlandpa",
  [MiddleSchool.Hill]: "Hill",
  [MiddleSchool.HillCountry]: "Hill Country",
  [MiddleSchool.Hillcrest]: "Hillcrest",
  [MiddleSchool.Hillwood]: "Hillwood",
  [MiddleSchool.Hogg]: "Hogg",
  [MiddleSchool.Holliday]: "Holliday",
  [MiddleSchool.Holloway]: "Holloway",
  [MiddleSchool.Holmes]: "Holmes",
  [MiddleSchool.Honduras]: "Honduras",
  [MiddleSchool.Honeygrove]: "Honeygrove",
  [MiddleSchool.Hood]: "Hood",
  [MiddleSchool.Hopewell]: "Hopewell",
  [MiddleSchool.Horizon]: "Horizon",
  [MiddleSchool.Hornedo]: "Hornedo",
  [MiddleSchool.Hotchkiss]: "Hotchkiss",
  [MiddleSchool.Houston]: "Houston",
  [MiddleSchool.Howard]: "Howard",
  [MiddleSchool.Howe]: "Howe",
  [MiddleSchool.Hubbard]: "Hubbard",
  [MiddleSchool.Huckabay]: "Huckabay",
  [MiddleSchool.Hudson]: "Hudson",
  [MiddleSchool.HudsonBend]: "Hudson Bend",
  [MiddleSchool.Huffines]: "Huffines",
  [MiddleSchool.Hughes]: "Hughes",
  [MiddleSchool.Hulcy]: "Hulcy",
  [MiddleSchool.Humble]: "Humble",
  [MiddleSchool.Hunt]: "Hunt",
  [MiddleSchool.Hutchinson]: "Hutchinson",
  [MiddleSchool.Hutto]: "Hutto",
  [MiddleSchool.Idaho]: "Idaho",
  [MiddleSchool.Illinois]: "Illinois",
  [MiddleSchool.Incarnate]: "Incarnate",
  [MiddleSchool.Indiana]: "Indiana",
  [MiddleSchool.Indianridg]: "Indianridg",
  [MiddleSchool.Indianspri]: "Indianspri",
  [MiddleSchool.Indonesia]: "Indonesia",
  [MiddleSchool.Ingram]: "Ingram",
  [MiddleSchool.Intermedia]: "Intermedia",
  [MiddleSchool.Iredell]: "Iredell",
  [MiddleSchool.Irion]: "Irion",
  [MiddleSchool.Irons]: "Irons",
  [MiddleSchool.Irving]: "Irving",
  [MiddleSchool.Italy]: "Italy",
  [MiddleSchool.JMartinJacquet]: "J Martin Jacquet",
  [MiddleSchool.Jackson]: "Jackson",
  [MiddleSchool.Jacksonvil]: "Jacksonvil",
  [MiddleSchool.Jamacia]: "Jamacia",
  [MiddleSchool.James]: "James",
  [MiddleSchool.JamesCoble]: "James Coble",
  [MiddleSchool.Jamison]: "Jamison",
  [MiddleSchool.Jarrell]: "Jarrell",
  [MiddleSchool.JeanMcclung]: "Jean Mcclung",
  [MiddleSchool.Jefferson]: "Jefferson",
  [MiddleSchool.JerryLindaMoore]: "Jerry & Linda Moore",
  [MiddleSchool.JerryWalker]: "Jerry Walker",
  [MiddleSchool.JimNed]: "Jim Ned",
  [MiddleSchool.JjWessendorff]: "Jj Wessendorff",
  [MiddleSchool.JohnMTidwell]: "John M Tidwell",
  [MiddleSchool.Johnson]: "Johnson",
  [MiddleSchool.Jones]: "Jones",
  [MiddleSchool.Jonesboro]: "Jonesboro",
  [MiddleSchool.JoseJAlderete]: "Jose J Alderete",
  [MiddleSchool.JuanDeDiosSalinas]: "Juan De Dios Salinas",
  [MiddleSchool.Judson]: "Judson",
  [MiddleSchool.Junction]: "Junction",
  [MiddleSchool.JWWilliams]: "JW Williams",
  [MiddleSchool.Kaffie]: "Kaffie",
  [MiddleSchool.Kansas]: "Kansas",
  [MiddleSchool.Karnack]: "Karnack",
  [MiddleSchool.Kazen]: "Kazen",
  [MiddleSchool.KeeferCrossing]: "Keefer Crossing",
  [MiddleSchool.Keller]: "Keller",
  [MiddleSchool.Kenedy]: "Kenedy",
  [MiddleSchool.Kennard]: "Kennard",
  [MiddleSchool.Kennedy]: "Kennedy",
  [MiddleSchool.Kennemer]: "Kennemer",
  [MiddleSchool.Kentucky]: "Kentucky",
  [MiddleSchool.Kerens]: "Kerens",
  [MiddleSchool.Kerr]: "Kerr",
  [MiddleSchool.Killian]: "Killian",
  [MiddleSchool.Kimbrough]: "Kimbrough",
  [MiddleSchool.King]: "King",
  [MiddleSchool.Kingsborou]: "Kingsborou",
  [MiddleSchool.Kirby]: "Kirby",
  [MiddleSchool.Kirkpatric]: "Kirkpatric",
  [MiddleSchool.Kittyhawk]: "Kittyhawk",
  [MiddleSchool.Kopperl]: "Kopperl",
  [MiddleSchool.Kountze]: "Kountze",
  [MiddleSchool.Krueger]: "Krueger",
  [MiddleSchool.Krum]: "Krum",
  [MiddleSchool.LaGrange]: "La Grange",
  [MiddleSchool.LaVillita]: "La Villita",
  [MiddleSchool.Labay]: "Labay",
  [MiddleSchool.LadyBirdJohnson]: "Lady Bird Johnson",
  [MiddleSchool.Lagovista]: "Lagovista",
  [MiddleSchool.LakeBelton]: "Lake Belton",
  [MiddleSchool.LakeDallas]: "Lake Dallas",
  [MiddleSchool.LakeTravis]: "Lake Travis",
  [MiddleSchool.Lakeside]: "Lakeside",
  [MiddleSchool.Lakeview]: "Lakeview",
  [MiddleSchool.Lamar]: "Lamar",
  [MiddleSchool.Lampasas]: "Lampasas",
  [MiddleSchool.Lancaster]: "Lancaster",
  [MiddleSchool.Laneville]: "Laneville",
  [MiddleSchool.Lantana]: "Lantana",
  [MiddleSchool.Lapoyner]: "Lapoyner",
  [MiddleSchool.Latexo]: "Latexo",
  [MiddleSchool.Lawler]: "Lawler",
  [MiddleSchool.Leal]: "Leal",
  [MiddleSchool.Leander]: "Leander",
  [MiddleSchool.Lee]: "Lee",
  [MiddleSchool.LelandEdge]: "Leland Edge",
  [MiddleSchool.LeoAdams]: "Leo Adams",
  [MiddleSchool.LeonSablatura]: "Leon Sablatura",
  [MiddleSchool.Leonard]: "Leonard",
  [MiddleSchool.Lexington]: "Lexington",
  [MiddleSchool.Liberty]: "Liberty",
  [MiddleSchool.LibertyEylau]: "Liberty-Eylau",
  [MiddleSchool.Libertyhil]: "Libertyhil",
  [MiddleSchool.Lincoln]: "Lincoln",
  [MiddleSchool.LindaJobe]: "Linda Jobe",
  [MiddleSchool.Lindsay]: "Lindsay",
  [MiddleSchool.Linglevill]: "Linglevill",
  [MiddleSchool.Lipan]: "Lipan",
  [MiddleSchool.Liveoakrid]: "Liveoakrid",
  [MiddleSchool.Loflin]: "Loflin",
  [MiddleSchool.Lohn]: "Lohn",
  [MiddleSchool.Lometa]: "Lometa",
  [MiddleSchool.LoneStar]: "Lone Star",
  [MiddleSchool.Loneoak]: "Loneoak",
  [MiddleSchool.Long]: "Long",
  [MiddleSchool.Longfellow]: "Longfellow",
  [MiddleSchool.Lorena]: "Lorena",
  [MiddleSchool.LoreneRogers]: "Lorene Rogers",
  [MiddleSchool.Loretto]: "Loretto",
  [MiddleSchool.LosCuates]: "Los Cuates",
  [MiddleSchool.Louisiana]: "Louisiana",
  [MiddleSchool.Lovejoy]: "Lovejoy",
  [MiddleSchool.Lowell]: "Lowell",
  [MiddleSchool.LowellSmith]: "Lowell Smith",
  [MiddleSchool.Lowery]: "Lowery",
  [MiddleSchool.Lowrance]: "Lowrance",
  [MiddleSchool.LubbockCooper]: "Lubbock-Cooper",
  [MiddleSchool.LucyRedeFranco]: "Lucy Rede Franco",
  [MiddleSchool.Lufkin]: "Lufkin",
  [MiddleSchool.LyndonBJohnson]: "Lyndon B Johnson",
  [MiddleSchool.LynnLucas]: "Lynn Lucas",
  [MiddleSchool.MacarioGarcia]: "Macario Garcia",
  [MiddleSchool.Mackenzie]: "Mackenzie",
  [MiddleSchool.Madison]: "Madison",
  [MiddleSchool.Magoffin]: "Magoffin",
  [MiddleSchool.Maine]: "Maine",
  [MiddleSchool.Malakoff]: "Malakoff",
  [MiddleSchool.Malone]: "Malone",
  [MiddleSchool.MancePark]: "Mance Park",
  [MiddleSchool.Mann]: "Mann",
  [MiddleSchool.Manor]: "Manor",
  [MiddleSchool.Marblefall]: "Marblefall",
  [MiddleSchool.Marietta]: "Marietta",
  [MiddleSchool.MarineCreek]: "Marine Creek",
  [MiddleSchool.Marlin]: "Marlin",
  [MiddleSchool.Marsh]: "Marsh",
  [MiddleSchool.MarshallDurham]: "Marshall Durham",
  [MiddleSchool.Mart]: "Mart",
  [MiddleSchool.Martinsmil]: "Martinsmil",
  [MiddleSchool.Maryland]: "Maryland",
  [MiddleSchool.Massachusetts]: "Massachusetts",
  [MiddleSchool.Maus]: "Maus",
  [MiddleSchool.May]: "May",
  [MiddleSchool.MaynardJackson]: "Maynard Jackson",
  [MiddleSchool.Mcallister]: "Mcallister",
  [MiddleSchool.Mccarroll]: "Mccarroll",
  [MiddleSchool.Mcdonald]: "Mcdonald",
  [MiddleSchool.Mckamy]: "Mckamy",
  [MiddleSchool.Mclean]: "Mclean",
  [MiddleSchool.Mcmath]: "Mcmath",
  [MiddleSchool.Mcnair]: "Mcnair",
  [MiddleSchool.Mcneil]: "Mcneil",
  [MiddleSchool.Meacham]: "Meacham",
  [MiddleSchool.Meadowbroo]: "Meadowbroo",
  [MiddleSchool.MedinaValley]: "Medina Valley",
  [MiddleSchool.Medinavall]: "Medinavall",
  [MiddleSchool.Medlin]: "Medlin",
  [MiddleSchool.Medrano]: "Medrano",
  [MiddleSchool.Melissa]: "Melissa",
  [MiddleSchool.Memorial]: "Memorial",
  [MiddleSchool.Memphis]: "Memphis",
  [MiddleSchool.Meridian]: "Meridian",
  [MiddleSchool.Merkel]: "Merkel",
  [MiddleSchool.Mexico]: "Mexico",
  [MiddleSchool.Michigan]: "Michigan",
  [MiddleSchool.Midlothian]: "Midlothian",
  [MiddleSchool.Midway]: "Midway",
  [MiddleSchool.Mildred]: "Mildred",
  [MiddleSchool.Milford]: "Milford",
  [MiddleSchool.Millergrov]: "Millergrov",
  [MiddleSchool.Millsap]: "Millsap",
  [MiddleSchool.Mineola]: "Mineola",
  [MiddleSchool.Mineralwel]: "Mineralwel",
  [MiddleSchool.Mississippi]: "Mississippi",
  [MiddleSchool.Missouri]: "Missouri",
  [MiddleSchool.MoisesVela]: "Moises Vela",
  [MiddleSchool.Monnig]: "Monnig",
  [MiddleSchool.Montague]: "Montague",
  [MiddleSchool.Montana]: "Montana",
  [MiddleSchool.Montgomery]: "Montgomery",
  [MiddleSchool.Montwood]: "Montwood",
  [MiddleSchool.Moody]: "Moody",
  [MiddleSchool.MooreMstMagnet]: "Moore Mst Magnet",
  [MiddleSchool.Moran]: "Moran",
  [MiddleSchool.Morehead]: "Morehead",
  [MiddleSchool.Morgan]: "Morgan",
  [MiddleSchool.MorganMil]: "Morgan Mil",
  [MiddleSchool.Morganmill]: "Morganmill",
  [MiddleSchool.Morningsid]: "Morningsid",
  [MiddleSchool.MotleyCountySchool]: "Motley County School",
  [MiddleSchool.MountEnterprise]: "Mount Enterprise",
  [MiddleSchool.MtValley]: "Mt Valley",
  [MiddleSchool.Mtcalm]: "Mtcalm",
  [MiddleSchool.Muenster]: "Muenster",
  [MiddleSchool.Murphy]: "Murphy",
  [MiddleSchool.Nacogdoches]: "Nacogdoches",
  [MiddleSchool.Nacona]: "Nacona",
  [MiddleSchool.Navo]: "Navo",
  [MiddleSchool.Neches]: "Neches",
  [MiddleSchool.Nelson]: "Nelson",
  [MiddleSchool.Nevada]: "Nevada",
  [MiddleSchool.New]: "New",
  [MiddleSchool.NewBoston]: "New Boston",
  [MiddleSchool.NewHome]: "New Home",
  [MiddleSchool.NewMexico]: "New Mexico",
  [MiddleSchool.NewSummerfield]: "New Summerfield",
  [MiddleSchool.NewYork]: "New York",
  [MiddleSchool.Newbraunfe]: "Newbraunfe",
  [MiddleSchool.Newcastle]: "Newcastle",
  [MiddleSchool.Newdiana]: "Newdiana",
  [MiddleSchool.Newton]: "Newton",
  [MiddleSchool.Nicaragua]: "Nicaragua",
  [MiddleSchool.Nimitz]: "Nimitz",
  [MiddleSchool.Nocona]: "Nocona",
  [MiddleSchool.Nolan]: "Nolan",
  [MiddleSchool.Norichland]: "Norichland",
  [MiddleSchool.Normangee]: "Normangee",
  [MiddleSchool.NorthCarolina]: "North Carolina",
  [MiddleSchool.NorthDakota]: "North Dakota",
  [MiddleSchool.NorthShore]: "North Shore",
  [MiddleSchool.Northhopki]: "Northhopki",
  [MiddleSchool.Northoaks]: "Northoaks",
  [MiddleSchool.Northridge]: "Northridge",
  [MiddleSchool.NtreisTestOnly]: "Ntreis Test Only",
  [MiddleSchool.Nueces]: "Nueces",
  [MiddleSchool.Oakrun]: "Oakrun",
  [MiddleSchool.Oakwood]: "Oakwood",
  [MiddleSchool.Obrien]: "Obrien",
  [MiddleSchool.Odom]: "Odom",
  [MiddleSchool.Oglesby]: "Oglesby",
  [MiddleSchool.Ohio]: "Ohio",
  [MiddleSchool.Oklahoma]: "Oklahoma",
  [MiddleSchool.Olfen]: "Olfen",
  [MiddleSchool.Orecity]: "Orecity",
  [MiddleSchool.Oregon]: "Oregon",
  [MiddleSchool.Other]: "Other",
  [MiddleSchool.Otto]: "Otto",
  [MiddleSchool.OuidaBaley]: "Ouida Baley",
  [MiddleSchool.Overton]: "Overton",
  [MiddleSchool.Ozona]: "Ozona",
  [MiddleSchool.Paducah]: "Paducah",
  [MiddleSchool.Page]: "Page",
  [MiddleSchool.PaintCreek]: "Paint Creek",
  [MiddleSchool.Palestine]: "Palestine",
  [MiddleSchool.PaloPinto]: "Palo Pinto",
  [MiddleSchool.Paloalto]: "Paloalto",
  [MiddleSchool.Panama]: "Panama",
  [MiddleSchool.Panhandle]: "Panhandle",
  [MiddleSchool.PantherCreek]: "Panther Creek",
  [MiddleSchool.Paradise]: "Paradise",
  [MiddleSchool.Parkland]: "Parkland",
  [MiddleSchool.PatHaganCheek]: "Pat Hagan Cheek",
  [MiddleSchool.PattonSprings]: "Patton Springs",
  [MiddleSchool.Pearson]: "Pearson",
  [MiddleSchool.Peaster]: "Peaster",
  [MiddleSchool.Penelope]: "Penelope",
  [MiddleSchool.Pennsylvania]: "Pennsylvania",
  [MiddleSchool.Perkins]: "Perkins",
  [MiddleSchool.Permenter]: "Permenter",
  [MiddleSchool.Perrin]: "Perrin",
  [MiddleSchool.Perry]: "Perry",
  [MiddleSchool.PerrytonJuniorHigh]: "Perryton Junior High",
  [MiddleSchool.Pershing]: "Pershing",
  [MiddleSchool.Peru]: "Peru",
  [MiddleSchool.Peterson]: "Peterson",
  [MiddleSchool.Pettus]: "Pettus",
  [MiddleSchool.Pike]: "Pike",
  [MiddleSchool.PilotPoint]: "Pilot Point",
  [MiddleSchool.Piner]: "Piner",
  [MiddleSchool.Pinetree]: "Pinetree",
  [MiddleSchool.PinnacleRandallJrHigh]: "Pinnacle/Randall Jr. High",
  [MiddleSchool.PinnacleWestover]: "Pinnacle/Westover",
  [MiddleSchool.Pioneer]: "Pioneer",
  [MiddleSchool.Pittsburg]: "Pittsburg",
  [MiddleSchool.PleasantGrove]: "Pleasant Grove",
  [MiddleSchool.Poe]: "Poe",
  [MiddleSchool.Polk]: "Polk",
  [MiddleSchool.Ponder]: "Ponder",
  [MiddleSchool.PortIsabel]: "Port Isabel",
  [MiddleSchool.PortNeches]: "Port Neches",
  [MiddleSchool.Post]: "Post",
  [MiddleSchool.Pottsboro]: "Pottsboro",
  [MiddleSchool.PrairieLea]: "Prairie Lea",
  [MiddleSchool.PrairieVa]: "Prairie Va",
  [MiddleSchool.PrairieVista]: "Prairie Vista",
  [MiddleSchool.Prairiland]: "Prairiland",
  [MiddleSchool.Prestwick]: "Prestwick",
  [MiddleSchool.Priddy]: "Priddy",
  [MiddleSchool.PuertoRico]: "Puerto Rico",
  [MiddleSchool.Quintenill]: "Quintenill",
  [MiddleSchool.ROharaLanier]: "R. Ohara Lanier",
  [MiddleSchool.Ranchland]: "Ranchland",
  [MiddleSchool.Rancier]: "Rancier",
  [MiddleSchool.Randolph]: "Randolph",
  [MiddleSchool.Ranger]: "Ranger",
  [MiddleSchool.Rawlinson]: "Rawlinson",
  [MiddleSchool.Rayburn]: "Rayburn",
  [MiddleSchool.Raynes]: "Raynes",
  [MiddleSchool.Reagan]: "Reagan",
  [MiddleSchool.RedLick]: "Red Lick",
  [MiddleSchool.RedOak]: "Red Oak",
  [MiddleSchool.Redwater]: "Redwater",
  [MiddleSchool.Reed]: "Reed",
  [MiddleSchool.Refugio]: "Refugio",
  [MiddleSchool.Renner]: "Renner",
  [MiddleSchool.Reynolds]: "Reynolds",
  [MiddleSchool.Rhodes]: "Rhodes",
  [MiddleSchool.Rice]: "Rice",
  [MiddleSchool.RichardAllie]: "Richard Allie",
  [MiddleSchool.Richards]: "Richards",
  [MiddleSchool.Richardson]: "Richardson",
  [MiddleSchool.Richland]: "Richland",
  [MiddleSchool.Ridgeview]: "Ridgeview",
  [MiddleSchool.Ringgold]: "Ringgold",
  [MiddleSchool.RioVista]: "Rio Vista",
  [MiddleSchool.Riobravo]: "Riobravo",
  [MiddleSchool.RisingStar]: "Rising Star",
  [MiddleSchool.RiverRoad]: "River Road",
  [MiddleSchool.Riverside]: "Riverside",
  [MiddleSchool.Roach]: "Roach",
  [MiddleSchool.RobertHill]: "Robert  Hill",
  [MiddleSchool.RobertLee]: "Robert Lee",
  [MiddleSchool.Robinson]: "Robinson",
  [MiddleSchool.Roby]: "Roby",
  [MiddleSchool.Rochester]: "Rochester",
  [MiddleSchool.Rockportfu]: "Rockportfu",
  [MiddleSchool.Rocksprings]: "Rocksprings",
  [MiddleSchool.Rodriguez]: "Rodriguez",
  [MiddleSchool.Rogers]: "Rogers",
  [MiddleSchool.Roscoe]: "Roscoe",
  [MiddleSchool.RosebudLott]: "Rosebud-Lott",
  [MiddleSchool.Rosemont]: "Rosemont",
  [MiddleSchool.Rossl]: "Rossl",
  [MiddleSchool.Roxton]: "Roxton",
  [MiddleSchool.RoyseCity]: "Royse City",
  [MiddleSchool.Rule]: "Rule",
  [MiddleSchool.Runningbru]: "Runningbru",
  [MiddleSchool.Rusk]: "Rusk",
  [MiddleSchool.SAndS]: "S And S",
  [MiddleSchool.Sabinal]: "Sabinal",
  [MiddleSchool.Sabine]: "Sabine",
  [MiddleSchool.Saintjo]: "Saintjo",
  [MiddleSchool.Saltillo]: "Saltillo",
  [MiddleSchool.SamTasby]: "Sam Tasby",
  [MiddleSchool.Samrayburn]: "Samrayburn",
  [MiddleSchool.SanAugustine]: "San Augustine",
  [MiddleSchool.SanSaba]: "San Saba",
  [MiddleSchool.Sanchez]: "Sanchez",
  [MiddleSchool.Sanelizaro]: "Sanelizaro",
  [MiddleSchool.Sanger]: "Sanger",
  [MiddleSchool.Santo]: "Santo",
  [MiddleSchool.Savoy]: "Savoy",
  [MiddleSchool.Schimelpfe]: "Schimelpfe",
  [MiddleSchool.Schultz]: "Schultz",
  [MiddleSchool.Scoggins]: "Scoggins",
  [MiddleSchool.Scotland]: "Scotland",
  [MiddleSchool.Scurry]: "Scurry",
  [MiddleSchool.Seadrift]: "Seadrift",
  [MiddleSchool.Seagoville]: "Seagoville",
  [MiddleSchool.Seymour]: "Seymour",
  [MiddleSchool.ShadowRidge]: "Shadow Ridge",
  [MiddleSchool.Shepard]: "Shepard",
  [MiddleSchool.Shepherd]: "Shepherd",
  [MiddleSchool.Sherman]: "Sherman",
  [MiddleSchool.Sidney]: "Sidney",
  [MiddleSchool.SierraBlanca]: "Sierra Blanca",
  [MiddleSchool.Sivellsven]: "Sivellsven",
  [MiddleSchool.Slaton]: "Slaton",
  [MiddleSchool.Slidell]: "Slidell",
  [MiddleSchool.Slider]: "Slider",
  [MiddleSchool.SloanCreek]: "Sloan Creek",
  [MiddleSchool.Slocum]: "Slocum",
  [MiddleSchool.Smith]: "Smith",
  [MiddleSchool.Smithfield]: "Smithfield",
  [MiddleSchool.Smithsonva]: "Smithsonva",
  [MiddleSchool.SmylieWilson]: "Smylie Wilson",
  [MiddleSchool.Snook]: "Snook",
  [MiddleSchool.Socorro]: "Socorro",
  [MiddleSchool.SouthAfrica]: "South Africa",
  [MiddleSchool.SouthDakota]: "South Dakota",
  [MiddleSchool.Southard]: "Southard",
  [MiddleSchool.Southpark]: "Southpark",
  [MiddleSchool.Southside]: "Southside",
  [MiddleSchool.Spain]: "Spain",
  [MiddleSchool.Spence]: "Spence",
  [MiddleSchool.Springbran]: "Springbran",
  [MiddleSchool.Springhill]: "Springhill",
  [MiddleSchool.Springoaks]: "Springoaks",
  [MiddleSchool.Springtown]: "Springtown",
  [MiddleSchool.Spur]: "Spur",
  [MiddleSchool.StLucia]: "St. Lucia",
  [MiddleSchool.StVincent]: "St. Vincent",
  [MiddleSchool.Stafford]: "Stafford",
  [MiddleSchool.Staley]: "Staley",
  [MiddleSchool.Stamford]: "Stamford",
  [MiddleSchool.Stevens]: "Stevens",
  [MiddleSchool.Stewart]: "Stewart",
  [MiddleSchool.Stinson]: "Stinson",
  [MiddleSchool.Stockard]: "Stockard",
  [MiddleSchool.Stone]: "Stone",
  [MiddleSchool.Stonypoint]: "Stonypoint",
  [MiddleSchool.Storey]: "Storey",
  [MiddleSchool.Strawn]: "Strawn",
  [MiddleSchool.Strickland]: "Strickland",
  [MiddleSchool.Stripling]: "Stripling",
  [MiddleSchool.Sulphurblu]: "Sulphurblu",
  [MiddleSchool.Sulphurspr]: "Sulphurspr",
  [MiddleSchool.SummerCreek]: "Summer Creek",
  [MiddleSchool.Sunnyvale]: "Sunnyvale",
  [MiddleSchool.Sunridge]: "Sunridge",
  [MiddleSchool.Sweetwater]: "Sweetwater",
  [MiddleSchool.Tafolla]: "Tafolla",
  [MiddleSchool.Tasby]: "Tasby",
  [MiddleSchool.Tatum]: "Tatum",
  [MiddleSchool.Taylor]: "Taylor",
  [MiddleSchool.Tejeda]: "Tejeda",
  [MiddleSchool.Telles]: "Telles",
  [MiddleSchool.Tennessee]: "Tennessee",
  [MiddleSchool.TerraVista]: "Terra Vista",
  [MiddleSchool.TerraceHi]: "Terrace Hi",
  [MiddleSchool.Terrellwel]: "Terrellwel",
  [MiddleSchool.Terry]: "Terry",
  [MiddleSchool.Texas]: "Texas",
  [MiddleSchool.ThePhilippines]: "The Philippines",
  [MiddleSchool.Thompson]: "Thompson",
  [MiddleSchool.Thrall]: "Thrall",
  [MiddleSchool.ThreeLakes]: "Three Lakes",
  [MiddleSchool.ThreeWay]: "Three Way",
  [MiddleSchool.Threeriver]: "Threeriver",
  [MiddleSchool.Threeway]: "Threeway",
  [MiddleSchool.Throckmort]: "Throckmort",
  [MiddleSchool.Timberview]: "Timberview",
  [MiddleSchool.Timberwood]: "Timberwood",
  [MiddleSchool.Timpson]: "Timpson",
  [MiddleSchool.Tioga]: "Tioga",
  [MiddleSchool.Tippit]: "Tippit",
  [MiddleSchool.Tison]: "Tison",
  [MiddleSchool.Tolar]: "Tolar",
  [MiddleSchool.TomBean]: "Tom Bean",
  [MiddleSchool.TomHarpool]: "Tom Harpool",
  [MiddleSchool.Tornillo]: "Tornillo",
  [MiddleSchool.Travis]: "Travis",
  [MiddleSchool.Trent]: "Trent",
  [MiddleSchool.Trenton]: "Trenton",
  [MiddleSchool.Trinidad]: "Trinidad",
  [MiddleSchool.TrinitySprings]: "Trinity Springs",
  [MiddleSchool.Troup]: "Troup",
  [MiddleSchool.Troy]: "Troy",
  [MiddleSchool.Truman]: "Truman",
  [MiddleSchool.Tulosomidw]: "Tulosomidw",
  [MiddleSchool.Twain]: "Twain",
  [MiddleSchool.Unionhill]: "Unionhill",
  [MiddleSchool.Upchurch]: "Upchurch",
  [MiddleSchool.Valley]: "Valley",
  [MiddleSchool.Valleyview]: "Valleyview",
  [MiddleSchool.Vandeventer]: "Vandeventer",
  [MiddleSchool.Vanston]: "Vanston",
  [MiddleSchool.Vega]: "Vega",
  [MiddleSchool.Venus]: "Venus",
  [MiddleSchool.Veribest]: "Veribest",
  [MiddleSchool.Vernon]: "Vernon",
  [MiddleSchool.Veterans]: "Veterans",
  [MiddleSchool.Vidor]: "Vidor",
  [MiddleSchool.Virginia]: "Virginia",
  [MiddleSchool.VistaRidge]: "VistaRidge",
  [MiddleSchool.WFGeorge]: "W F George",
  [MiddleSchool.Waco]: "Waco",
  [MiddleSchool.Walker]: "Walker",
  [MiddleSchool.Wallace]: "Wallace",
  [MiddleSchool.WalnutGrove]: "Walnut Grove",
  [MiddleSchool.WalnutSp]: "Walnut Sp",
  [MiddleSchool.Walnutbend]: "Walnutbend",
  [MiddleSchool.Warren]: "Warren",
  [MiddleSchool.Washington]: "Washington",
  [MiddleSchool.Waskom]: "Waskom",
  [MiddleSchool.Watauga]: "Watauga",
  [MiddleSchool.Wayside]: "Wayside",
  [MiddleSchool.Wedgwood]: "Wedgwood",
  [MiddleSchool.Weis]: "Weis",
  [MiddleSchool.Wells]: "Wells",
  [MiddleSchool.West]: "West",
  [MiddleSchool.WestRidge]: "West Ridge",
  [MiddleSchool.WestSabine]: "West Sabine",
  [MiddleSchool.WestTexas]: "West Texas",
  [MiddleSchool.WestVirginia]: "West Virginia",
  [MiddleSchool.Westbrook]: "Westbrook",
  [MiddleSchool.Wester]: "Wester",
  [MiddleSchool.Westwood]: "Westwood",
  [MiddleSchool.Wheatley]: "Wheatley",
  [MiddleSchool.Wheeler]: "Wheeler",
  [MiddleSchool.White]: "White",
  [MiddleSchool.Whiteoak]: "Whiteoak",
  [MiddleSchool.Whitesboro]: "Whitesboro",
  [MiddleSchool.Whitewrigh]: "Whitewrigh",
  [MiddleSchool.Whitney]: "Whitney",
  [MiddleSchool.Whittier]: "Whittier",
  [MiddleSchool.Wiggs]: "Wiggs",
  [MiddleSchool.Wildorado]: "Wildorado",
  [MiddleSchool.Wilkinson]: "Wilkinson",
  [MiddleSchool.WilliamRushing]: "William Rushing",
  [MiddleSchool.Williams]: "Williams",
  [MiddleSchool.WilliamsIntermediate]: "Williams Intermediate",
  [MiddleSchool.WillowSprings]: "Willow Springs",
  [MiddleSchool.WillowVistRiverRd]: "Willow Vist/River Rd",
  [MiddleSchool.Willspoint]: "Willspoint",
  [MiddleSchool.Wilson]: "Wilson",
  [MiddleSchool.Windthorst]: "Windthorst",
  [MiddleSchool.Winfield]: "Winfield",
  [MiddleSchool.Winona]: "Winona",
  [MiddleSchool.Wolfecity]: "Wolfecity",
  [MiddleSchool.Wood]: "Wood",
  [MiddleSchool.Woodlakehi]: "Woodlakehi",
  [MiddleSchool.WoodlandAcres]: "Woodland Acres",
  [MiddleSchool.Woodson]: "Woodson",
  [MiddleSchool.Woodville]: "Woodville",
  [MiddleSchool.Worley]: "Worley",
  [MiddleSchool.Wortham]: "Wortham",
  [MiddleSchool.Wrenn]: "Wrenn",
  [MiddleSchool.Wylie]: "Wylie",
  [MiddleSchool.Wyoming]: "Wyoming",
  [MiddleSchool.Yantis]: "Yantis",
  [MiddleSchool.Young]: "Young",
  [MiddleSchool.Ysleta]: "Ysleta",
  [MiddleSchool.Zephyr]: "Zephyr",
  [MiddleSchool.Zumwalt]: "Zumwalt",
  [MiddleSchool.SlayterCreek]: "Slayter Creek"
};
