import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  Sold = "sold",
  PendingContinueToShow = "pendingContinueToShow",
  Withdrawn = "withdrawn",
  Pending = "pending",
  Terminated = "terminated",
  OptionPending = "optionPending",
  ComingSoon = "comingSoon",
  Expired = "expired"
}
export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.PendingContinueToShow,
  MlsStatus.OptionPending,
  MlsStatus.Pending,
  MlsStatus.ComingSoon
];

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.Sold, "Sold"],
  [MlsStatus.PendingContinueToShow, "Pending Continue to Show"],
  [MlsStatus.Withdrawn, "Withdrawn"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.Terminated, "Terminated"],
  [MlsStatus.OptionPending, "Option Pending"],
  [MlsStatus.ComingSoon, "Coming Soon"],
  [MlsStatus.Expired, "Expired"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.OptionPending, MlsStatusColor.Pending],
  [MlsStatus.PendingContinueToShow, MlsStatusColor.Pending],
  [MlsStatus.Sold, MlsStatusColor.Sold],
  [MlsStatus.Withdrawn, MlsStatusColor.Withdrawn],
  [MlsStatus.Terminated, MlsStatusColor.Canceled],
  [MlsStatus.ComingSoon, MlsStatusColor.Withdrawn],
  [MlsStatus.Expired, MlsStatusColor.Canceled]
]);

export const MlsStatusWithoutStatusFields: MlsStatus[] = [
  MlsStatus.Active,
  MlsStatus.ComingSoon
];
