import React from "react";
import { useLocation } from "react-router-dom";

import {
  FetchData,
  FetchDataDownloading,
  GridResponse,
  useFetchData
} from "@homesusa/grid-table";
import { MarketCode } from "@homesusa/core";
import { ListingGrid } from "core/interfaces/listing";
import { ListType, ListedType } from "core/enums";
import { CookiesManager } from "core/utils";
import { getListings } from "core/services/listings.service";
import { ListingGridCookieType } from "../enums";

export const useGetListingsGridData = (
  listType: ListType,
  marketCode: MarketCode,
  defaultMlsStatus: string[]
): {
  fetchData: (props: FetchData) => Promise<void>;
  fetchDataForDownloading: () => Promise<ListingGrid[]>;
  data: GridResponse<ListingGrid>;
  mlsStatusSelected: string[];
  setMlsStatusSelected: (value: string[]) => void;
  listedTypeSelected: ListedType | undefined;
  setListedTypeSelected: (value: ListedType) => void;
} => {
  const { search } = useLocation();

  const setMlsStatusCookie = (mlsStatuses: string[]): void => {
    CookiesManager.setCookie(ListingGridCookieType.MlsStatus, mlsStatuses);
  };

  function initialMlsStatus(): string[] {
    const cookieFIlters = CookiesManager.getCookie<string[]>(
      ListingGridCookieType.MlsStatus
    );
    if (cookieFIlters?.length) {
      return cookieFIlters;
    }
    if (search && search !== "") {
      const mlsStatus = search.split("=")[1];
      return mlsStatus.split(",");
    }
    return defaultMlsStatus;
  }

  function initialListedType(): ListedType {
    const cookieListedType = CookiesManager.getCookie<ListedType>(
      ListingGridCookieType.ListedStatus
    );
    if (cookieListedType) {
      return cookieListedType;
    }
    return ListedType.None;
  }

  const [mlsStatusSelected, setMlsStatusSelected] =
    React.useState<string[]>(initialMlsStatus);
  const [listedTypeSelected, setListedTypeSelected] =
    React.useState<ListedType>(initialListedType);

  const getGridData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      sortBy,
      globalFilter,
      isForDownloading
    }: FetchDataDownloading): Promise<GridResponse<ListingGrid>> => {
      return getListings(
        listType,
        marketCode
      )({
        take: pageSize,
        skip: pageIndex,
        mlsStatus: globalFilter != null ? undefined : mlsStatusSelected,
        listedType:
          globalFilter == null && listedTypeSelected != null
            ? listedTypeSelected
            : undefined,
        searchBy: globalFilter,
        sortBy,
        isForDownloading
      });
    },
    [mlsStatusSelected, listedTypeSelected]
  );

  const {
    data,
    fetchData: fetchListingData,
    fetchDataForDownloading
  } = useFetchData<ListingGrid>(getGridData);

  const fetchData = React.useCallback(
    async ({ globalFilter, ...props }: FetchData): Promise<void> => {
      await fetchListingData({ ...props, globalFilter });
      if (listedTypeSelected) {
        CookiesManager.setCookie(
          ListingGridCookieType.ListedStatus,
          listedTypeSelected
        );
      }
      if (mlsStatusSelected) {
        setMlsStatusCookie(mlsStatusSelected);
      }
      if (globalFilter) {
        CookiesManager.setCookie(ListingGridCookieType.Search, globalFilter);
      }
    },
    [fetchListingData]
  );

  return {
    data,
    fetchData,
    fetchDataForDownloading,
    mlsStatusSelected,
    setMlsStatusSelected,
    listedTypeSelected,
    setListedTypeSelected
  };
};
