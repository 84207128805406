export enum ExteriorFeature {
  Balcony = "balcony",
  ExteriorSteps = "exteriorSteps",
  GrillElectric = "grillElectric",
  GrillGas = "grillGas",
  Lighting = "lighting",
  NoExteriorSteps = "noExteriorSteps",
  None = "none",
  OutdoorGrill = "outdoorGrill",
  Playground = "playground",
  PrivateYard = "privateYard",
  TennisCourts = "tennisCourts"
}
export const ExteriorFeatureLabel = new Map<ExteriorFeature, string>([
  [ExteriorFeature.Balcony, "Balcony"],
  [ExteriorFeature.ExteriorSteps, "Exterior Steps"],
  [ExteriorFeature.GrillElectric, "Grill-Electric"],
  [ExteriorFeature.GrillGas, "Grill-Gas"],
  [ExteriorFeature.Lighting, "Lighting"],
  [ExteriorFeature.NoExteriorSteps, "No Exterior Steps"],
  [ExteriorFeature.None, "None"],
  [ExteriorFeature.OutdoorGrill, "Outdoor Grill"],
  [ExteriorFeature.Playground, "Playground"],
  [ExteriorFeature.PrivateYard, "Private Yard"],
  [ExteriorFeature.TennisCourts, "Tennis Court(s)"]
]);
