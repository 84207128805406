import React from "react";
import { Box } from "@mui/material";

export function WarningMessage(): JSX.Element {
  return (
    <Box sx={{ margin: "auto", width: 700 }}>
      <Box
        component="img"
        sx={{ width: "100%", mt: 0, mb: 5 }}
        src="https://homesusastorage.blob.core.windows.net/webimages/SpecDeck-Report.png"
      />
    </Box>
  );
}
