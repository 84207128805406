import { MlsStatusColor } from "core/enums";

export enum MlsStatus {
  Active = "active",
  ActiveUnderContract = "activeUnderContract",
  Cancelled = "canceled",
  Closed = "closed",
  Hold = "hold",
  Pending = "pending"
}

export const MlsStatusForNewListings = [
  MlsStatus.Active,
  MlsStatus.ActiveUnderContract,
  MlsStatus.Pending
];

export const MlsStatusLabel = new Map<MlsStatus, string>([
  [MlsStatus.Active, "Active"],
  [MlsStatus.ActiveUnderContract, "Active Under Contract"],
  [MlsStatus.Pending, "Pending"],
  [MlsStatus.Cancelled, "Cancelled"],
  [MlsStatus.Closed, "Closed"],
  [MlsStatus.Hold, "Hold"]
]);

export const StatusColorLabel = new Map<MlsStatus, MlsStatusColor>([
  [MlsStatus.Active, MlsStatusColor.Active],
  [MlsStatus.ActiveUnderContract, MlsStatusColor.Active],
  [MlsStatus.Cancelled, MlsStatusColor.Canceled],
  [MlsStatus.Pending, MlsStatusColor.Pending],
  [MlsStatus.Closed, MlsStatusColor.Sold],
  [MlsStatus.Hold, MlsStatusColor.Pending]
]);

export const MlsStatusWithoutStatusFields: MlsStatus[] = [MlsStatus.Active];
