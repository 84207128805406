export enum ElementarySchools {
  AMAikin = "amAikin",
  Abbott = "abbott",
  Academy = "academy",
  Achziger = "achziger",
  Acker = "acker",
  Acton = "acton",
  Adams = "adams",
  Adamsjohnq = "adamsjohnq",
  Adamsnatha = "adamsnatha",
  AdelfaCallejo = "adelfaCallejo",
  Aguadulce = "aguadulce",
  Aiken = "aiken",
  Aikin = "aikin",
  Akin = "akin",
  Alabama = "alabama",
  Alamo = "alamo",
  Alarcon = "alarcon",
  Alaska = "alaska",
  Albagolden = "albagolden",
  Aldridge = "aldridge",
  Alexander = "alexander",
  Allen = "allen",
  Alpine = "alpine",
  Altavista = "altavista",
  Alto = "alto",
  AltoBonito = "altoBonito",
  Alton = "alton",
  AlvaradoN = "alvaradoN",
  AlvaradoS = "alvaradoS",
  Alvarez = "alvarez",
  Alvin = "alvin",
  Alvord = "alvord",
  AmberTerrace = "amberTerrace",
  Amos = "amos",
  AmyParksHeath = "amyParksHeath",
  Anahuac = "anahuac",
  Anderson = "anderson",
  AndersonShiro = "andersonShiro",
  Andrews = "andrews",
  Anguilla = "anguilla",
  AnitaScott = "anitaScott",
  AnnaMayDaulton = "annaMayDaulton",
  Annaville = "annaville",
  AnnettePerry = "annettePerry",
  AnnieWebbBlanton = "annieWebbBlanton",
  Anson = "anson",
  Anthon = "anthon",
  Anthony = "anthony",
  Aoy = "aoy",
  Apache = "apache",
  AppleSprings = "appleSprings",
  Aquilla = "aquilla",
  Arapaho = "arapaho",
  Arcadiapar = "arcadiapar",
  Archercity = "archercity",
  ArdenRoad = "ardenRoad",
  Ardenroad = "ardenroad",
  Argyle = "argyle",
  ArgyleSouth = "argyleSouth",
  ArgyleWest = "argyleWest",
  Arizona = "arizona",
  Arkansas = "arkansas",
  Arlingtonp = "arlingtonp",
  ArlonRSeay = "arlonRSeay",
  Armstrong = "armstrong",
  Arnold = "arnold",
  Arp = "arp",
  Aruba = "aruba",
  Ascatate = "ascatate",
  Ashley = "ashley",
  Ashworth = "ashworth",
  Aspermont = "aspermont",
  Athens = "athens",
  Atherton = "atherton",
  Atlanta = "atlanta",
  Atwood = "atwood",
  Audeliacrk = "audeliacrk",
  Austin = "austin",
  Australia = "australia",
  Austwellti = "austwellti",
  Avalon = "avalon",
  Avery = "avery",
  AveryParkArea = "averyParkArea",
  Avinger = "avinger",
  Avondale = "avondale",
  Axtell = "axtell",
  Azle = "azle",
  Baccus = "baccus",
  Bagdade = "bagdade",
  Bahamas = "bahamas",
  Baird = "baird",
  Ball = "ball",
  Ballinger = "ballinger",
  Balmorhea = "balmorhea",
  Banquete = "banquete",
  Barbados = "barbados",
  BarbaraWalker = "barbaraWalker",
  Barkleyrui = "barkleyrui",
  Barksdale = "barksdale",
  Barnes = "barnes",
  Barron = "barron",
  Bartlett = "bartlett",
  Barton = "barton",
  BartonCreek = "bartonCreek",
  Baskin = "baskin",
  Bassetti = "bassetti",
  Basswood = "basswood",
  Bauer = "bauer",
  Baxter = "baxter",
  BayColony = "bayColony",
  Bayles = "bayles",
  Beaconhill = "beaconhill",
  Beall = "beall",
  BearCreek = "bearCreek",
  Beasley = "beasley",
  Bebensee = "bebensee",
  Beck = "beck",
  Beckham = "beckham",
  Bedfordhei = "bedfordhei",
  BeeCave = "beeCave",
  Belair = "belair",
  Belize = "belize",
  Bell = "bell",
  Bellaire = "bellaire",
  Bellevue = "bellevue",
  Bellmanor = "bellmanor",
  Bells = "bells",
  Belmar = "belmar",
  Beltline = "beltline",
  BenMilam = "benMilam",
  Benavides = "benavides",
  Benavidez = "benavidez",
  Benbrook = "benbrook",
  Benjamin = "benjamin",
  BenjaminDocKerleyElementary = "benjaminDocKerleyElementary",
  Bennett = "bennett",
  BensBranch = "bensBranch",
  Benson = "benson",
  Bentsen = "bentsen",
  Berkman = "berkman",
  Berry = "berry",
  Bertram = "bertram",
  Bethany = "bethany",
  Bethune = "bethune",
  Beverly = "beverly",
  Bigcountry = "bigcountry",
  Bigsandy = "bigsandy",
  Bigsprings = "bigsprings",
  Bilchildre = "bilchildre",
  Bilhartz = "bilhartz",
  BillBrown = "billBrown",
  BillieStevenson = "billieStevenson",
  Bingman = "bingman",
  Birdville = "birdville",
  Birdwell = "birdwell",
  Birmingham = "birmingham",
  Bishop = "bishop",
  Bivins = "bivins",
  Black = "black",
  Blackburn = "blackburn",
  Blackland = "blackland",
  Blackshear = "blackshear",
  Blackwell = "blackwell",
  Blair = "blair",
  Blanco = "blanco",
  Bland = "bland",
  Blanket = "blanket",
  BlaschkeSheldon = "blaschkeSheldon",
  Bledsoe = "bledsoe",
  Blessing = "blessing",
  Bliss = "bliss",
  Blockhouse = "blockhouse",
  Bloomburg = "bloomburg",
  Bloominggr = "bloominggr",
  Blossom = "blossom",
  Bluebonnet = "bluebonnet",
  BluebonnetTrail = "bluebonnetTrail",
  Bluehaze = "bluehaze",
  Blueridge = "blueridge",
  Bluffdale = "bluffdale",
  Blum = "blum",
  Boals = "boals",
  BobandLolaSanford = "bobandLolaSanford",
  BobbyRayAftonMartin = "bobbyRayAftonMartin",
  Bobhope = "bobhope",
  Boggess = "boggess",
  Boles = "boles",
  Bolin = "bolin",
  Bond = "bond",
  Bonham = "bonham",
  Bonner = "bonner",
  Bonniebrae = "bonniebrae",
  Boon = "boon",
  Borchardt = "borchardt",
  BordenCountySchool = "bordenCountySchool",
  Boren = "boren",
  Borger = "borger",
  Borman = "borman",
  Borrego = "borrego",
  Bosqueville = "bosqueville",
  Bowden = "bowden",
  Bowen = "bowen",
  Bowie = "bowie",
  Boyd = "boyd",
  Brackenrid = "brackenrid",
  Bradfield = "bradfield",
  Bradford = "bradford",
  Bradley = "bradley",
  Brady = "brady",
  Bramlette = "bramlette",
  Branch = "branch",
  Brandenber = "brandenber",
  Brandon = "brandon",
  Bransford = "bransford",
  Bransom = "bransom",
  Braunstati = "braunstati",
  Bray = "bray",
  Brazil = "brazil",
  Bremond = "bremond",
  BrendaNorwood = "brendaNorwood",
  Brent = "brent",
  Brentfield = "brentfield",
  Brewer = "brewer",
  Brice = "brice",
  BridgePoint = "bridgePoint",
  Bridgeport = "bridgeport",
  Bridlewood = "bridlewood",
  Bright = "bright",
  Brinker = "brinker",
  Briscoe = "briscoe",
  Britain = "britain",
  Broadway = "broadway",
  Brock = "brock",
  Bronte = "bronte",
  Brookeland = "brookeland",
  Brookesmith = "brookesmith",
  Brookhollow = "brookhollow",
  Brown = "brown",
  Brownsboro = "brownsboro",
  Brownsville = "brownsville",
  Bruceshulk = "bruceshulk",
  BrucevilleEddy = "brucevilleEddy",
  Bruni = "bruni",
  Brushycree = "brushycree",
  Bryan = "bryan",
  Bryant = "bryant",
  Bryson = "bryson",
  Buckner = "buckner",
  Budd = "budd",
  BuenaVistaSchool = "buenaVistaSchool",
  Buenosaire = "buenosaire",
  Buffalo = "buffalo",
  BuffaloGap = "buffaloGap",
  Bukhair = "bukhair",
  Bulgaria = "bulgaria",
  Bullard = "bullard",
  Bulverde = "bulverde",
  Buna = "buna",
  Burgin = "burgin",
  Burkeville = "burkeville",
  Burks = "burks",
  Burleson = "burleson",
  Burnet = "burnet",
  Burnett = "burnett",
  Burton = "burton",
  Burtonhill = "burtonhill",
  Bush = "bush",
  Bushland = "bushland",
  Bushman = "bushman",
  Butler = "butler",
  Butterfield = "butterfield",
  Bynum = "bynum",
  CATatumJr = "caTatumJr",
  CJHarris = "cjHarris",
  CMSoto = "cmSoto",
  Cabell = "cabell",
  Cactus = "cactus",
  Cactusranc = "cactusranc",
  CaddoGrove = "caddoGrove",
  Caddomills = "caddomills",
  Cadwallade = "cadwallade",
  Caillet = "caillet",
  Cain = "cain",
  Caldwell = "caldwell",
  Caldwellhe = "caldwellhe",
  California = "california",
  Calk = "calk",
  Callisburg = "callisburg",
  Cambridge = "cambridge",
  Camelot = "camelot",
  Cameron = "cameron",
  Camey = "camey",
  Camian = "camian",
  Campbell = "campbell",
  Campestre = "campestre",
  Canada = "canada",
  Candlewood = "candlewood",
  Cannaday = "cannaday",
  Cannon = "cannon",
  Canton = "canton",
  Canutillo = "canutillo",
  Canyoncree = "canyoncree",
  Capistrano = "capistrano",
  Caprock = "caprock",
  Carden = "carden",
  Carillo = "carillo",
  CarlESchluter = "carlESchluter",
  Carleston = "carleston",
  Carlisle = "carlisle",
  CarolHolt = "carolHolt",
  Carpenter = "carpenter",
  Carr = "carr",
  Carroll = "carroll",
  Carrolllan = "carrolllan",
  Carrollpea = "carrollpea",
  Carrollton = "carrollton",
  Carson = "carson",
  Carterpark = "carterpark",
  Carvajal = "carvajal",
  Carver = "carver",
  Casalinda = "casalinda",
  Casaview = "casaview",
  CastleHills = "castleHills",
  Castleberr = "castleberr",
  Cater = "cater",
  Cato = "cato",
  CaymanIslands = "caymanIslands",
  Cayuga = "cayuga",
  Cedarcreek = "cedarcreek",
  Cedarvalle = "cedarvalle",
  Cedergrove = "cedergrove",
  Celeste = "celeste",
  CeliaHays = "celiaHays",
  Cenizopark = "cenizopark",
  Centennial = "centennial",
  Center = "center",
  Centerville = "centerville",
  Central = "central",
  CentralHeights = "centralHeights",
  Centralhei = "centralhei",
  Centralpar = "centralpar",
  CesarChavez = "cesarChavez",
  Challenger = "challenger",
  Chalmers = "chalmers",
  Chamberlai = "chamberlai",
  Chandler = "chandler",
  ChapelHill = "chapelHill",
  Chapelhill = "chapelhill",
  CharlesandCindyStuber = "charlesandCindyStuber",
  Charlesnas = "charlesnas",
  Chavez = "chavez",
  Cheatham = "cheatham",
  Cherry = "cherry",
  Chico = "chico",
  Childress = "childress",
  Chile = "chile",
  Chillicoth = "chillicoth",
  China = "china",
  Chinasprng = "chinasprng",
  Chireno = "chireno",
  ChisholmRidge = "chisholmRidge",
  ChisolmTrail = "chisolmTrail",
  Chisolmtra = "chisolmtra",
  Chisum = "chisum",
  ChoiceOfSchool = "choiceOfSchool",
  Christie = "christie",
  Ciavarra = "ciavarra",
  Cielovista = "cielovista",
  Cimarron = "cimarron",
  Cisco = "cisco",
  CityView = "cityView",
  Citypark = "citypark",
  ClaraLove = "claraLove",
  Clardy = "clardy",
  Clarendon = "clarendon",
  Clark = "clark",
  Clarkston = "clarkston",
  Clarksvill = "clarksvill",
  Claude = "claude",
  Claybon = "claybon",
  ClaytonLi = "claytonLi",
  Clearcreek = "clearcreek",
  Clearfork = "clearfork",
  Clearsprin = "clearsprin",
  Clendenin = "clendenin",
  Clifton = "clifton",
  Cliftonpar = "cliftonpar",
  Cloverleaf = "cloverleaf",
  Clyde = "clyde",
  Cochran = "cochran",
  CockrellHill = "cockrellHill",
  Coder = "coder",
  Coggin = "coggin",
  Coker = "coker",
  Coldwell = "coldwell",
  Coleman = "coleman",
  Coles = "coles",
  CollegeHill = "collegeHill",
  CollegeSt = "collegeSt",
  Collegehil = "collegehil",
  Colleyvill = "colleyvill",
  Collier = "collier",
  Collins = "collins",
  Collinsgar = "collinsgar",
  Collinsville = "collinsville",
  Colonial = "colonial",
  Colonialhi = "colonialhi",
  Colonialht = "colonialht",
  Colorado = "colorado",
  Colt = "colt",
  Columbiahe = "columbiahe",
  Columbus = "columbus",
  Comal = "comal",
  Comanche = "comanche",
  ComancheSprings = "comancheSprings",
  Comfort = "comfort",
  Commerce = "commerce",
  Como = "como",
  Comopickto = "comopickto",
  Comstock = "comstock",
  Connally = "connally",
  Connecticut = "connecticut",
  Conner = "conner",
  Conteras = "conteras",
  Converse = "converse",
  Cooke = "cooke",
  Cooley = "cooley",
  Coolidge = "coolidge",
  Cooper = "cooper",
  CoraSpencer = "coraSpencer",
  Corbell = "corbell",
  Corey = "corey",
  Corinth = "corinth",
  Coronado = "coronado",
  Coronadoes = "coronadoes",
  Coronadovi = "coronadovi",
  CorriganCamden = "corriganCamden",
  CostaRica = "costaRica",
  Coston = "coston",
  Cotton = "cotton",
  Cottonwood = "cottonwood",
  Countrypla = "countrypla",
  Coupland = "coupland",
  Covington = "covington",
  Cowart = "cowart",
  Cowen = "cowen",
  Cox = "cox",
  CoyoteRidge = "coyoteRidge",
  Crandall = "crandall",
  Cranfillsg = "cranfillsg",
  Crawford = "crawford",
  CreekView = "creekView",
  Creekside = "creekside",
  Crenshaw = "crenshaw",
  Crestview = "crestview",
  Criswell = "criswell",
  Crockett = "crockett",
  Crosby = "crosby",
  CrossOaks = "crossOaks",
  CrossPlains = "crossPlains",
  Crossroads = "crossroads",
  Crosstimbe = "crosstimbe",
  Crouch = "crouch",
  Crow = "crow",
  Crowell = "crowell",
  Crowley = "crowley",
  Crutchfield = "crutchfield",
  Cuellar = "cuellar",
  Cumby = "cumby",
  Cunningham = "cunningham",
  Curtis = "curtis",
  Curtsinger = "curtsinger",
  Cushing = "cushing",
  CynthiaACockrell = "cynthiaACockrell",
  Cypress = "cypress",
  Daffron = "daffron",
  Daggett = "daggett",
  Daiches = "daiches",
  DallasPark = "dallasPark",
  Dalton = "dalton",
  Daniels = "daniels",
  Darr = "darr",
  Darrell = "darrell",
  Dartmouth = "dartmouth",
  Davenport = "davenport",
  DavidMcCall = "davidMcCall",
  Davis = "davis",
  DavisMalvern = "davisMalvern",
  Dawson = "dawson",
  DeZavala = "deZavala",
  Dealey = "dealey",
  Decalb = "decalb",
  Decker = "decker",
  Deepwood = "deepwood",
  Deercreek = "deercreek",
  Degan = "degan",
  Degolyer = "degolyer",
  DelValle = "delValle",
  Delaney = "delaney",
  Deleon = "deleon",
  Dellview = "dellview",
  Delnortehe = "delnortehe",
  Dentoncree = "dentoncree",
  DenverAve = "denverAve",
  Deport = "deport",
  Dequeen = "dequeen",
  Desertaire = "desertaire",
  Deserthill = "deserthill",
  Detroit = "detroit",
  Devers = "devers",
  Devonian = "devonian",
  Dew = "dew",
  Dezavala = "dezavala",
  DiamondHi = "diamondHi",
  DiazVillarreal = "diazVillarreal",
  Dickinson = "dickinson",
  Dillingham = "dillingham",
  Dinsmore = "dinsmore",
  DirksAnderson = "dirksAnderson",
  Ditto = "ditto",
  Dixie = "dixie",
  Dobbs = "dobbs",
  Dodd = "dodd",
  Doddcity = "doddcity",
  DoloresMcClatchey = "doloresMcClatchey",
  Dolphinter = "dolphinter",
  DominicanRepublic = "dominicanRepublic",
  DonJeter = "donJeter",
  DonWhitt = "donWhitt",
  Donald = "donald",
  DonnaPark = "donnaPark",
  Dooley = "dooley",
  Doriemille = "doriemille",
  DorisCullinsLakePointe = "dorisCullinsLakePointe",
  DorothyPAdkins = "dorothyPAdkins",
  DorrisJones = "dorrisJones",
  Dorsey = "dorsey",
  Doss = "doss",
  Doublefile = "doublefile",
  Douglas = "douglas",
  Douglass = "douglass",
  Dovalina = "dovalina",
  Dove = "dove",
  Dover = "dover",
  Dowell = "dowell",
  Dozier = "dozier",
  DrMarioERamirez = "drMarioERamirez",
  DrBryanJack = "drBryanJack",
  Driscoll = "driscoll",
  Dublin = "dublin",
  Duff = "duff",
  Dunaway = "dunaway",
  Dunbar = "dunbar",
  Duncan = "duncan",
  Dunn = "dunn",
  Dupre = "dupre",
  Durham = "durham",
  Dyer = "dyer",
  Dyess = "dyess",
  ECMason = "ecMason",
  Eagle = "eagle",
  EagleRidge = "eagleRidge",
  Eagleheigh = "eagleheigh",
  Eaglemount = "eaglemount",
  Earhart = "earhart",
  Early = "early",
  East = "east",
  Easternhil = "easternhil",
  Easthandle = "easthandle",
  Eastland = "eastland",
  Eastpoint = "eastpoint",
  Eastridge = "eastridge",
  Eastside = "eastside",
  Eastterrel = "eastterrel",
  Eastward = "eastward",
  Eastwoodhe = "eastwoodhe",
  Eastwoodkn = "eastwoodkn",
  EbbyHalliday = "ebbyHalliday",
  Ector = "ector",
  Eddins = "eddins",
  Eden = "eden",
  Edgemere = "edgemere",
  Edgewood = "edgewood",
  Edison = "edison",
  Edna = "edna",
  Edwardbris = "edwardbris",
  Effiemorri = "effiemorri",
  Eisenhower = "eisenhower",
  ElSalvador = "elSalvador",
  Elder = "elder",
  Eldorado = "eldorado",
  Elkhart = "elkhart",
  Elkins = "elkins",
  Elliott = "elliott",
  Ellis = "ellis",
  Elmcreek = "elmcreek",
  Elolf = "elolf",
  Elysianfie = "elysianfie",
  Emerson = "emerson",
  Emile = "emile",
  Encinopark = "encinopark",
  Era = "era",
  Ervin = "ervin",
  Escontrias = "escontrias",
  EsperanzaMedrano = "esperanzaMedrano",
  Ethridge = "ethridge",
  Etoile = "etoile",
  Eugeniarayzor = "eugeniarayzor",
  Eula = "eula",
  Evadale = "evadale",
  Evans = "evans",
  Evant = "evant",
  Everett = "everett",
  EversPark = "eversPark",
  FaddenMckeownChambless = "faddenMckeownChambless",
  Fairfield = "fairfield",
  Fairmeadow = "fairmeadow",
  Fairview = "fairview",
  Fallscity = "fallscity",
  Fannin = "fannin",
  Fannindell = "fannindell",
  Farias = "farias",
  Farine = "farine",
  Farmersbra = "farmersbra",
  Farrell = "farrell",
  Farwell = "farwell",
  Faubion = "faubion",
  FayeWebb = "fayeWebb",
  FelixGBotello = "felixGBotello",
  Fenwick = "fenwick",
  Fernbluff = "fernbluff",
  Field = "field",
  FieldsStore = "fieldsStore",
  Finch = "finch",
  Finleyoate = "finleyoate",
  Fisher = "fisher",
  Fitzgerald = "fitzgerald",
  Fivepalms = "fivepalms",
  Florence = "florence",
  Flores = "flores",
  Florida = "florida",
  Flourbluff = "flourbluff",
  FlowerMound = "flowerMound",
  Floyd = "floyd",
  Fm360Area = "fm360Area",
  Forbes = "forbes",
  ForestHill = "forestHill",
  ForestRidge = "forestRidge",
  ForestTrail = "forestTrail",
  ForestVista = "forestVista",
  Forestburg = "forestburg",
  Forestcree = "forestcree",
  Foresthill = "foresthill",
  Forestnort = "forestnort",
  Forestridg = "forestridg",
  Forman = "forman",
  Forsan = "forsan",
  Fort = "fort",
  Foster = "foster",
  Fostervill = "fostervill",
  Fowler = "fowler",
  Foxrun = "foxrun",
  France = "france",
  FrancesAndJeannetteLee = "francesAndJeannetteLee",
  Francisco = "francisco",
  Frank = "frank",
  FrankGuzick = "frankGuzick",
  Franklin = "franklin",
  Frankston = "frankston",
  Franz = "franz",
  Fred = "fred",
  Fredericks = "fredericks",
  Fredonia = "fredonia",
  Freedom = "freedom",
  Freeman = "freeman",
  Freiheit = "freiheit",
  French = "french",
  Frey = "frey",
  Friendship = "friendship",
  Friona = "friona",
  Froestlane = "froestlane",
  Frost = "frost",
  Ftsamhoust = "ftsamhoust",
  Furneaux = "furneaux",
  GalenaPark = "galenaPark",
  Gallego = "gallego",
  Galloway = "galloway",
  Galvan = "galvan",
  Ganado = "ganado",
  Garcia = "garcia",
  GardenOaks = "gardenOaks",
  GardenRidge = "gardenRidge",
  GardenRidgeArea = "gardenRidgeArea",
  Gardendale = "gardendale",
  Garner = "garner",
  Garrison = "garrison",
  Gary = "gary",
  Gates = "gates",
  Gatesville = "gatesville",
  Gateway = "gateway",
  Gattis = "gattis",
  Gause = "gause",
  GeneHowe = "geneHowe",
  GeneralRicardoSanchez = "generalRicardoSanchez",
  GenevaHeights = "genevaHeights",
  Gentry = "gentry",
  GeorgeWBush = "georgeWBush",
  Georgeclar = "georgeclar",
  Georgewest = "georgewest",
  Georgia = "georgia",
  Gerard = "gerard",
  Germany = "germany",
  Gholson = "gholson",
  Gibbs = "gibbs",
  Gibson = "gibson",
  Giddens = "giddens",
  Gideon = "gideon",
  Gilbert = "gilbert",
  GilbertCuellar = "gilbertCuellar",
  GilbertJMircovich = "gilbertJMircovich",
  Gililland = "gililland",
  Gill = "gill",
  Gillette = "gillette",
  Gilmer = "gilmer",
  Ginnings = "ginnings",
  Givens = "givens",
  Glass = "glass",
  GlenRose = "glenRose",
  Glencove = "glencove",
  Glenhope = "glenhope",
  Glenn = "glenn",
  Glenoaks = "glenoaks",
  Glenpark = "glenpark",
  Glenview = "glenview",
  Glenwood = "glenwood",
  Godley = "godley",
  Godwin = "godwin",
  Goldthwaite = "goldthwaite",
  Goliad = "goliad",
  Gonzalez = "gonzalez",
  GonzaloAndSofiaGarcia = "gonzaloAndSofiaGarcia",
  Gooch = "gooch",
  Good = "good",
  Goodman = "goodman",
  Gordon = "gordon",
  GoshenCreek = "goshenCreek",
  Graebner = "graebner",
  Graford = "graford",
  Grandsalin = "grandsalin",
  Grandview = "grandview",
  Grapeland = "grapeland",
  Grapevine = "grapevine",
  Graves = "graves",
  Gray = "gray",
  Greathouse = "greathouse",
  Greatoaks = "greatoaks",
  Green = "green",
  GreenWm = "greenWm",
  GreenAcres = "greenAcres",
  GreenValley = "greenValley",
  Greenbriar = "greenbriar",
  Greenfield = "greenfield",
  Greenvalle = "greenvalle",
  Greenwood = "greenwood",
  Griffin = "griffin",
  Groesbeck = "groesbeck",
  Groves = "groves",
  Groveton = "groveton",
  Grulla = "grulla",
  Guatemala = "guatemala",
  Guerrero = "guerrero",
  Gulledge = "gulledge",
  Gunstream = "gunstream",
  Gunter = "gunter",
  Gustine = "gustine",
  Hachar = "hachar",
  Haciendahe = "haciendahe",
  Hackberry = "hackberry",
  Haggar = "haggar",
  Hale = "hale",
  Haleyj = "haleyj",
  Haleyt = "haleyt",
  Hall = "hall",
  Hallsville = "hallsville",
  HamiltonPark = "hamiltonPark",
  Hamlet = "hamlet",
  Hamlin = "hamlin",
  Hanby = "hanby",
  Hanes = "hanes",
  Happy = "happy",
  Harben = "harben",
  HardOak = "hardOak",
  Hardeman = "hardeman",
  Hardin = "hardin",
  Hargrave = "hargrave",
  Harkerheig = "harkerheig",
  Harleanbea = "harleanbea",
  Harleton = "harleton",
  Harllee = "harllee",
  Harmon = "harmon",
  Harmony = "harmony",
  Harmonyhil = "harmonyhil",
  Harper = "harper",
  Harrington = "harrington",
  Harris = "harris",
  HarrisAcademny = "harrisAcademny",
  HarrisAcademy = "harrisAcademy",
  Harrison = "harrison",
  HarrisonJeffersonMadisonComplex = "harrisonJeffersonMadisonComplex",
  HarryMckillop = "harryMckillop",
  Hart = "hart",
  Hartman = "hartman",
  Hartsbluff = "hartsbluff",
  Haskell = "haskell",
  Haslet = "haslet",
  Hastings = "hastings",
  Hatfield = "hatfield",
  Haun = "haun",
  Havard = "havard",
  Hawkins = "hawkins",
  Hawley = "hawley",
  Hawthorne = "hawthorne",
  Haybranch = "haybranch",
  Haynes = "haynes",
  HazelHarveyPeace = "hazelHarveyPeace",
  HebronValley = "hebronValley",
  Hedgcoxe = "hedgcoxe",
  Hedrick = "hedrick",
  Heights = "heights",
  Helbing = "helbing",
  Helms = "helms",
  Helotes = "helotes",
  Hemphill = "hemphill",
  Henderson = "henderson",
  Henrietta = "henrietta",
  HenryWSory = "henryWSory",
  Herff = "herff",
  Heritage = "heritage",
  HeritageHills = "heritageHills",
  Hermliegh = "hermliegh",
  Hernandez = "hernandez",
  HerschelZellars = "herschelZellars",
  Hettiehals = "hettiehals",
  Hewitt = "hewitt",
  Hexter = "hexter",
  Hickey = "hickey",
  Hicks = "hicks",
  Hico = "hico",
  Hiddencov = "hiddencov",
  Hiddenfore = "hiddenfore",
  Hiddenlake = "hiddenlake",
  Higgins = "higgins",
  Highctry = "highctry",
  Highland = "highland",
  HighlandPark = "highlandPark",
  HighlandVillage = "highlandVillage",
  Highlandfo = "highlandfo",
  Highlandhi = "highlandhi",
  Highlandla = "highlandla",
  Highlandpa = "highlandpa",
  Highlandpk = "highlandpk",
  Highlands = "highlands",
  Highlndmea = "highlndmea",
  Highpointe = "highpointe",
  Hightower = "hightower",
  Hileman = "hileman",
  Hill = "hill",
  Hillcrest = "hillcrest",
  Hilley = "hilley",
  Hillsboro = "hillsboro",
  Hillside = "hillside",
  Hilltop = "hilltop",
  Hirsch = "hirsch",
  HlBrockett = "hlBrockett",
  HobbsWilliams = "hobbsWilliams",
  Hodge = "hodge",
  Hodges = "hodges",
  Hoelscher = "hoelscher",
  Hoffmann = "hoffmann",
  Hogg = "hogg",
  Holiday = "holiday",
  Hollandl = "hollandl",
  Holliday = "holliday",
  Holliepars = "holliepars",
  HollisDeitz = "hollisDeitz",
  Holmes = "holmes",
  Homestead = "homestead",
  Hommel = "hommel",
  Honduras = "honduras",
  Honeygrove = "honeygrove",
  HoodCase = "hoodCase",
  Hooe = "hooe",
  Hooks = "hooks",
  Hoover = "hoover",
  Hopkins = "hopkins",
  Horizonhei = "horizonhei",
  Hosp = "hosp",
  Hotchkiss = "hotchkiss",
  Houston = "houston",
  Howard = "howard",
  Howe = "howe",
  Hubbard = "hubbard",
  Huckabay = "huckabay",
  Hudson = "hudson",
  Huebnerrd = "huebnerrd",
  Hueco = "hueco",
  Huffman = "huffman",
  Hughes = "hughes",
  HughesSprings = "hughesSprings",
  Hughey = "hughey",
  Hughston = "hughston",
  Hulbert = "hulbert",
  Huley = "huley",
  HumphreysHilnd = "humphreysHilnd",
  Hunt = "hunt",
  Huntsville = "huntsville",
  Huppertz = "huppertz",
  Hursthills = "hursthills",
  Hutchins = "hutchins",
  Hutchinson = "hutchinson",
  Hutto = "hutto",
  HydePark = "hydePark",
  Hyer = "hyer",
  Hyman = "hyman",
  ITHolleman = "itHolleman",
  Idaho = "idaho",
  Ikard = "ikard",
  Illinois = "illinois",
  Independence = "independence",
  IndianCreek = "indianCreek",
  Indiana = "indiana",
  Indiancre = "indiancre",
  Indonesia = "indonesia",
  IndustrialEast = "industrialEast",
  IndustrialWest = "industrialWest",
  Inglish = "inglish",
  Ingram = "ingram",
  Iraan = "iraan",
  Iracross = "iracross",
  Iredell = "iredell",
  Ireland = "ireland",
  IreneClinkscale = "ireneClinkscale",
  Irion = "irion",
  IrmaDruHutchison = "irmaDruHutchison",
  Irvin = "irvin",
  Irving = "irving",
  Isbell = "isbell",
  Italy = "italy",
  Itasca = "itasca",
  JBStephens = "jbStephens",
  JCiavarra = "jCiavarra",
  JacintoCity = "jacintoCity",
  JackLoweSr = "jackLoweSr",
  Jackbinion = "jackbinion",
  JackieFuller = "jackieFuller",
  Jacksboro = "jacksboro",
  Jackson = "jackson",
  JacksonRooseveltComplex = "jacksonRooseveltComplex",
  Jacksonkel = "jacksonkel",
  Jacksonmay = "jacksonmay",
  Jacksonsto = "jacksonsto",
  Jamacia = "jamacia",
  James = "james",
  JamesAMonaco = "jamesAMonaco",
  Japhet = "japhet",
  Jarrell = "jarrell",
  Jbrockett = "jbrockett",
  JeanColeman = "jeanColeman",
  Jeffersave = "jeffersave",
  Jefferson = "jefferson",
  Jenkins = "jenkins",
  JennyPreston = "jennyPreston",
  JerryJunkins = "jerryJunkins",
  JesseMcgowen = "jesseMcgowen",
  JimSpradley = "jimSpradley",
  JimmieTylerBrashear = "jimmieTylerBrashear",
  JoeKBryant = "joeKBryant",
  JohnABaker = "johnABaker",
  JohnASippel = "johnASippel",
  JohnAndMargieStipes = "johnAndMargieStipes",
  JohnandNeldaPartin = "johnandNeldaPartin",
  JohnAndOliveHinojosa = "johnAndOliveHinojosa",
  JohnTWhite = "johnTWhite",
  JohnTower = "johnTower",
  JohnieDaniel = "johnieDaniel",
  Johnosn = "johnosn",
  Johns = "johns",
  Johnson = "johnson",
  JohnsonRanch = "johnsonRanch",
  Johnston = "johnston",
  Jollyville = "jollyville",
  Jones = "jones",
  Jonesboro = "jonesboro",
  Jordan = "jordan",
  JoseHDamian = "joseHDamian",
  JoyceHall = "joyceHall",
  JtStevens = "jtStevens",
  JudithHarlow = "judithHarlow",
  JudyHajek = "judyHajek",
  JudyMiller = "judyMiller",
  JudyRucker = "judyRucker",
  Junction = "junction",
  JuneWDavis = "juneWDavis",
  Justin = "justin",
  Justiss = "justiss",
  Kahn = "kahn",
  Kansas = "kansas",
  Karnack = "karnack",
  Karnescoun = "karnescoun",
  KathrynGriffis = "kathrynGriffis",
  Kawas = "kawas",
  KayGranger = "kayGranger",
  Keene = "keene",
  Kellerharv = "kellerharv",
  Kelley = "kelley",
  Kemp = "kemp",
  Kendall = "kendall",
  Kenedy = "kenedy",
  Kennard = "kennard",
  Kennedy = "kennedy",
  Kennedypow = "kennedypow",
  Kent = "kent",
  Kentwood = "kentwood",
  Kerens = "kerens",
  Kerr = "kerr",
  Key = "key",
  Keyes = "keyes",
  Keys = "keys",
  Kidwell = "kidwell",
  Kiest = "kiest",
  Kilgore = "kilgore",
  Kimball = "kimball",
  KinderTractArea = "kinderTractArea",
  Kindred = "kindred",
  King = "king",
  KingsManor = "kingsManor",
  Kirby = "kirby",
  Kirbyville = "kirbyville",
  Kirchner = "kirchner",
  Kirkpatric = "kirkpatric",
  Kleberg = "kleberg",
  Knight = "knight",
  Knox = "knox",
  KnoxCity = "knoxCity",
  Koennecke = "koennecke",
  Kohlberg = "kohlberg",
  Kopperl = "kopperl",
  Kostoryz = "kostoryz",
  Kountze = "kountze",
  Kramer = "kramer",
  Kriewaldrd = "kriewaldrd",
  LaGrange = "laGrange",
  LaMesa = "laMesa",
  LaUnion = "laUnion",
  LaVega = "laVega",
  LaVillita = "laVillita",
  Lackland = "lackland",
  Lacy = "lacy",
  Lagovista = "lagovista",
  Lagow = "lagow",
  LakePointe = "lakePointe",
  LakeTravis = "lakeTravis",
  Lakedallas = "lakedallas",
  Lakehighla = "lakehighla",
  Lakeland = "lakeland",
  Lakeridge = "lakeridge",
  Lakeside = "lakeside",
  Lakeview = "lakeview",
  Lakeway = "lakeway",
  Lakewood = "lakewood",
  Lamar = "lamar",
  Lamkin = "lamkin",
  Lancaster = "lancaster",
  LanceThompson = "lanceThompson",
  Landergin = "landergin",
  Landry = "landry",
  Laneville = "laneville",
  Lanier = "lanier",
  Lansberry = "lansberry",
  Lapoyner = "lapoyner",
  Larkspur = "larkspur",
  Larson = "larson",
  LarueMiller = "larueMiller",
  Lascolinas = "lascolinas",
  Laspalmas = "laspalmas",
  Latexo = "latexo",
  LauraIngallsWilder = "lauraIngallsWilder",
  Laurelmoun = "laurelmoun",
  Lawhon = "lawhon",
  Lawn = "lawn",
  Lawndale = "lawndale",
  Lawrence = "lawrence",
  Lebarronp = "lebarronp",
  Lee = "lee",
  LeeMcshan = "leeMcshan",
  Leerobert = "leerobert",
  Leeumphre = "leeumphre",
  LelandEdge = "lelandEdge",
  Leon = "leon",
  LeonSprings = "leonSprings",
  Leonard = "leonard",
  Leonheight = "leonheight",
  LeonidesGonzalezCigarroaMd = "leonidesGonzalezCigarroaMd",
  LetaHornSmith = "letaHornSmith",
  Lewis = "lewis",
  Lewisville = "lewisville",
  Lexington = "lexington",
  Leyendecke = "leyendecke",
  Libby = "libby",
  Liberty = "liberty",
  LibertyEylau = "libertyEylau",
  Libertyhil = "libertyhil",
  Ligarde = "ligarde",
  LightFarms = "lightFarms",
  Lillian = "lillian",
  LindaLyon = "lindaLyon",
  Linden = "linden",
  Lindsay = "lindsay",
  Linglevill = "linglevill",
  Lipan = "lipan",
  Lipscomb = "lipscomb",
  Lisbon = "lisbon",
  Liscano = "liscano",
  Little = "little",
  Littlebay = "littlebay",
  Lively = "lively",
  Liveoak = "liveoak",
  Llano = "llano",
  Logan = "logan",
  Lohn = "lohn",
  LoisLindsey = "loisLindsey",
  Lomapark = "lomapark",
  Lomaterrac = "lomaterrac",
  Lometa = "lometa",
  London = "london",
  LoneStar = "loneStar",
  Loneoak = "loneoak",
  Lonestar = "lonestar",
  Long = "long",
  Longbranch = "longbranch",
  Longfellow = "longfellow",
  Longscreek = "longscreek",
  Loraine = "loraine",
  Lorena = "lorena",
  Loretto = "loretto",
  LosFresnos = "losFresnos",
  Losncino = "losncino",
  LostPines = "lostPines",
  Lotspeich = "lotspeich",
  Lott = "lott",
  LouiseCabaniss = "louiseCabaniss",
  Louisiana = "louisiana",
  Lovejoy = "lovejoy",
  Lovelady = "lovelady",
  Lowe = "lowe",
  Loweryrd = "loweryrd",
  Lozano = "lozano",
  LucilleRogersAshley = "lucilleRogersAshley",
  LuedersAvoca = "luedersAvoca",
  Luellamerr = "luellamerr",
  Lujanchave = "lujanchave",
  LyndonBJohnson = "lyndonBJohnson",
  Lytle = "lytle",
  Mabank = "mabank",
  Macarthur = "macarthur",
  Macdonell = "macdonell",
  Macias = "macias",
  Mackey = "mackey",
  Macon = "macon",
  MadeleyRanch = "madeleyRanch",
  Madison = "madison",
  Madisonville = "madisonville",
  Maestevens = "maestevens",
  Magee = "magee",
  Maine = "maine",
  Malakoff = "malakoff",
  Malone = "malone",
  Malvern = "malvern",
  Mambrino = "mambrino",
  Manor = "manor",
  Manormaria = "manormaria",
  Manorthoma = "manorthoma",
  Manualjara = "manualjara",
  MapleBrook = "mapleBrook",
  Maplelawn = "maplelawn",
  Marblefall = "marblefall",
  Marcus = "marcus",
  Marcy = "marcy",
  MarcyLykins = "marcyLykins",
  Marfa = "marfa",
  MargaretFelty = "margaretFelty",
  Margil = "margil",
  Marietta = "marietta",
  MarilynMiller = "marilynMiller",
  Marinecree = "marinecree",
  Marion = "marion",
  Markham = "markham",
  Marlboro = "marlboro",
  Marlin = "marlin",
  Marsalis = "marsalis",
  Marshall = "marshall",
  Mart = "mart",
  Marti = "marti",
  Martinez = "martinez",
  Martinjr = "martinjr",
  Martinsmil = "martinsmil",
  Martinwalk = "martinwalk",
  Marvin = "marvin",
  MaryBurksMarek = "maryBurksMarek",
  MaryJoSheppard = "maryJoSheppard",
  MaryLouDodson = "maryLouDodson",
  Maryland = "maryland",
  Mason = "mason",
  Massachusetts = "massachusetts",
  Mata = "mata",
  Matagorda = "matagorda",
  Mathews = "mathews",
  Maudelogan = "maudelogan",
  Maudriewal = "maudriewal",
  Maverick = "maverick",
  MaxHSimpson = "maxHSimpson",
  Maxdale = "maxdale",
  Maxfield = "maxfield",
  May = "may",
  Mayes = "mayes",
  Maypearl = "maypearl",
  Mays = "mays",
  Mcauliffe = "mcauliffe",
  Mcbay = "mcbay",
  Mcclendon = "mcclendon",
  LizzieNellCundiffMcclure = "lizzieNellCundiffMcclure",
  Mccoy = "mccoy",
  Mcgaha = "mcgaha",
  Mckamy = "mckamy",
  Mckenzie = "mckenzie",
  Mclaughlin = "mclaughlin",
  Mcmillan = "mcmillan",
  Mcnair = "mcnair",
  Mcneil = "mcneil",
  Mcqueeney = "mcqueeney",
  Mcrae = "mcrae",
  McSpedden = "mcSpedden",
  Mcwhorter = "mcwhorter",
  Meadowbroo = "meadowbroo",
  Meadowcree = "meadowcree",
  Meadowcrk = "meadowcrk",
  Meadows = "meadows",
  Medinavall = "medinavall",
  MelbaPassmore = "melbaPassmore",
  Melissa = "melissa",
  Memorial = "memorial",
  Menard = "menard",
  Mendenhall = "mendenhall",
  Mendoza = "mendoza",
  Menger = "menger",
  Meridian = "meridian",
  Meridithdu = "meridithdu",
  Merkel = "merkel",
  Merrifield = "merrifield",
  MerrimanPark = "merrimanPark",
  Merrimanpk = "merrimanpk",
  MesaVerde = "mesaVerde",
  Mesavista = "mesavista",
  Mesita = "mesita",
  Mexico = "mexico",
  Meyer = "meyer",
  Michael = "michael",
  Michigan = "michigan",
  Midcrown = "midcrown",
  Midwaypark = "midwaypark",
  MikeandJanieReeves = "mikeandJanieReeves",
  MikeMoseley = "mikeMoseley",
  Milam = "milam",
  Milano = "milano",
  Mildrdhawk = "mildrdhawk",
  Mildred = "mildred",
  Miles = "miles",
  Milford = "milford",
  Miller = "miller",
  Millergrov = "millergrov",
  Millerheig = "millerheig",
  Millerpoin = "millerpoin",
  Mills = "mills",
  Millsap = "millsap",
  Milton = "milton",
  Mina = "mina",
  Mineola = "mineola",
  Minshew = "minshew",
  Mireles = "mireles",
  MissionVa = "missionVa",
  Mississippi = "mississippi",
  Missjewell = "missjewell",
  Missouri = "missouri",
  Mitchell = "mitchell",
  Mitchellbl = "mitchellbl",
  Moates = "moates",
  Mockingbir = "mockingbir",
  Mohawk = "mohawk",
  MollyLivengoodCarter = "mollyLivengoodCarter",
  Monahan = "monahan",
  Montague = "montague",
  Montaguevi = "montaguevi",
  Montana = "montana",
  Montanavis = "montanavis",
  Montclair = "montclair",
  Montgomery = "montgomery",
  Moody = "moody",
  Mooneyham = "mooneyham",
  Moore = "moore",
  MooreMh = "mooreMh",
  Mooresally = "mooresally",
  Moran = "moran",
  Moreno = "moreno",
  Morgan = "morgan",
  MorganMil = "morganMil",
  Morningsid = "morningsid",
  Morningside = "morningside",
  Morrill = "morrill",
  Morris = "morris",
  Morton = "morton",
  Moseley = "moseley",
  Moss = "moss",
  Mosshaven = "mosshaven",
  Motley = "motley",
  MotleyCountySchool = "motleyCountySchool",
  Mound = "mound",
  Mounger = "mounger",
  MountEnterprise = "mountEnterprise",
  Mountainvi = "mountainvi",
  Mountaubur = "mountaubur",
  MrsJerryBryant = "mrsJerryBryant",
  Mtcalm = "mtcalm",
  Mtpeak = "mtpeak",
  Mtvernon = "mtvernon",
  Muenster = "muenster",
  Mullendore = "mullendore",
  Mullin = "mullin",
  Mumford = "mumford",
  Munday = "munday",
  Murchison = "murchison",
  MurryFly = "murryFly",
  NHiMt = "nHiMt",
  Nacogdoches = "nacogdoches",
  Nacona = "nacona",
  NancyNeal = "nancyNeal",
  NancySmith = "nancySmith",
  NaomiPress = "naomiPress",
  Nash = "nash",
  Natalia = "natalia",
  Nathahowel = "nathahowel",
  Naumann = "naumann",
  Navarro = "navarro",
  Neal = "neal",
  Neches = "neches",
  NellHillRhea = "nellHillRhea",
  Nelson = "nelson",
  Nesmith = "nesmith",
  Nevada = "nevada",
  NewCaney = "newCaney",
  NewHome = "newHome",
  NewMexico = "newMexico",
  NewSummerfield = "newSummerfield",
  NewWaverly = "newWaverly",
  NewYork = "newYork",
  Newcastle = "newcastle",
  Newdiana = "newdiana",
  Newman = "newman",
  Newton = "newton",
  Newtonrayzor = "newtonrayzor",
  Nicaragua = "nicaragua",
  Nichols = "nichols",
  Nimitz = "nimitz",
  Nitsch = "nitsch",
  Nixon = "nixon",
  Njoshua = "njoshua",
  NobleReed = "nobleReed",
  Nocona = "nocona",
  Nolanville = "nolanville",
  NormaJPaschal = "normaJPaschal",
  NormandyCrossing = "normandyCrossing",
  Normangee = "normangee",
  Norris = "norris",
  North = "north",
  NorthCarolina = "northCarolina",
  NorthCreek = "northCreek",
  NorthDakota = "northDakota",
  NorthGrammar = "northGrammar",
  NorthHeights = "northHeights",
  NorthShore = "northShore",
  Northbrook = "northbrook",
  Northernhi = "northernhi",
  Northeules = "northeules",
  Northhopki = "northhopki",
  Northhts = "northhts",
  Northlake = "northlake",
  Northloop = "northloop",
  Northrichl = "northrichl",
  Northridg = "northridg",
  Northriver = "northriver",
  Northside = "northside",
  Northstar = "northstar",
  Northwest = "northwest",
  Northwood = "northwood",
  Norton = "norton",
  Norwood = "norwood",
  NtreisTestOnly = "ntreisTestOnly",
  Nueces = "nueces",
  OakCreek = "oakCreek",
  OakDale = "oakDale",
  OakForest = "oakForest",
  OakPark = "oakPark",
  OakPoint = "oakPoint",
  Oakgrove = "oakgrove",
  Oakhurst = "oakhurst",
  Oaklawn = "oaklawn",
  Oakmeadow = "oakmeadow",
  Oakmont = "oakmont",
  Oakwood = "oakwood",
  Oakwoods = "oakwoods",
  Oakwoodter = "oakwoodter",
  Ochoa = "ochoa",
  ODell = "oDell",
  Odonnell = "odonnell",
  Ogden = "ogden",
  Ogle = "ogle",
  Oglesby = "oglesby",
  Ohenry = "ohenry",
  Ohio = "ohio",
  Oklahoma = "oklahoma",
  OldSettlers = "oldSettlers",
  Oldtown = "oldtown",
  Oldunion = "oldunion",
  Olfen = "olfen",
  OliveStephens = "oliveStephens",
  Oliver = "oliver",
  OliverClift = "oliverClift",
  Olmos = "olmos",
  Olney = "olney",
  Olsen = "olsen",
  OlsenPark = "olsenPark",
  Olson = "olson",
  Olympia = "olympia",
  OpalSmith = "opalSmith",
  Oppe = "oppe",
  Orecity = "orecity",
  Oregon = "oregon",
  Orr = "orr",
  Ortiz = "ortiz",
  Osheakeleh = "osheakeleh",
  Other = "other",
  OuidaSpringer = "ouidaSpringer",
  Overton = "overton",
  Owen = "owen",
  Owens = "owens",
  Ozona = "ozona",
  Packsaddle = "packsaddle",
  Paducah = "paducah",
  PaintCreek = "paintCreek",
  PaintRock = "paintRock",
  Palmer = "palmer",
  PaloPinto = "paloPinto",
  Paloalto = "paloalto",
  PalomaCreek = "palomaCreek",
  Panama = "panama",
  Panhandle = "panhandle",
  PantherCreek = "pantherCreek",
  Paradise = "paradise",
  ParamountTerrace = "paramountTerrace",
  Park = "park",
  ParkLakes = "parkLakes",
  Parker = "parker",
  Parkglen = "parkglen",
  Parkland = "parkland",
  Parkview = "parkview",
  Parkvillag = "parkvillag",
  Parkway = "parkway",
  Parmley = "parmley",
  Parnell = "parnell",
  Parsons = "parsons",
  Pasemann = "pasemann",
  Pasodale = "pasodale",
  PateAm = "pateAm",
  Patlan = "patlan",
  PatriciaDeanBoswellMccall = "patriciaDeanBoswellMccall",
  Patterson = "patterson",
  PattonSprings = "pattonSprings",
  PaulBelton = "paulBelton",
  Peabody = "peabody",
  Pearcy = "pearcy",
  Pease = "pease",
  Peaster = "peaster",
  Pebblecree = "pebblecree",
  Pebblehill = "pebblehill",
  Pecancreek = "pecancreek",
  Pecanvalle = "pecanvalle",
  Peebles = "peebles",
  Peeler = "peeler",
  Peete = "peete",
  Penelope = "penelope",
  Pennsylvania = "pennsylvania",
  Penny = "penny",
  Perales = "perales",
  PercyWNeblett = "percyWNeblett",
  Perot = "perot",
  Perrin = "perrin",
  Pershing = "pershing",
  Pershingpa = "pershingpa",
  Peru = "peru",
  PetersColony = "petersColony",
  Peterson = "peterson",
  Petrolia = "petrolia",
  Petronila = "petronila",
  Pettus = "pettus",
  Phillips = "phillips",
  PhillipsM = "phillipsM",
  PhylissNesmith = "phylissNesmith",
  Pickett = "pickett",
  Pierce = "pierce",
  PilotPoint = "pilotPoint",
  PineForest = "pineForest",
  PineRidge = "pineRidge",
  Pinetree = "pinetree",
  Pink = "pink",
  Pinkerton = "pinkerton",
  Pirrung = "pirrung",
  Pirtle = "pirtle",
  PleasantGrove = "pleasantGrove",
  PleasantRun = "pleasantRun",
  PleasantValley = "pleasantValley",
  Pleasantgr = "pleasantgr",
  PlumCreek = "plumCreek",
  Plummer = "plummer",
  PointComfort = "pointComfort",
  Polk = "polk",
  Polser = "polser",
  Ponder = "ponder",
  Pondspring = "pondspring",
  Poolville = "poolville",
  Pope = "pope",
  PortIsabel = "portIsabel",
  PortNeches = "portNeches",
  PortOConnor = "portOConnor",
  Porter = "porter",
  Post = "post",
  Postma = "postma",
  Potranco = "potranco",
  Pottsboro = "pottsboro",
  Powell = "powell",
  Poynter = "poynter",
  PrairieLea = "prairieLea",
  PrairieTrail = "prairieTrail",
  PrairieVa = "prairieVa",
  Prairiecre = "prairiecre",
  Prairievie = "prairievie",
  Presa = "presa",
  Prescott = "prescott",
  Presidio = "presidio",
  Prestonhol = "prestonhol",
  Prestonwoo = "prestonwoo",
  PrestwickK8Stem = "prestwickK8Stem",
  Price = "price",
  Priddy = "priddy",
  Providence = "providence",
  Puckett = "puckett",
  PuertoRico = "puertoRico",
  Pullen = "pullen",
  Purefoy = "purefoy",
  Purl = "purl",
  PurpleSage = "purpleSage",
  Purplesage = "purplesage",
  Putnam = "putnam",
  Pyburn = "pyburn",
  Quitman = "quitman",
  RAHall = "raHall",
  RSteveFolsom = "rSteveFolsom",
  RJWilson = "rjWilson",
  Race = "race",
  Raguet = "raguet",
  Rains = "rains",
  Rainwater = "rainwater",
  RalphandMaryLynnBoyer = "ralphandMaryLynnBoyer",
  Ramey = "ramey",
  Ramona = "ramona",
  Randall = "randall",
  Randolph = "randolph",
  Range = "range",
  Ranger = "ranger",
  Rankin = "rankin",
  Rann = "rann",
  Rasor = "rasor",
  Ray = "ray",
  Rayburn = "rayburn",
  Rayeallen = "rayeallen",
  Rayzor = "rayzor",
  Reagan = "reagan",
  Rebeccacrk = "rebeccacrk",
  RedLick = "redLick",
  RedOak = "redOak",
  Redlandoak = "redlandoak",
  Redrock = "redrock",
  Redsands = "redsands",
  Redwater = "redwater",
  Reecescree = "reecescree",
  Reed = "reed",
  ReevesHinger = "reevesHinger",
  Reevhinger = "reevhinger",
  Regencypla = "regencypla",
  Reid = "reid",
  Reilly = "reilly",
  Reinhardt = "reinhardt",
  Remingtnpt = "remingtnpt",
  Remynse = "remynse",
  Reno = "reno",
  Reynolds = "reynolds",
  Rhodes = "rhodes",
  Rice = "rice",
  RichardJLee = "richardJLee",
  Richards = "richards",
  RichardsonHeights = "richardsonHeights",
  Richardste = "richardste",
  Richland = "richland",
  Riddle = "riddle",
  Ridgecrest = "ridgecrest",
  Ridgeview = "ridgeview",
  Ridgewood = "ridgewood",
  Ridgleahil = "ridgleahil",
  Ringgold = "ringgold",
  RioHondo = "rioHondo",
  RioVista = "rioVista",
  Risdacadem = "risdacadem",
  Rishardshe = "rishardshe",
  RisingStar = "risingStar",
  RitaDrabek = "ritaDrabek",
  RiverRidge = "riverRidge",
  Rivera = "rivera",
  Riverchase = "riverchase",
  Rivercrest = "rivercrest",
  Riversidep = "riversidep",
  Rivertrail = "rivertrail",
  RjRichey = "rjRichey",
  Roanforest = "roanforest",
  Roanoke = "roanoke",
  Roark = "roark",
  Robb = "robb",
  Roberson = "roberson",
  RobertCrippen = "robertCrippen",
  RobertKing = "robertKing",
  RobertLPuster = "robertLPuster",
  Robertlee = "robertlee",
  Roberts = "roberts",
  RobertsRoad = "robertsRoad",
  Robertson = "robertson",
  Robinson = "robinson",
  Roby = "roby",
  Rochelle = "rochelle",
  Rochester = "rochester",
  Rockbrook = "rockbrook",
  Rockdale = "rockdale",
  Rockenbaug = "rockenbaug",
  Rockprairi = "rockprairi",
  Rocksprings = "rocksprings",
  Rodriguez = "rodriguez",
  Rogers = "rogers",
  Rojax = "rojax",
  RollingHills = "rollingHills",
  Rollinghil = "rollinghil",
  Roosevelt = "roosevelt",
  RoqueGuerraJr = "roqueGuerraJr",
  Roquemore = "roquemore",
  RosaParksMillbrook = "rosaParksMillbrook",
  Roscoe = "roscoe",
  RoseGarden = "roseGarden",
  Rosemeade = "rosemeade",
  Rosemont = "rosemont",
  RosemontPark = "rosemontPark",
  Rosenberg = "rosenberg",
  Rositavall = "rositavall",
  Rotan = "rotan",
  Rountree = "rountree",
  Rowe = "rowe",
  Roxton = "roxton",
  RoyWRoberts = "royWRoberts",
  Royalridge = "royalridge",
  Royalwood = "royalwood",
  Rugel = "rugel",
  Rule = "rule",
  Runge = "runge",
  Runyon = "runyon",
  Rush = "rush",
  Rusk = "rusk",
  Russell = "russell",
  RussellSchupmann = "russellSchupmann",
  RusticOak = "rusticOak",
  RuthandHaroldFrazier = "ruthandHaroldFrazier",
  RuthCherry = "ruthCherry",
  Rutherford = "rutherford",
  Ryan = "ryan",
  Ryanws = "ryanws",
  SAndS = "sAndS",
  Sabinal = "sabinal",
  Sabine = "sabine",
  Sagamorehi = "sagamorehi",
  Sageland = "sageland",
  Saginaw = "saginaw",
  Saigling = "saigling",
  SaintJo = "saintJo",
  Salazar = "salazar",
  Saldivar = "saldivar",
  Saltillo = "saltillo",
  SamJohnson = "samJohnson",
  Sambrano = "sambrano",
  Samrayburn = "samrayburn",
  Samrosen = "samrosen",
  SanAugustine = "sanAugustine",
  SanJacinto = "sanJacinto",
  SanSaba = "sanSaba",
  Sanandres = "sanandres",
  Sanborn = "sanborn",
  Sanchez = "sanchez",
  SandbrockRanch = "sandbrockRanch",
  Sanders = "sanders",
  Sanger = "sanger",
  Sanjacinto = "sanjacinto",
  Sanluis = "sanluis",
  Sanpedro = "sanpedro",
  SantaAnna = "santaAnna",
  SantaFe = "santaFe",
  SantaRita = "santaRita",
  Santamaria = "santamaria",
  Santo = "santo",
  Santonino = "santonino",
  Saugustine = "saugustine",
  Savannah = "savannah",
  SavannahLakes = "savannahLakes",
  Savoy = "savoy",
  Scarborough = "scarborough",
  Schanen = "schanen",
  Scheh = "scheh",
  Schell = "schell",
  Schenck = "schenck",
  Schertz = "schertz",
  Schulz = "schulz",
  Schulze = "schulze",
  Schurz = "schurz",
  Schuster = "schuster",
  Scotland = "scotland",
  Scotsdale = "scotsdale",
  Scott = "scott",
  Scurry = "scurry",
  Seabourn = "seabourn",
  Seagoville = "seagoville",
  Secomines = "secomines",
  Seele = "seele",
  Seguin = "seguin",
  Sellers = "sellers",
  Sem = "sem",
  SenderaRanch = "senderaRanch",
  Sequoyah = "sequoyah",
  SereneHills = "sereneHills",
  Serna = "serna",
  Sevenhills = "sevenhills",
  Seymour = "seymour",
  Shackelfor = "shackelfor",
  ShadyGrove = "shadyGrove",
  ShadyShores = "shadyShores",
  Shadybrook = "shadybrook",
  Shadycrest = "shadycrest",
  Shadygrove = "shadygrove",
  Shadyoaks = "shadyoaks",
  Shands = "shands",
  SharonShannon = "sharonShannon",
  Shaw = "shaw",
  Shawnee = "shawnee",
  Sheffield = "sheffield",
  Sheldon = "sheldon",
  Shepard = "shepard",
  Sherrod = "sherrod",
  SherryandPaulHamm = "sherryandPaulHamm",
  Shields = "shields",
  Shive = "shive",
  Short = "short",
  Sides = "sides",
  Sidney = "sidney",
  SidneyHPoynter = "sidneyHPoynter",
  Siebert = "siebert",
  SierraBlanca = "sierraBlanca",
  Sierravist = "sierravist",
  Sigler = "sigler",
  Silberstei = "silberstei",
  Silvercree = "silvercree",
  Silvercrest = "silvercrest",
  Silverlake = "silverlake",
  Sims = "sims",
  Sinclair = "sinclair",
  Sivellsben = "sivellsben",
  Skaggs = "skaggs",
  Skyharbour = "skyharbour",
  Skyview = "skyview",
  Slack = "slack",
  Slaughter = "slaughter",
  SleepyHollow = "sleepyHollow",
  Slidell = "slidell",
  Slocum = "slocum",
  Smith = "smith",
  Smithfield = "smithfield",
  Snook = "snook",
  Snowheight = "snowheight",
  Snyder = "snyder",
  Somerset = "somerset",
  Somerville = "somerville",
  Sonntag = "sonntag",
  SonnyAndAllegraNance = "sonnyAndAllegraNance",
  Sonora = "sonora",
  Souder = "souder",
  SourLake = "sourLake",
  South = "south",
  SouthAfrica = "southAfrica",
  SouthAthens = "southAthens",
  SouthDakota = "southDakota",
  SouthGeorgia = "southGeorgia",
  SouthKnoll = "southKnoll",
  SouthWard = "southWard",
  Southbirdv = "southbirdv",
  Southbosqu = "southbosqu",
  Southdavis = "southdavis",
  Southeast = "southeast",
  Southeules = "southeules",
  Southftwor = "southftwor",
  Southhills = "southhills",
  Southhimou = "southhimou",
  Southlawn = "southlawn",
  Southloop = "southloop",
  Southmarsh = "southmarsh",
  Southridge = "southridge",
  Southside = "southside",
  Southwest = "southwest",
  Southwoodv = "southwoodv",
  Spain = "spain",
  Sparks = "sparks",
  Sparta = "sparta",
  Spears = "spears",
  Specht = "specht",
  Speeglevil = "speeglevil",
  Speer = "speer",
  Spicer = "spicer",
  Spicewood = "spicewood",
  Spigner = "spigner",
  SpringCanyon = "springCanyon",
  SpringCreek = "springCreek",
  SpringValley = "springValley",
  Springardn = "springardn",
  Springdale = "springdale",
  Springer = "springer",
  Springlkpk = "springlkpk",
  Springmead = "springmead",
  Springridge = "springridge",
  Springtown = "springtown",
  Springvall = "springvall",
  Spur = "spur",
  Ssdillow = "ssdillow",
  StLucia = "stLucia",
  StVincent = "stVincent",
  Stafford = "stafford",
  Stahl = "stahl",
  Stanfield = "stanfield",
  Stanton = "stanton",
  StantonSmith = "stantonSmith",
  Staples = "staples",
  Stark = "stark",
  Starkey = "starkey",
  Starks = "starks",
  Starrett = "starrett",
  Startzville = "startzville",
  Steele = "steele",
  Steinerran = "steinerran",
  Stemmons = "stemmons",
  Stevenspar = "stevenspar",
  Stewart = "stewart",
  StewartCreek = "stewartCreek",
  StewartsCreek = "stewartsCreek",
  Stinson = "stinson",
  Stjoseph = "stjoseph",
  Stockdale = "stockdale",
  Stonegate = "stonegate",
  Stoneharry = "stoneharry",
  Stoneoak = "stoneoak",
  Stonewall = "stonewall",
  Storm = "storm",
  Story = "story",
  Stowe = "stowe",
  Strawn = "strawn",
  Street = "street",
  Stribling = "stribling",
  Stuard = "stuard",
  Stultsrd = "stultsrd",
  Sudan = "sudan",
  SueCrouch = "sueCrouch",
  SueEvelynRattan = "sueEvelynRattan",
  Sugarloaf = "sugarloaf",
  Sulphurblu = "sulphurblu",
  Sulphursp = "sulphursp",
  Sulross = "sulross",
  Sumeer = "sumeer",
  Sundown = "sundown",
  Sundownln = "sundownln",
  Sunnyvale = "sunnyvale",
  Sunrise = "sunrise",
  Sunset = "sunset",
  Sunvalley = "sunvalley",
  SusieFuentes = "susieFuentes",
  Swift = "swift",
  Swinburn = "swinburn",
  Sycamore = "sycamore",
  Syrratt = "syrratt",
  TaSims = "taSims",
  Tadlock = "tadlock",
  Taft = "taft",
  Tally = "tally",
  Tanglewood = "tanglewood",
  Tarver = "tarver",
  Tarverrend = "tarverrend",
  Tatum = "tatum",
  Taylor = "taylor",
  Tennessee = "tennessee",
  Terrell = "terrell",
  Terry = "terry",
  TgMccord = "tgMccord",
  TheMeadows = "theMeadows",
  ThePhilippines = "thePhilippines",
  Thevillage = "thevillage",
  Thomas = "thomas",
  Thompson = "thompson",
  ThompsonLearningCenter = "thompsonLearningCenter",
  Thornton = "thornton",
  Thousandoa = "thousandoa",
  Thrall = "thrall",
  Threeriver = "threeriver",
  Threeway = "threeway",
  Throckmort = "throckmort",
  Thunderbird = "thunderbird",
  ThurgoodMarshall = "thurgoodMarshall",
  Tibbals = "tibbals",
  Tice = "tice",
  Tierradels = "tierradels",
  TimberCreek = "timberCreek",
  Timbercreek = "timbercreek",
  Timberline = "timberline",
  TimberwoodPark = "timberwoodPark",
  Timpson = "timpson",
  Tioga = "tioga",
  Tipps = "tipps",
  Tisinger = "tisinger",
  Titche = "titche",
  Tobias = "tobias",
  Tolar = "tolar",
  Tolbert = "tolbert",
  TomBean = "tomBean",
  Tornillo = "tornillo",
  Tosch = "tosch",
  Towncenter = "towncenter",
  Townley = "townley",
  Townsell = "townsell",
  Tradewind = "tradewind",
  Travis = "travis",
  Trenton = "trenton",
  Trimmier = "trimmier",
  Trinidad = "trinidad",
  Troup = "troup",
  Troy = "troy",
  Truett = "truett",
  TurnerWj = "turnerWj",
  Turneradel = "turneradel",
  Turnermart = "turnermart",
  Twain = "twain",
  Tye = "tye",
  Tyler = "tyler",
  Tynan = "tynan",
  Underwood = "underwood",
  UnionPark = "unionPark",
  Uniongrove = "uniongrove",
  Unionhill = "unionhill",
  University = "university",
  Urbanpark = "urbanpark",
  Valley = "valley",
  ValleyMil = "valleyMil",
  ValleyRanch = "valleyRanch",
  ValleyRidge = "valleyRidge",
  ValleyView = "valleyView",
  Valleycree = "valleycree",
  Valleyranc = "valleyranc",
  Valleyview = "valleyview",
  VanAlstyne = "vanAlstyne",
  VanBuren = "vanBuren",
  VanZandt = "vanZandt",
  Vandagrif = "vandagrif",
  Vanvleck = "vanvleck",
  Vaughan = "vaughan",
  VAUGHN = "vaughn",
  Vega = "vega",
  Velasco = "velasco",
  Venablevil = "venablevil",
  Venus = "venus",
  Veribest = "veribest",
  Vernon = "vernon",
  Versiawill = "versiawill",
  Vestal = "vestal",
  Vickery = "vickery",
  Vickerymea = "vickerymea",
  Vidor = "vidor",
  Vilas = "vilas",
  VintageOaksArea = "vintageOaksArea",
  Virginia = "virginia",
  Viridian = "viridian",
  Vistadelso = "vistadelso",
  Vistahills = "vistahills",
  Vitovsky = "vitovsky",
  Vogel = "vogel",
  Voigt = "voigt",
  Waco = "waco",
  Wainwright = "wainwright",
  WakeVillage = "wakeVillage",
  Wakefield = "wakefield",
  Walker = "walker",
  WalkerVanguard = "walkerVanguard",
  Walkercrk = "walkercrk",
  Wallace = "wallace",
  WallyWatkins = "wallyWatkins",
  WalnutCreek = "walnutCreek",
  WalnutGrove = "walnutGrove",
  WalnutSp = "walnutSp",
  WalnutSprings = "walnutSprings",
  Walnutbend = "walnutbend",
  Walnuthill = "walnuthill",
  Walnutsp = "walnutsp",
  Walsh = "walsh",
  WaltDisney = "waltDisney",
  Walzem = "walzem",
  Ward = "ward",
  Ware = "ware",
  Warren = "warren",
  Washington = "washington",
  Waskom = "waskom",
  Watauga = "watauga",
  Waterford = "waterford",
  Waters = "waters",
  Watts = "watts",
  Waverlypar = "waverlypar",
  WayneACox = "wayneACox",
  Weatherfor = "weatherfor",
  Webb = "webb",
  Webster = "webster",
  Wedgeworth = "wedgeworth",
  Weinert = "weinert",
  Weiss = "weiss",
  Welch = "welch",
  Wellington = "wellington",
  Wells = "wells",
  Wellsbranc = "wellsbranc",
  West = "west",
  WestEnd = "westEnd",
  WestSabine = "westSabine",
  WestTexas = "westTexas",
  WestUniversity = "westUniversity",
  WestVirginia = "westVirginia",
  Westavenue = "westavenue",
  Westbirdvi = "westbirdvi",
  Westbrook = "westbrook",
  Westcliff = "westcliff",
  Westcreek = "westcreek",
  WesternPlateau = "westernPlateau",
  Westernhil = "westernhil",
  Westhandle = "westhandle",
  Westhurst = "westhurst",
  Westlawn = "westlawn",
  Westmain = "westmain",
  WestnHill = "westnHill",
  Westoso = "westoso",
  Westpark = "westpark",
  Westrusk = "westrusk",
  Westside = "westside",
  Westward = "westward",
  Westwind = "westwind",
  Westwood = "westwood",
  Wetmore = "wetmore",
  Wheatley = "wheatley",
  Wheeler = "wheeler",
  Whitaker = "whitaker",
  White = "white",
  Whiteface = "whiteface",
  Whiteoak = "whiteoak",
  Whiterock = "whiterock",
  Whitesboro = "whitesboro",
  Whitestone = "whitestone",
  Whitewrigh = "whitewrigh",
  Whitis = "whitis",
  Whitleyrd = "whitleyrd",
  Whitney = "whitney",
  Whitt = "whitt",
  Whittier = "whittier",
  Wiederstein = "wiederstein",
  Wildorado = "wildorado",
  Willett = "willett",
  Williampas = "williampas",
  Williams = "williams",
  Williamson = "williamson",
  Willislane = "willislane",
  WillowBend = "willowBend",
  WillowCreek = "willowCreek",
  WillowWood = "willowWood",
  Willowrun = "willowrun",
  Willowspri = "willowspri",
  Wills = "wills",
  Wilmerhutc = "wilmerhutc",
  Wilmeth = "wilmeth",
  Wilshire = "wilshire",
  Wilson = "wilson",
  Wimbish = "wimbish",
  Windcrest = "windcrest",
  WindsongRanch = "windsongRanch",
  Windsor = "windsor",
  WindsorPark = "windsorPark",
  Windthorst = "windthorst",
  Winfield = "winfield",
  Winnetka = "winnetka",
  Winnsboro = "winnsboro",
  Winona = "winona",
  Winston = "winston",
  Winters = "winters",
  Wise = "wise",
  Withers = "withers",
  Woden = "woden",
  Wolfecity = "wolfecity",
  Wolflin = "wolflin",
  Wolford = "wolford",
  Wood = "wood",
  Woodcreek = "woodcreek",
  Woodcrest = "woodcrest",
  Wooden = "wooden",
  Woodlake = "woodlake",
  Woodlandht = "woodlandht",
  Woodlands = "woodlands",
  Woodlandsp = "woodlandsp",
  Woodlawn = "woodlawn",
  Woodlawnhl = "woodlawnhl",
  Woodridge = "woodridge",
  Woodriver = "woodriver",
  Woods = "woods",
  Woodsboro = "woodsboro",
  Woodson = "woodson",
  Woodstone = "woodstone",
  Woodville = "woodville",
  Woodway = "woodway",
  WorthHeig = "worthHeig",
  Wortham = "wortham",
  Wright = "wright",
  Wyatt = "wyatt",
  WylieEast = "wylieEast",
  WylieWest = "wylieWest",
  Wyoming = "wyoming",
  Yale = "yale",
  Yantis = "yantis",
  Yeager = "yeager",
  Yermo = "yermo",
  Young = "young",
  Ysleta = "ysleta",
  Zachry = "zachry",
  Zaragoza = "zaragoza",
  Zavala = "zavala",
  Zephyr = "zephyr",
  RosamondSherley = "rosamondSherley"
}

export const ElementarySchoolsLabels: Record<ElementarySchools, string> = {
  [ElementarySchools.AMAikin]: "A.M. Aikin",
  [ElementarySchools.Abbott]: "Abbott",
  [ElementarySchools.Academy]: "Academy",
  [ElementarySchools.Achziger]: "Achziger",
  [ElementarySchools.Acker]: "Acker",
  [ElementarySchools.Acton]: "Acton",
  [ElementarySchools.Adams]: "Adams",
  [ElementarySchools.Adamsjohnq]: "Adamsjohnq",
  [ElementarySchools.Adamsnatha]: "Adamsnatha",
  [ElementarySchools.AdelfaCallejo]: "Adelfa Callejo",
  [ElementarySchools.Aguadulce]: "Aguadulce",
  [ElementarySchools.Aiken]: "Aiken",
  [ElementarySchools.Aikin]: "Aikin",
  [ElementarySchools.Akin]: "Akin",
  [ElementarySchools.Alabama]: "Alabama",
  [ElementarySchools.Alamo]: "Alamo",
  [ElementarySchools.Alarcon]: "Alarcon",
  [ElementarySchools.Alaska]: "Alaska",
  [ElementarySchools.Albagolden]: "Albagolden",
  [ElementarySchools.Aldridge]: "Aldridge",
  [ElementarySchools.Alexander]: "Alexander",
  [ElementarySchools.Allen]: "Allen",
  [ElementarySchools.Alpine]: "Alpine",
  [ElementarySchools.Altavista]: "Altavista",
  [ElementarySchools.Alto]: "Alto",
  [ElementarySchools.AltoBonito]: "Alto Bonito",
  [ElementarySchools.Alton]: "Alton",
  [ElementarySchools.AlvaradoN]: "Alvarado N",
  [ElementarySchools.AlvaradoS]: "Alvarado S",
  [ElementarySchools.Alvarez]: "Alvarez",
  [ElementarySchools.Alvin]: "Alvin",
  [ElementarySchools.Alvord]: "Alvord",
  [ElementarySchools.AmberTerrace]: "Amber Terrace",
  [ElementarySchools.Amos]: "Amos",
  [ElementarySchools.AmyParksHeath]: "Amy Parks-Heath",
  [ElementarySchools.Anahuac]: "Anahuac",
  [ElementarySchools.Anderson]: "Anderson",
  [ElementarySchools.AndersonShiro]: "Anderson-Shiro",
  [ElementarySchools.Andrews]: "Andrews",
  [ElementarySchools.Anguilla]: "Anguilla",
  [ElementarySchools.AnitaScott]: "Anita Scott",
  [ElementarySchools.AnnaMayDaulton]: "Anna May Daulton",
  [ElementarySchools.Annaville]: "Annaville",
  [ElementarySchools.AnnettePerry]: "Annette Perry",
  [ElementarySchools.AnnieWebbBlanton]: "Annie Webb Blanton",
  [ElementarySchools.Anson]: "Anson",
  [ElementarySchools.Anthon]: "Anthon",
  [ElementarySchools.Anthony]: "Anthony",
  [ElementarySchools.Aoy]: "Aoy",
  [ElementarySchools.Apache]: "Apache",
  [ElementarySchools.AppleSprings]: "Apple Springs",
  [ElementarySchools.Aquilla]: "Aquilla",
  [ElementarySchools.Arapaho]: "Arapaho",
  [ElementarySchools.Arcadiapar]: "Arcadiapar",
  [ElementarySchools.Archercity]: "Archercity",
  [ElementarySchools.ArdenRoad]: "Arden Road",
  [ElementarySchools.Ardenroad]: "Ardenroad",
  [ElementarySchools.Argyle]: "Argyle",
  [ElementarySchools.ArgyleSouth]: "Argyle South",
  [ElementarySchools.ArgyleWest]: "Argyle West",
  [ElementarySchools.Arizona]: "Arizona",
  [ElementarySchools.Arkansas]: "Arkansas",
  [ElementarySchools.Arlingtonp]: "Arlingtonp",
  [ElementarySchools.ArlonRSeay]: "Arlon R Seay",
  [ElementarySchools.Armstrong]: "Armstrong",
  [ElementarySchools.Arnold]: "Arnold",
  [ElementarySchools.Arp]: "Arp",
  [ElementarySchools.Aruba]: "Aruba",
  [ElementarySchools.Ascatate]: "Ascatate",
  [ElementarySchools.Ashley]: "Ashley",
  [ElementarySchools.Ashworth]: "Ashworth",
  [ElementarySchools.Aspermont]: "Aspermont",
  [ElementarySchools.Athens]: "Athens",
  [ElementarySchools.Atherton]: "Atherton",
  [ElementarySchools.Atlanta]: "Atlanta",
  [ElementarySchools.Atwood]: "Atwood",
  [ElementarySchools.Audeliacrk]: "Audeliacrk",
  [ElementarySchools.Austin]: "Austin",
  [ElementarySchools.Australia]: "Australia",
  [ElementarySchools.Austwellti]: "Austwellti",
  [ElementarySchools.Avalon]: "Avalon",
  [ElementarySchools.Avery]: "Avery",
  [ElementarySchools.AveryParkArea]: "Avery Park Area",
  [ElementarySchools.Avinger]: "Avinger",
  [ElementarySchools.Avondale]: "Avondale",
  [ElementarySchools.Axtell]: "Axtell",
  [ElementarySchools.Azle]: "Azle",
  [ElementarySchools.Baccus]: "Baccus",
  [ElementarySchools.Bagdade]: "Bagdade",
  [ElementarySchools.Bahamas]: "Bahamas",
  [ElementarySchools.Baird]: "Baird",
  [ElementarySchools.Ball]: "Ball",
  [ElementarySchools.Ballinger]: "Ballinger",
  [ElementarySchools.Balmorhea]: "Balmorhea",
  [ElementarySchools.Banquete]: "Banquete",
  [ElementarySchools.Barbados]: "Barbados",
  [ElementarySchools.BarbaraWalker]: "Barbara Walker",
  [ElementarySchools.Barkleyrui]: "Barkleyrui",
  [ElementarySchools.Barksdale]: "Barksdale",
  [ElementarySchools.Barnes]: "Barnes",
  [ElementarySchools.Barron]: "Barron",
  [ElementarySchools.Bartlett]: "Bartlett",
  [ElementarySchools.Barton]: "Barton",
  [ElementarySchools.BartonCreek]: "Barton Creek",
  [ElementarySchools.Baskin]: "Baskin",
  [ElementarySchools.Bassetti]: "Bassetti",
  [ElementarySchools.Basswood]: "Basswood",
  [ElementarySchools.Bauer]: "Bauer",
  [ElementarySchools.Baxter]: "Baxter",
  [ElementarySchools.BayColony]: "Bay Colony",
  [ElementarySchools.Bayles]: "Bayles",
  [ElementarySchools.Beaconhill]: "Beaconhill",
  [ElementarySchools.Beall]: "Beall",
  [ElementarySchools.BearCreek]: "Bear Creek",
  [ElementarySchools.Beasley]: "Beasley",
  [ElementarySchools.Bebensee]: "Bebensee",
  [ElementarySchools.Beck]: "Beck",
  [ElementarySchools.Beckham]: "Beckham",
  [ElementarySchools.Bedfordhei]: "Bedfordhei",
  [ElementarySchools.BeeCave]: "Bee Cave",
  [ElementarySchools.Belair]: "Belair",
  [ElementarySchools.Belize]: "Belize",
  [ElementarySchools.Bell]: "Bell",
  [ElementarySchools.Bellaire]: "Bellaire",
  [ElementarySchools.Bellevue]: "Bellevue",
  [ElementarySchools.Bellmanor]: "Bellmanor",
  [ElementarySchools.Bells]: "Bells",
  [ElementarySchools.Belmar]: "Belmar",
  [ElementarySchools.Beltline]: "Beltline",
  [ElementarySchools.BenMilam]: "Ben Milam",
  [ElementarySchools.Benavides]: "Benavides",
  [ElementarySchools.Benavidez]: "Benavidez",
  [ElementarySchools.Benbrook]: "Benbrook",
  [ElementarySchools.Benjamin]: "Benjamin",
  [ElementarySchools.BenjaminDocKerleyElementary]:
    "Benjamin Doc Kerley Elementary",
  [ElementarySchools.Bennett]: "Bennett",
  [ElementarySchools.BensBranch]: "Bens Branch",
  [ElementarySchools.Benson]: "Benson",
  [ElementarySchools.Bentsen]: "Bentsen",
  [ElementarySchools.Berkman]: "Berkman",
  [ElementarySchools.Berry]: "Berry",
  [ElementarySchools.Bertram]: "Bertram",
  [ElementarySchools.Bethany]: "Bethany",
  [ElementarySchools.Bethune]: "Bethune",
  [ElementarySchools.Beverly]: "Beverly",
  [ElementarySchools.Bigcountry]: "Bigcountry",
  [ElementarySchools.Bigsandy]: "Bigsandy",
  [ElementarySchools.Bigsprings]: "Bigsprings",
  [ElementarySchools.Bilchildre]: "Bilchildre",
  [ElementarySchools.Bilhartz]: "Bilhartz",
  [ElementarySchools.BillBrown]: "Bill Brown",
  [ElementarySchools.BillieStevenson]: "Billie Stevenson",
  [ElementarySchools.Bingman]: "Bingman",
  [ElementarySchools.Birdville]: "Birdville",
  [ElementarySchools.Birdwell]: "Birdwell",
  [ElementarySchools.Birmingham]: "Birmingham",
  [ElementarySchools.Bishop]: "Bishop",
  [ElementarySchools.Bivins]: "Bivins",
  [ElementarySchools.Black]: "Black",
  [ElementarySchools.Blackburn]: "Blackburn",
  [ElementarySchools.Blackland]: "Blackland",
  [ElementarySchools.Blackshear]: "Blackshear",
  [ElementarySchools.Blackwell]: "Blackwell",
  [ElementarySchools.Blair]: "Blair",
  [ElementarySchools.Blanco]: "Blanco",
  [ElementarySchools.Bland]: "Bland",
  [ElementarySchools.Blanket]: "Blanket",
  [ElementarySchools.BlaschkeSheldon]: "Blaschke Sheldon",
  [ElementarySchools.Bledsoe]: "Bledsoe",
  [ElementarySchools.Blessing]: "Blessing",
  [ElementarySchools.Bliss]: "Bliss",
  [ElementarySchools.Blockhouse]: "Blockhouse",
  [ElementarySchools.Bloomburg]: "Bloomburg",
  [ElementarySchools.Bloominggr]: "Bloominggr",
  [ElementarySchools.Blossom]: "Blossom",
  [ElementarySchools.Bluebonnet]: "Bluebonnet",
  [ElementarySchools.BluebonnetTrail]: "Bluebonnet Trail",
  [ElementarySchools.Bluehaze]: "Bluehaze",
  [ElementarySchools.Blueridge]: "Blueridge",
  [ElementarySchools.Bluffdale]: "Bluffdale",
  [ElementarySchools.Blum]: "Blum",
  [ElementarySchools.Boals]: "Boals",
  [ElementarySchools.BobandLolaSanford]: "Bob and Lola Sanford",
  [ElementarySchools.BobbyRayAftonMartin]: "Bobby Ray- Afton Martin",
  [ElementarySchools.Bobhope]: "Bobhope",
  [ElementarySchools.Boggess]: "Boggess",
  [ElementarySchools.Boles]: "Boles",
  [ElementarySchools.Bolin]: "Bolin",
  [ElementarySchools.Bond]: "Bond",
  [ElementarySchools.Bonham]: "Bonham",
  [ElementarySchools.Bonner]: "Bonner",
  [ElementarySchools.Bonniebrae]: "Bonniebrae",
  [ElementarySchools.Boon]: "Boon",
  [ElementarySchools.Borchardt]: "Borchardt",
  [ElementarySchools.BordenCountySchool]: "Borden County School",
  [ElementarySchools.Boren]: "Boren",
  [ElementarySchools.Borger]: "Borger",
  [ElementarySchools.Borman]: "Borman",
  [ElementarySchools.Borrego]: "Borrego",
  [ElementarySchools.Bosqueville]: "Bosqueville",
  [ElementarySchools.Bowden]: "Bowden",
  [ElementarySchools.Bowen]: "Bowen",
  [ElementarySchools.Bowie]: "Bowie",
  [ElementarySchools.Boyd]: "Boyd",
  [ElementarySchools.Brackenrid]: "Brackenrid",
  [ElementarySchools.Bradfield]: "Bradfield",
  [ElementarySchools.Bradford]: "Bradford",
  [ElementarySchools.Bradley]: "Bradley",
  [ElementarySchools.Brady]: "Brady",
  [ElementarySchools.Bramlette]: "Bramlette",
  [ElementarySchools.Branch]: "Branch",
  [ElementarySchools.Brandenber]: "Brandenber",
  [ElementarySchools.Brandon]: "Brandon",
  [ElementarySchools.Bransford]: "Bransford",
  [ElementarySchools.Bransom]: "Bransom",
  [ElementarySchools.Braunstati]: "Braunstati",
  [ElementarySchools.Bray]: "Bray",
  [ElementarySchools.Brazil]: "Brazil",
  [ElementarySchools.Bremond]: "Bremond",
  [ElementarySchools.BrendaNorwood]: "Brenda Norwood",
  [ElementarySchools.Brent]: "Brent",
  [ElementarySchools.Brentfield]: "Brentfield",
  [ElementarySchools.Brewer]: "Brewer",
  [ElementarySchools.Brice]: "Brice",
  [ElementarySchools.BridgePoint]: "Bridge Point",
  [ElementarySchools.Bridgeport]: "Bridgeport",
  [ElementarySchools.Bridlewood]: "Bridlewood",
  [ElementarySchools.Bright]: "Bright",
  [ElementarySchools.Brinker]: "Brinker",
  [ElementarySchools.Briscoe]: "Briscoe",
  [ElementarySchools.Britain]: "Britain",
  [ElementarySchools.Broadway]: "Broadway",
  [ElementarySchools.Brock]: "Brock",
  [ElementarySchools.Bronte]: "Bronte",
  [ElementarySchools.Brookeland]: "Brookeland",
  [ElementarySchools.Brookesmith]: "Brookesmith",
  [ElementarySchools.Brookhollow]: "Brookhollow",
  [ElementarySchools.Brown]: "Brown",
  [ElementarySchools.Brownsboro]: "Brownsboro",
  [ElementarySchools.Brownsville]: "Brownsville",
  [ElementarySchools.Bruceshulk]: "Bruceshulk",
  [ElementarySchools.BrucevilleEddy]: "Bruceville-Eddy",
  [ElementarySchools.Bruni]: "Bruni",
  [ElementarySchools.Brushycree]: "Brushycree",
  [ElementarySchools.Bryan]: "Bryan",
  [ElementarySchools.Bryant]: "Bryant",
  [ElementarySchools.Bryson]: "Bryson",
  [ElementarySchools.Buckner]: "Buckner",
  [ElementarySchools.Budd]: "Budd",
  [ElementarySchools.BuenaVistaSchool]: "Buena Vista School",
  [ElementarySchools.Buenosaire]: "Buenosaire",
  [ElementarySchools.Buffalo]: "Buffalo",
  [ElementarySchools.BuffaloGap]: "Buffalo Gap",
  [ElementarySchools.Bukhair]: "Bukhair",
  [ElementarySchools.Bulgaria]: "Bulgaria",
  [ElementarySchools.Bullard]: "Bullard",
  [ElementarySchools.Bulverde]: "Bulverde",
  [ElementarySchools.Buna]: "Buna",
  [ElementarySchools.Burgin]: "Burgin",
  [ElementarySchools.Burkeville]: "Burkeville",
  [ElementarySchools.Burks]: "Burks",
  [ElementarySchools.Burleson]: "Burleson",
  [ElementarySchools.Burnet]: "Burnet",
  [ElementarySchools.Burnett]: "Burnett",
  [ElementarySchools.Burton]: "Burton",
  [ElementarySchools.Burtonhill]: "Burtonhill",
  [ElementarySchools.Bush]: "Bush",
  [ElementarySchools.Bushland]: "Bushland",
  [ElementarySchools.Bushman]: "Bushman",
  [ElementarySchools.Butler]: "Butler",
  [ElementarySchools.Butterfield]: "Butterfield",
  [ElementarySchools.Bynum]: "Bynum",
  [ElementarySchools.CATatumJr]: "C A Tatum Jr",
  [ElementarySchools.CJHarris]: "C.J. Harris",
  [ElementarySchools.CMSoto]: "C.M. Soto",
  [ElementarySchools.Cabell]: "Cabell",
  [ElementarySchools.Cactus]: "Cactus",
  [ElementarySchools.Cactusranc]: "Cactusranc",
  [ElementarySchools.CaddoGrove]: "Caddo Grove",
  [ElementarySchools.Caddomills]: "Caddomills",
  [ElementarySchools.Cadwallade]: "Cadwallade",
  [ElementarySchools.Caillet]: "Caillet",
  [ElementarySchools.Cain]: "Cain",
  [ElementarySchools.Caldwell]: "Caldwell",
  [ElementarySchools.Caldwellhe]: "Caldwellhe",
  [ElementarySchools.California]: "California",
  [ElementarySchools.Calk]: "Calk",
  [ElementarySchools.Callisburg]: "Callisburg",
  [ElementarySchools.Cambridge]: "Cambridge",
  [ElementarySchools.Camelot]: "Camelot",
  [ElementarySchools.Cameron]: "Cameron",
  [ElementarySchools.Camey]: "Camey",
  [ElementarySchools.Camian]: "Camian",
  [ElementarySchools.Campbell]: "Campbell",
  [ElementarySchools.Campestre]: "Campestre",
  [ElementarySchools.Canada]: "Canada",
  [ElementarySchools.Candlewood]: "Candlewood",
  [ElementarySchools.Cannaday]: "Cannaday",
  [ElementarySchools.Cannon]: "Cannon",
  [ElementarySchools.Canton]: "Canton",
  [ElementarySchools.Canutillo]: "Canutillo",
  [ElementarySchools.Canyoncree]: "Canyoncree",
  [ElementarySchools.Capistrano]: "Capistrano",
  [ElementarySchools.Caprock]: "Caprock",
  [ElementarySchools.Carden]: "Carden",
  [ElementarySchools.Carillo]: "Carillo",
  [ElementarySchools.CarlESchluter]: "Carl E. Schluter",
  [ElementarySchools.Carleston]: "Carleston",
  [ElementarySchools.Carlisle]: "Carlisle",
  [ElementarySchools.CarolHolt]: "Carol Holt",
  [ElementarySchools.Carpenter]: "Carpenter",
  [ElementarySchools.Carr]: "Carr",
  [ElementarySchools.Carroll]: "Carroll",
  [ElementarySchools.Carrolllan]: "Carrolllan",
  [ElementarySchools.Carrollpea]: "Carrollpea",
  [ElementarySchools.Carrollton]: "Carrollton",
  [ElementarySchools.Carson]: "Carson",
  [ElementarySchools.Carterpark]: "Carterpark",
  [ElementarySchools.Carvajal]: "Carvajal",
  [ElementarySchools.Carver]: "Carver",
  [ElementarySchools.Casalinda]: "Casalinda",
  [ElementarySchools.Casaview]: "Casaview",
  [ElementarySchools.CastleHills]: "Castle Hills",
  [ElementarySchools.Castleberr]: "Castleberr",
  [ElementarySchools.Cater]: "Cater",
  [ElementarySchools.Cato]: "Cato",
  [ElementarySchools.CaymanIslands]: "Cayman Islands",
  [ElementarySchools.Cayuga]: "Cayuga",
  [ElementarySchools.Cedarcreek]: "Cedarcreek",
  [ElementarySchools.Cedarvalle]: "Cedarvalle",
  [ElementarySchools.Cedergrove]: "Cedergrove",
  [ElementarySchools.Celeste]: "Celeste",
  [ElementarySchools.CeliaHays]: "Celia Hays",
  [ElementarySchools.Cenizopark]: "Cenizopark",
  [ElementarySchools.Centennial]: "Centennial",
  [ElementarySchools.Center]: "Center",
  [ElementarySchools.Centerville]: "Centerville",
  [ElementarySchools.Central]: "Central",
  [ElementarySchools.CentralHeights]: "Central Heights",
  [ElementarySchools.Centralhei]: "Centralhei",
  [ElementarySchools.Centralpar]: "Centralpar",
  [ElementarySchools.CesarChavez]: "Cesar Chavez",
  [ElementarySchools.Challenger]: "Challenger",
  [ElementarySchools.Chalmers]: "Chalmers",
  [ElementarySchools.Chamberlai]: "Chamberlai",
  [ElementarySchools.Chandler]: "Chandler",
  [ElementarySchools.ChapelHill]: "Chapel Hill",
  [ElementarySchools.Chapelhill]: "Chapelhill",
  [ElementarySchools.CharlesandCindyStuber]: "Charles and Cindy Stuber",
  [ElementarySchools.Charlesnas]: "Charlesnas",
  [ElementarySchools.Chavez]: "Chavez",
  [ElementarySchools.Cheatham]: "Cheatham",
  [ElementarySchools.Cherry]: "Cherry",
  [ElementarySchools.Chico]: "Chico",
  [ElementarySchools.Childress]: "Childress",
  [ElementarySchools.Chile]: "Chile",
  [ElementarySchools.Chillicoth]: "Chillicoth",
  [ElementarySchools.China]: "China",
  [ElementarySchools.Chinasprng]: "Chinasprng",
  [ElementarySchools.Chireno]: "Chireno",
  [ElementarySchools.ChisholmRidge]: "Chisholm Ridge",
  [ElementarySchools.ChisolmTrail]: "Chisolm Trail",
  [ElementarySchools.Chisolmtra]: "Chisolmtra",
  [ElementarySchools.Chisum]: "Chisum",
  [ElementarySchools.ChoiceOfSchool]: "Choice Of School",
  [ElementarySchools.Christie]: "Christie",
  [ElementarySchools.Ciavarra]: "Ciavarra",
  [ElementarySchools.Cielovista]: "Cielovista",
  [ElementarySchools.Cimarron]: "Cimarron",
  [ElementarySchools.Cisco]: "Cisco",
  [ElementarySchools.CityView]: "City View",
  [ElementarySchools.Citypark]: "Citypark",
  [ElementarySchools.ClaraLove]: "Clara Love",
  [ElementarySchools.Clardy]: "Clardy",
  [ElementarySchools.Clarendon]: "Clarendon",
  [ElementarySchools.Clark]: "Clark",
  [ElementarySchools.Clarkston]: "Clarkston",
  [ElementarySchools.Clarksvill]: "Clarksvill",
  [ElementarySchools.Claude]: "Claude",
  [ElementarySchools.Claybon]: "Claybon",
  [ElementarySchools.ClaytonLi]: "Clayton Li",
  [ElementarySchools.Clearcreek]: "Clearcreek",
  [ElementarySchools.Clearfork]: "Clearfork",
  [ElementarySchools.Clearsprin]: "Clearsprin",
  [ElementarySchools.Clendenin]: "Clendenin",
  [ElementarySchools.Clifton]: "Clifton",
  [ElementarySchools.Cliftonpar]: "Cliftonpar",
  [ElementarySchools.Cloverleaf]: "Cloverleaf",
  [ElementarySchools.Clyde]: "Clyde",
  [ElementarySchools.Cochran]: "Cochran",
  [ElementarySchools.CockrellHill]: "Cockrell Hill",
  [ElementarySchools.Coder]: "Coder",
  [ElementarySchools.Coggin]: "Coggin",
  [ElementarySchools.Coker]: "Coker",
  [ElementarySchools.Coldwell]: "Coldwell",
  [ElementarySchools.Coleman]: "Coleman",
  [ElementarySchools.Coles]: "Coles",
  [ElementarySchools.CollegeHill]: "College Hill",
  [ElementarySchools.CollegeSt]: "College St",
  [ElementarySchools.Collegehil]: "Collegehil",
  [ElementarySchools.Colleyvill]: "Colleyvill",
  [ElementarySchools.Collier]: "Collier",
  [ElementarySchools.Collins]: "Collins",
  [ElementarySchools.Collinsgar]: "Collinsgar",
  [ElementarySchools.Collinsville]: "Collinsville",
  [ElementarySchools.Colonial]: "Colonial",
  [ElementarySchools.Colonialhi]: "Colonialhi",
  [ElementarySchools.Colonialht]: "Colonialht",
  [ElementarySchools.Colorado]: "Colorado",
  [ElementarySchools.Colt]: "Colt",
  [ElementarySchools.Columbiahe]: "Columbiahe",
  [ElementarySchools.Columbus]: "Columbus",
  [ElementarySchools.Comal]: "Comal",
  [ElementarySchools.Comanche]: "Comanche",
  [ElementarySchools.ComancheSprings]: "Comanche Springs",
  [ElementarySchools.Comfort]: "Comfort",
  [ElementarySchools.Commerce]: "Commerce",
  [ElementarySchools.Como]: "Como",
  [ElementarySchools.Comopickto]: "Comopickto",
  [ElementarySchools.Comstock]: "Comstock",
  [ElementarySchools.Connally]: "Connally",
  [ElementarySchools.Connecticut]: "Connecticut",
  [ElementarySchools.Conner]: "Conner",
  [ElementarySchools.Conteras]: "Conteras",
  [ElementarySchools.Converse]: "Converse",
  [ElementarySchools.Cooke]: "Cooke",
  [ElementarySchools.Cooley]: "Cooley",
  [ElementarySchools.Coolidge]: "Coolidge",
  [ElementarySchools.Cooper]: "Cooper",
  [ElementarySchools.CoraSpencer]: "Cora Spencer",
  [ElementarySchools.Corbell]: "Corbell",
  [ElementarySchools.Corey]: "Corey",
  [ElementarySchools.Corinth]: "Corinth",
  [ElementarySchools.Coronado]: "Coronado",
  [ElementarySchools.Coronadoes]: "Coronadoes",
  [ElementarySchools.Coronadovi]: "Coronadovi",
  [ElementarySchools.CorriganCamden]: "Corrigan-Camden",
  [ElementarySchools.CostaRica]: "Costa Rica",
  [ElementarySchools.Coston]: "Coston",
  [ElementarySchools.Cotton]: "Cotton",
  [ElementarySchools.Cottonwood]: "Cottonwood",
  [ElementarySchools.Countrypla]: "Countrypla",
  [ElementarySchools.Coupland]: "Coupland",
  [ElementarySchools.Covington]: "Covington",
  [ElementarySchools.Cowart]: "Cowart",
  [ElementarySchools.Cowen]: "Cowen",
  [ElementarySchools.Cox]: "Cox",
  [ElementarySchools.CoyoteRidge]: "Coyote Ridge",
  [ElementarySchools.Crandall]: "Crandall",
  [ElementarySchools.Cranfillsg]: "Cranfillsg",
  [ElementarySchools.Crawford]: "Crawford",
  [ElementarySchools.CreekView]: "Creek View",
  [ElementarySchools.Creekside]: "Creekside",
  [ElementarySchools.Crenshaw]: "Crenshaw",
  [ElementarySchools.Crestview]: "Crestview",
  [ElementarySchools.Criswell]: "Criswell",
  [ElementarySchools.Crockett]: "Crockett",
  [ElementarySchools.Crosby]: "Crosby",
  [ElementarySchools.CrossOaks]: "Cross Oaks",
  [ElementarySchools.CrossPlains]: "Cross Plains",
  [ElementarySchools.Crossroads]: "Crossroads",
  [ElementarySchools.Crosstimbe]: "Crosstimbe",
  [ElementarySchools.Crouch]: "Crouch",
  [ElementarySchools.Crow]: "Crow",
  [ElementarySchools.Crowell]: "Crowell",
  [ElementarySchools.Crowley]: "Crowley",
  [ElementarySchools.Crutchfield]: "Crutchfield",
  [ElementarySchools.Cuellar]: "Cuellar",
  [ElementarySchools.Cumby]: "Cumby",
  [ElementarySchools.Cunningham]: "Cunningham",
  [ElementarySchools.Curtis]: "Curtis",
  [ElementarySchools.Curtsinger]: "Curtsinger",
  [ElementarySchools.Cushing]: "Cushing",
  [ElementarySchools.CynthiaACockrell]: "Cynthia A Cockrell",
  [ElementarySchools.Cypress]: "Cypress",
  [ElementarySchools.Daffron]: "Daffron",
  [ElementarySchools.Daggett]: "Daggett",
  [ElementarySchools.Daiches]: "Daiches",
  [ElementarySchools.DallasPark]: "Dallas Park",
  [ElementarySchools.Dalton]: "Dalton",
  [ElementarySchools.Daniels]: "Daniels",
  [ElementarySchools.Darr]: "Darr",
  [ElementarySchools.Darrell]: "Darrell",
  [ElementarySchools.Dartmouth]: "Dartmouth",
  [ElementarySchools.Davenport]: "Davenport",
  [ElementarySchools.DavidMcCall]: "David McCall",
  [ElementarySchools.Davis]: "Davis",
  [ElementarySchools.DavisMalvern]: "Davis Malvern",
  [ElementarySchools.Dawson]: "Dawson",
  [ElementarySchools.DeZavala]: "De Zavala",
  [ElementarySchools.Dealey]: "Dealey",
  [ElementarySchools.Decalb]: "Decalb",
  [ElementarySchools.Decker]: "Decker",
  [ElementarySchools.Deepwood]: "Deepwood",
  [ElementarySchools.Deercreek]: "Deercreek",
  [ElementarySchools.Degan]: "Degan",
  [ElementarySchools.Degolyer]: "Degolyer",
  [ElementarySchools.DelValle]: "Del Valle",
  [ElementarySchools.Delaney]: "Delaney",
  [ElementarySchools.Deleon]: "Deleon",
  [ElementarySchools.Dellview]: "Dellview",
  [ElementarySchools.Delnortehe]: "Delnortehe",
  [ElementarySchools.Dentoncree]: "Dentoncree",
  [ElementarySchools.DenverAve]: "Denver Ave",
  [ElementarySchools.Deport]: "Deport",
  [ElementarySchools.Dequeen]: "Dequeen",
  [ElementarySchools.Desertaire]: "Desertaire",
  [ElementarySchools.Deserthill]: "Deserthill",
  [ElementarySchools.Detroit]: "Detroit",
  [ElementarySchools.Devers]: "Devers",
  [ElementarySchools.Devonian]: "Devonian",
  [ElementarySchools.Dew]: "Dew",
  [ElementarySchools.Dezavala]: "Dezavala",
  [ElementarySchools.DiamondHi]: "Diamond Hi",
  [ElementarySchools.DiazVillarreal]: "Diaz-Villarreal",
  [ElementarySchools.Dickinson]: "Dickinson",
  [ElementarySchools.Dillingham]: "Dillingham",
  [ElementarySchools.Dinsmore]: "Dinsmore",
  [ElementarySchools.DirksAnderson]: "Dirks-Anderson",
  [ElementarySchools.Ditto]: "Ditto",
  [ElementarySchools.Dixie]: "Dixie",
  [ElementarySchools.Dobbs]: "Dobbs",
  [ElementarySchools.Dodd]: "Dodd",
  [ElementarySchools.Doddcity]: "Doddcity",
  [ElementarySchools.DoloresMcClatchey]: "Dolores McClatchey",
  [ElementarySchools.Dolphinter]: "Dolphinter",
  [ElementarySchools.DominicanRepublic]: "Dominican Republic",
  [ElementarySchools.DonJeter]: "Don Jeter",
  [ElementarySchools.DonWhitt]: "Don Whitt",
  [ElementarySchools.Donald]: "Donald",
  [ElementarySchools.DonnaPark]: "Donna Park",
  [ElementarySchools.Dooley]: "Dooley",
  [ElementarySchools.Doriemille]: "Doriemille",
  [ElementarySchools.DorisCullinsLakePointe]: "Doris Cullins-Lake Pointe",
  [ElementarySchools.DorothyPAdkins]: "Dorothy P Adkins",
  [ElementarySchools.DorrisJones]: "Dorris Jones",
  [ElementarySchools.Dorsey]: "Dorsey",
  [ElementarySchools.Doss]: "Doss",
  [ElementarySchools.Doublefile]: "Doublefile",
  [ElementarySchools.Douglas]: "Douglas",
  [ElementarySchools.Douglass]: "Douglass",
  [ElementarySchools.Dovalina]: "Dovalina",
  [ElementarySchools.Dove]: "Dove",
  [ElementarySchools.Dover]: "Dover",
  [ElementarySchools.Dowell]: "Dowell",
  [ElementarySchools.Dozier]: "Dozier",
  [ElementarySchools.DrMarioERamirez]: "Dr Mario E Ramirez",
  [ElementarySchools.DrBryanJack]: "Dr. Bryan Jack",
  [ElementarySchools.Driscoll]: "Driscoll",
  [ElementarySchools.Dublin]: "Dublin",
  [ElementarySchools.Duff]: "Duff",
  [ElementarySchools.Dunaway]: "Dunaway",
  [ElementarySchools.Dunbar]: "Dunbar",
  [ElementarySchools.Duncan]: "Duncan",
  [ElementarySchools.Dunn]: "Dunn",
  [ElementarySchools.Dupre]: "Dupre",
  [ElementarySchools.Durham]: "Durham",
  [ElementarySchools.Dyer]: "Dyer",
  [ElementarySchools.Dyess]: "Dyess",
  [ElementarySchools.ECMason]: "E C Mason",
  [ElementarySchools.Eagle]: "Eagle",
  [ElementarySchools.EagleRidge]: "Eagle Ridge",
  [ElementarySchools.Eagleheigh]: "Eagleheigh",
  [ElementarySchools.Eaglemount]: "Eaglemount",
  [ElementarySchools.Earhart]: "Earhart",
  [ElementarySchools.Early]: "Early",
  [ElementarySchools.East]: "East",
  [ElementarySchools.Easternhil]: "Easternhil",
  [ElementarySchools.Easthandle]: "Easthandle",
  [ElementarySchools.Eastland]: "Eastland",
  [ElementarySchools.Eastpoint]: "Eastpoint",
  [ElementarySchools.Eastridge]: "Eastridge",
  [ElementarySchools.Eastside]: "Eastside",
  [ElementarySchools.Eastterrel]: "Eastterrel",
  [ElementarySchools.Eastward]: "Eastward",
  [ElementarySchools.Eastwoodhe]: "Eastwoodhe",
  [ElementarySchools.Eastwoodkn]: "Eastwoodkn",
  [ElementarySchools.EbbyHalliday]: "Ebby Halliday",
  [ElementarySchools.Ector]: "Ector",
  [ElementarySchools.Eddins]: "Eddins",
  [ElementarySchools.Eden]: "Eden",
  [ElementarySchools.Edgemere]: "Edgemere",
  [ElementarySchools.Edgewood]: "Edgewood",
  [ElementarySchools.Edison]: "Edison",
  [ElementarySchools.Edna]: "Edna",
  [ElementarySchools.Edwardbris]: "Edwardbris",
  [ElementarySchools.Effiemorri]: "Effiemorri",
  [ElementarySchools.Eisenhower]: "Eisenhower",
  [ElementarySchools.ElSalvador]: "El Salvador",
  [ElementarySchools.Elder]: "Elder",
  [ElementarySchools.Eldorado]: "Eldorado",
  [ElementarySchools.Elkhart]: "Elkhart",
  [ElementarySchools.Elkins]: "Elkins",
  [ElementarySchools.Elliott]: "Elliott",
  [ElementarySchools.Ellis]: "Ellis",
  [ElementarySchools.Elmcreek]: "Elmcreek",
  [ElementarySchools.Elolf]: "Elolf",
  [ElementarySchools.Elysianfie]: "Elysianfie",
  [ElementarySchools.Emerson]: "Emerson",
  [ElementarySchools.Emile]: "Emile",
  [ElementarySchools.Encinopark]: "Encinopark",
  [ElementarySchools.Era]: "Era",
  [ElementarySchools.Ervin]: "Ervin",
  [ElementarySchools.Escontrias]: "Escontrias",
  [ElementarySchools.EsperanzaMedrano]: "Esperanza Medrano",
  [ElementarySchools.Ethridge]: "Ethridge",
  [ElementarySchools.Etoile]: "Etoile",
  [ElementarySchools.Eugeniarayzor]: "Eugeniarayzor",
  [ElementarySchools.Eula]: "Eula",
  [ElementarySchools.Evadale]: "Evadale",
  [ElementarySchools.Evans]: "Evans",
  [ElementarySchools.Evant]: "Evant",
  [ElementarySchools.Everett]: "Everett",
  [ElementarySchools.EversPark]: "Evers Park",
  [ElementarySchools.FaddenMckeownChambless]: "Fadden- Mckeown- Chambless",
  [ElementarySchools.Fairfield]: "Fairfield",
  [ElementarySchools.Fairmeadow]: "Fairmeadow",
  [ElementarySchools.Fairview]: "Fairview",
  [ElementarySchools.Fallscity]: "Fallscity",
  [ElementarySchools.Fannin]: "Fannin",
  [ElementarySchools.Fannindell]: "Fannindell",
  [ElementarySchools.Farias]: "Farias",
  [ElementarySchools.Farine]: "Farine",
  [ElementarySchools.Farmersbra]: "Farmersbra",
  [ElementarySchools.Farrell]: "Farrell",
  [ElementarySchools.Farwell]: "Farwell",
  [ElementarySchools.Faubion]: "Faubion",
  [ElementarySchools.FayeWebb]: "Faye Webb",
  [ElementarySchools.FelixGBotello]: "Felix G. Botello",
  [ElementarySchools.Fenwick]: "Fenwick",
  [ElementarySchools.Fernbluff]: "Fernbluff",
  [ElementarySchools.Field]: "Field",
  [ElementarySchools.FieldsStore]: "Fields Store",
  [ElementarySchools.Finch]: "Finch",
  [ElementarySchools.Finleyoate]: "Finleyoate",
  [ElementarySchools.Fisher]: "Fisher",
  [ElementarySchools.Fitzgerald]: "Fitzgerald",
  [ElementarySchools.Fivepalms]: "Fivepalms",
  [ElementarySchools.Florence]: "Florence",
  [ElementarySchools.Flores]: "Flores",
  [ElementarySchools.Florida]: "Florida",
  [ElementarySchools.Flourbluff]: "Flourbluff",
  [ElementarySchools.FlowerMound]: "Flower Mound",
  [ElementarySchools.Floyd]: "Floyd",
  [ElementarySchools.Fm360Area]: "Fm 360 Area",
  [ElementarySchools.Forbes]: "Forbes",
  [ElementarySchools.ForestHill]: "Forest Hill",
  [ElementarySchools.ForestRidge]: "Forest Ridge",
  [ElementarySchools.ForestTrail]: "Forest Trail",
  [ElementarySchools.ForestVista]: "Forest Vista",
  [ElementarySchools.Forestburg]: "Forestburg",
  [ElementarySchools.Forestcree]: "Forestcree",
  [ElementarySchools.Foresthill]: "Foresthill",
  [ElementarySchools.Forestnort]: "Forestnort",
  [ElementarySchools.Forestridg]: "Forestridg",
  [ElementarySchools.Forman]: "Forman",
  [ElementarySchools.Forsan]: "Forsan",
  [ElementarySchools.Fort]: "Fort",
  [ElementarySchools.Foster]: "Foster",
  [ElementarySchools.Fostervill]: "Fostervill",
  [ElementarySchools.Fowler]: "Fowler",
  [ElementarySchools.Foxrun]: "Foxrun",
  [ElementarySchools.France]: "France",
  [ElementarySchools.FrancesAndJeannetteLee]: "Frances And Jeannette Lee",
  [ElementarySchools.Francisco]: "Francisco",
  [ElementarySchools.Frank]: "Frank",
  [ElementarySchools.FrankGuzick]: "Frank Guzick",
  [ElementarySchools.Franklin]: "Franklin",
  [ElementarySchools.Frankston]: "Frankston",
  [ElementarySchools.Franz]: "Franz",
  [ElementarySchools.Fred]: "Fred",
  [ElementarySchools.Fredericks]: "Fredericks",
  [ElementarySchools.Fredonia]: "Fredonia",
  [ElementarySchools.Freedom]: "Freedom",
  [ElementarySchools.Freeman]: "Freeman",
  [ElementarySchools.Freiheit]: "Freiheit",
  [ElementarySchools.French]: "French",
  [ElementarySchools.Frey]: "Frey",
  [ElementarySchools.Friendship]: "Friendship",
  [ElementarySchools.Friona]: "Friona",
  [ElementarySchools.Froestlane]: "Froestlane",
  [ElementarySchools.Frost]: "Frost",
  [ElementarySchools.Ftsamhoust]: "Ftsamhoust",
  [ElementarySchools.Furneaux]: "Furneaux",
  [ElementarySchools.GalenaPark]: "Galena Park",
  [ElementarySchools.Gallego]: "Gallego",
  [ElementarySchools.Galloway]: "Galloway",
  [ElementarySchools.Galvan]: "Galvan",
  [ElementarySchools.Ganado]: "Ganado",
  [ElementarySchools.Garcia]: "Garcia",
  [ElementarySchools.GardenOaks]: "Garden Oaks",
  [ElementarySchools.GardenRidge]: "Garden Ridge",
  [ElementarySchools.GardenRidgeArea]: "Garden Ridge Area",
  [ElementarySchools.Gardendale]: "Gardendale",
  [ElementarySchools.Garner]: "Garner",
  [ElementarySchools.Garrison]: "Garrison",
  [ElementarySchools.Gary]: "Gary",
  [ElementarySchools.Gates]: "Gates",
  [ElementarySchools.Gatesville]: "Gatesville",
  [ElementarySchools.Gateway]: "Gateway",
  [ElementarySchools.Gattis]: "Gattis",
  [ElementarySchools.Gause]: "Gause",
  [ElementarySchools.GeneHowe]: "Gene Howe",
  [ElementarySchools.GeneralRicardoSanchez]: "General Ricardo Sanchez",
  [ElementarySchools.GenevaHeights]: "Geneva Heights",
  [ElementarySchools.Gentry]: "Gentry",
  [ElementarySchools.GeorgeWBush]: "George W Bush",
  [ElementarySchools.Georgeclar]: "Georgeclar",
  [ElementarySchools.Georgewest]: "Georgewest",
  [ElementarySchools.Georgia]: "Georgia",
  [ElementarySchools.Gerard]: "Gerard",
  [ElementarySchools.Germany]: "Germany",
  [ElementarySchools.Gholson]: "Gholson",
  [ElementarySchools.Gibbs]: "Gibbs",
  [ElementarySchools.Gibson]: "Gibson",
  [ElementarySchools.Giddens]: "Giddens",
  [ElementarySchools.Gideon]: "Gideon",
  [ElementarySchools.Gilbert]: "Gilbert",
  [ElementarySchools.GilbertCuellar]: "Gilbert Cuellar",
  [ElementarySchools.GilbertJMircovich]: "Gilbert J Mircovich",
  [ElementarySchools.Gililland]: "Gililland",
  [ElementarySchools.Gill]: "Gill",
  [ElementarySchools.Gillette]: "Gillette",
  [ElementarySchools.Gilmer]: "Gilmer",
  [ElementarySchools.Ginnings]: "Ginnings",
  [ElementarySchools.Givens]: "Givens",
  [ElementarySchools.Glass]: "Glass",
  [ElementarySchools.GlenRose]: "Glen Rose",
  [ElementarySchools.Glencove]: "Glencove",
  [ElementarySchools.Glenhope]: "Glenhope",
  [ElementarySchools.Glenn]: "Glenn",
  [ElementarySchools.Glenoaks]: "Glenoaks",
  [ElementarySchools.Glenpark]: "Glenpark",
  [ElementarySchools.Glenview]: "Glenview",
  [ElementarySchools.Glenwood]: "Glenwood",
  [ElementarySchools.Godley]: "Godley",
  [ElementarySchools.Godwin]: "Godwin",
  [ElementarySchools.Goldthwaite]: "Goldthwaite",
  [ElementarySchools.Goliad]: "Goliad",
  [ElementarySchools.Gonzalez]: "Gonzalez",
  [ElementarySchools.GonzaloAndSofiaGarcia]: "Gonzalo And Sofia Garcia",
  [ElementarySchools.Gooch]: "Gooch",
  [ElementarySchools.Good]: "Good",
  [ElementarySchools.Goodman]: "Goodman",
  [ElementarySchools.Gordon]: "Gordon",
  [ElementarySchools.GoshenCreek]: "Goshen Creek",
  [ElementarySchools.Graebner]: "Graebner",
  [ElementarySchools.Graford]: "Graford",
  [ElementarySchools.Grandsalin]: "Grandsalin",
  [ElementarySchools.Grandview]: "Grandview",
  [ElementarySchools.Grapeland]: "Grapeland",
  [ElementarySchools.Grapevine]: "Grapevine",
  [ElementarySchools.Graves]: "Graves",
  [ElementarySchools.Gray]: "Gray",
  [ElementarySchools.Greathouse]: "Greathouse",
  [ElementarySchools.Greatoaks]: "Greatoaks",
  [ElementarySchools.Green]: "Green",
  [ElementarySchools.GreenWm]: "Green  Wm",
  [ElementarySchools.GreenAcres]: "Green Acres",
  [ElementarySchools.GreenValley]: "Green Valley",
  [ElementarySchools.Greenbriar]: "Greenbriar",
  [ElementarySchools.Greenfield]: "Greenfield",
  [ElementarySchools.Greenvalle]: "Greenvalle",
  [ElementarySchools.Greenwood]: "Greenwood",
  [ElementarySchools.Griffin]: "Griffin",
  [ElementarySchools.Groesbeck]: "Groesbeck",
  [ElementarySchools.Groves]: "Groves",
  [ElementarySchools.Groveton]: "Groveton",
  [ElementarySchools.Grulla]: "Grulla",
  [ElementarySchools.Guatemala]: "Guatemala",
  [ElementarySchools.Guerrero]: "Guerrero",
  [ElementarySchools.Gulledge]: "Gulledge",
  [ElementarySchools.Gunstream]: "Gunstream",
  [ElementarySchools.Gunter]: "Gunter",
  [ElementarySchools.Gustine]: "Gustine",
  [ElementarySchools.Hachar]: "Hachar",
  [ElementarySchools.Haciendahe]: "Haciendahe",
  [ElementarySchools.Hackberry]: "Hackberry",
  [ElementarySchools.Haggar]: "Haggar",
  [ElementarySchools.Hale]: "Hale",
  [ElementarySchools.Haleyj]: "Haleyj",
  [ElementarySchools.Haleyt]: "Haleyt",
  [ElementarySchools.Hall]: "Hall",
  [ElementarySchools.Hallsville]: "Hallsville",
  [ElementarySchools.HamiltonPark]: "Hamilton Park",
  [ElementarySchools.Hamlet]: "Hamlet",
  [ElementarySchools.Hamlin]: "Hamlin",
  [ElementarySchools.Hanby]: "Hanby",
  [ElementarySchools.Hanes]: "Hanes",
  [ElementarySchools.Happy]: "Happy",
  [ElementarySchools.Harben]: "Harben",
  [ElementarySchools.HardOak]: "Hard Oak",
  [ElementarySchools.Hardeman]: "Hardeman",
  [ElementarySchools.Hardin]: "Hardin",
  [ElementarySchools.Hargrave]: "Hargrave",
  [ElementarySchools.Harkerheig]: "Harkerheig",
  [ElementarySchools.Harleanbea]: "Harleanbea",
  [ElementarySchools.Harleton]: "Harleton",
  [ElementarySchools.Harllee]: "Harllee",
  [ElementarySchools.Harmon]: "Harmon",
  [ElementarySchools.Harmony]: "Harmony",
  [ElementarySchools.Harmonyhil]: "Harmonyhil",
  [ElementarySchools.Harper]: "Harper",
  [ElementarySchools.Harrington]: "Harrington",
  [ElementarySchools.Harris]: "Harris",
  [ElementarySchools.HarrisAcademny]: "Harris Academny",
  [ElementarySchools.HarrisAcademy]: "Harris Academy",
  [ElementarySchools.Harrison]: "Harrison",
  [ElementarySchools.HarrisonJeffersonMadisonComplex]:
    "Harrison/Jefferson/Madison Complex",
  [ElementarySchools.HarryMckillop]: "Harry Mckillop",
  [ElementarySchools.Hart]: "Hart",
  [ElementarySchools.Hartman]: "Hartman",
  [ElementarySchools.Hartsbluff]: "Hartsbluff",
  [ElementarySchools.Haskell]: "Haskell",
  [ElementarySchools.Haslet]: "Haslet",
  [ElementarySchools.Hastings]: "Hastings",
  [ElementarySchools.Hatfield]: "Hatfield",
  [ElementarySchools.Haun]: "Haun",
  [ElementarySchools.Havard]: "Havard",
  [ElementarySchools.Hawkins]: "Hawkins",
  [ElementarySchools.Hawley]: "Hawley",
  [ElementarySchools.Hawthorne]: "Hawthorne",
  [ElementarySchools.Haybranch]: "Haybranch",
  [ElementarySchools.Haynes]: "Haynes",
  [ElementarySchools.HazelHarveyPeace]: "Hazel Harvey Peace",
  [ElementarySchools.HebronValley]: "Hebron Valley",
  [ElementarySchools.Hedgcoxe]: "Hedgcoxe",
  [ElementarySchools.Hedrick]: "Hedrick",
  [ElementarySchools.Heights]: "Heights",
  [ElementarySchools.Helbing]: "Helbing",
  [ElementarySchools.Helms]: "Helms",
  [ElementarySchools.Helotes]: "Helotes",
  [ElementarySchools.Hemphill]: "Hemphill",
  [ElementarySchools.Henderson]: "Henderson",
  [ElementarySchools.Henrietta]: "Henrietta",
  [ElementarySchools.HenryWSory]: "Henry W Sory",
  [ElementarySchools.Herff]: "Herff",
  [ElementarySchools.Heritage]: "Heritage",
  [ElementarySchools.HeritageHills]: "Heritage Hills",
  [ElementarySchools.Hermliegh]: "Hermliegh",
  [ElementarySchools.Hernandez]: "Hernandez",
  [ElementarySchools.HerschelZellars]: "Herschel Zellars",
  [ElementarySchools.Hettiehals]: "Hettiehals",
  [ElementarySchools.Hewitt]: "Hewitt",
  [ElementarySchools.Hexter]: "Hexter",
  [ElementarySchools.Hickey]: "Hickey",
  [ElementarySchools.Hicks]: "Hicks",
  [ElementarySchools.Hico]: "Hico",
  [ElementarySchools.Hiddencov]: "Hiddencov",
  [ElementarySchools.Hiddenfore]: "Hiddenfore",
  [ElementarySchools.Hiddenlake]: "Hiddenlake",
  [ElementarySchools.Higgins]: "Higgins",
  [ElementarySchools.Highctry]: "Highctry",
  [ElementarySchools.Highland]: "Highland",
  [ElementarySchools.HighlandPark]: "Highland Park",
  [ElementarySchools.HighlandVillage]: "Highland Village",
  [ElementarySchools.Highlandfo]: "Highlandfo",
  [ElementarySchools.Highlandhi]: "Highlandhi",
  [ElementarySchools.Highlandla]: "Highlandla",
  [ElementarySchools.Highlandpa]: "Highlandpa",
  [ElementarySchools.Highlandpk]: "Highlandpk",
  [ElementarySchools.Highlands]: "Highlands",
  [ElementarySchools.Highlndmea]: "Highlndmea",
  [ElementarySchools.Highpointe]: "Highpointe",
  [ElementarySchools.Hightower]: "Hightower",
  [ElementarySchools.Hileman]: "Hileman",
  [ElementarySchools.Hill]: "Hill",
  [ElementarySchools.Hillcrest]: "Hillcrest",
  [ElementarySchools.Hilley]: "Hilley",
  [ElementarySchools.Hillsboro]: "Hillsboro",
  [ElementarySchools.Hillside]: "Hillside",
  [ElementarySchools.Hilltop]: "Hilltop",
  [ElementarySchools.Hirsch]: "Hirsch",
  [ElementarySchools.HlBrockett]: "Hl Brockett",
  [ElementarySchools.HobbsWilliams]: "Hobbs Williams",
  [ElementarySchools.Hodge]: "Hodge",
  [ElementarySchools.Hodges]: "Hodges",
  [ElementarySchools.Hoelscher]: "Hoelscher",
  [ElementarySchools.Hoffmann]: "Hoffmann",
  [ElementarySchools.Hogg]: "Hogg",
  [ElementarySchools.Holiday]: "Holiday",
  [ElementarySchools.Hollandl]: "Hollandl",
  [ElementarySchools.Holliday]: "Holliday",
  [ElementarySchools.Holliepars]: "Holliepars",
  [ElementarySchools.HollisDeitz]: "Hollis Deitz",
  [ElementarySchools.Holmes]: "Holmes",
  [ElementarySchools.Homestead]: "Homestead",
  [ElementarySchools.Hommel]: "Hommel",
  [ElementarySchools.Honduras]: "Honduras",
  [ElementarySchools.Honeygrove]: "Honeygrove",
  [ElementarySchools.HoodCase]: "Hood Case",
  [ElementarySchools.Hooe]: "Hooe",
  [ElementarySchools.Hooks]: "Hooks",
  [ElementarySchools.Hoover]: "Hoover",
  [ElementarySchools.Hopkins]: "Hopkins",
  [ElementarySchools.Horizonhei]: "Horizonhei",
  [ElementarySchools.Hosp]: "Hosp",
  [ElementarySchools.Hotchkiss]: "Hotchkiss",
  [ElementarySchools.Houston]: "Houston",
  [ElementarySchools.Howard]: "Howard",
  [ElementarySchools.Howe]: "Howe",
  [ElementarySchools.Hubbard]: "Hubbard",
  [ElementarySchools.Huckabay]: "Huckabay",
  [ElementarySchools.Hudson]: "Hudson",
  [ElementarySchools.Huebnerrd]: "Huebnerrd",
  [ElementarySchools.Hueco]: "Hueco",
  [ElementarySchools.Huffman]: "Huffman",
  [ElementarySchools.Hughes]: "Hughes",
  [ElementarySchools.HughesSprings]: "Hughes Springs",
  [ElementarySchools.Hughey]: "Hughey",
  [ElementarySchools.Hughston]: "Hughston",
  [ElementarySchools.Hulbert]: "Hulbert",
  [ElementarySchools.Huley]: "Huley",
  [ElementarySchools.HumphreysHilnd]: "Humphrey's Hilnd",
  [ElementarySchools.Hunt]: "Hunt",
  [ElementarySchools.Huntsville]: "Huntsville",
  [ElementarySchools.Huppertz]: "Huppertz",
  [ElementarySchools.Hursthills]: "Hursthills",
  [ElementarySchools.Hutchins]: "Hutchins",
  [ElementarySchools.Hutchinson]: "Hutchinson",
  [ElementarySchools.Hutto]: "Hutto",
  [ElementarySchools.HydePark]: "Hyde Park",
  [ElementarySchools.Hyer]: "Hyer",
  [ElementarySchools.Hyman]: "Hyman",
  [ElementarySchools.ITHolleman]: "I T Holleman",
  [ElementarySchools.Idaho]: "Idaho",
  [ElementarySchools.Ikard]: "Ikard",
  [ElementarySchools.Illinois]: "Illinois",
  [ElementarySchools.Independence]: "Independence",
  [ElementarySchools.IndianCreek]: "Indian Creek",
  [ElementarySchools.Indiana]: "Indiana",
  [ElementarySchools.Indiancre]: "Indiancre",
  [ElementarySchools.Indonesia]: "Indonesia",
  [ElementarySchools.IndustrialEast]: "Industrial East",
  [ElementarySchools.IndustrialWest]: "Industrial West",
  [ElementarySchools.Inglish]: "Inglish",
  [ElementarySchools.Ingram]: "Ingram",
  [ElementarySchools.Iraan]: "Iraan",
  [ElementarySchools.Iracross]: "Iracross",
  [ElementarySchools.Iredell]: "Iredell",
  [ElementarySchools.Ireland]: "Ireland",
  [ElementarySchools.IreneClinkscale]: "Irene Clinkscale",
  [ElementarySchools.Irion]: "Irion",
  [ElementarySchools.IrmaDruHutchison]: "Irma Dru Hutchison",
  [ElementarySchools.Irvin]: "Irvin",
  [ElementarySchools.Irving]: "Irving",
  [ElementarySchools.Isbell]: "Isbell",
  [ElementarySchools.Italy]: "Italy",
  [ElementarySchools.Itasca]: "Itasca",
  [ElementarySchools.JBStephens]: "J B Stephens",
  [ElementarySchools.JCiavarra]: "J Ciavarra",
  [ElementarySchools.JacintoCity]: "Jacinto City",
  [ElementarySchools.JackLoweSr]: "Jack Lowe Sr",
  [ElementarySchools.Jackbinion]: "Jackbinion",
  [ElementarySchools.JackieFuller]: "Jackie Fuller",
  [ElementarySchools.Jacksboro]: "Jacksboro",
  [ElementarySchools.Jackson]: "Jackson",
  [ElementarySchools.JacksonRooseveltComplex]: "Jackson/Roosevelt Complex",
  [ElementarySchools.Jacksonkel]: "Jacksonkel",
  [ElementarySchools.Jacksonmay]: "Jacksonmay",
  [ElementarySchools.Jacksonsto]: "Jacksonsto",
  [ElementarySchools.Jamacia]: "Jamacia",
  [ElementarySchools.James]: "James",
  [ElementarySchools.JamesAMonaco]: "James A Monaco",
  [ElementarySchools.Japhet]: "Japhet",
  [ElementarySchools.Jarrell]: "Jarrell",
  [ElementarySchools.Jbrockett]: "Jbrockett",
  [ElementarySchools.JeanColeman]: "Jean Coleman",
  [ElementarySchools.Jeffersave]: "Jeffersave",
  [ElementarySchools.Jefferson]: "Jefferson",
  [ElementarySchools.Jenkins]: "Jenkins",
  [ElementarySchools.JennyPreston]: "Jenny Preston",
  [ElementarySchools.JerryJunkins]: "Jerry Junkins",
  [ElementarySchools.JesseMcgowen]: "Jesse Mcgowen",
  [ElementarySchools.JimSpradley]: "Jim Spradley",
  [ElementarySchools.JimmieTylerBrashear]: "Jimmie Tyler Brashear",
  [ElementarySchools.JoeKBryant]: "Joe K Bryant",
  [ElementarySchools.JohnABaker]: "John A Baker",
  [ElementarySchools.JohnASippel]: "John A Sippel",
  [ElementarySchools.JohnAndMargieStipes]: "John And Margie Stipes",
  [ElementarySchools.JohnandNeldaPartin]: "John and Nelda Partin",
  [ElementarySchools.JohnAndOliveHinojosa]: "John And Olive Hinojosa",
  [ElementarySchools.JohnTWhite]: "John T White",
  [ElementarySchools.JohnTower]: "John Tower",
  [ElementarySchools.JohnieDaniel]: "Johnie Daniel",
  [ElementarySchools.Johnosn]: "Johnosn",
  [ElementarySchools.Johns]: "Johns",
  [ElementarySchools.Johnson]: "Johnson",
  [ElementarySchools.JohnsonRanch]: "Johnson Ranch",
  [ElementarySchools.Johnston]: "Johnston",
  [ElementarySchools.Jollyville]: "Jollyville",
  [ElementarySchools.Jones]: "Jones",
  [ElementarySchools.Jonesboro]: "Jonesboro",
  [ElementarySchools.Jordan]: "Jordan",
  [ElementarySchools.JoseHDamian]: "Jose H Damian",
  [ElementarySchools.JoyceHall]: "Joyce Hall",
  [ElementarySchools.JtStevens]: "Jt Stevens",
  [ElementarySchools.JudithHarlow]: "Judith Harlow",
  [ElementarySchools.JudyHajek]: "Judy Hajek",
  [ElementarySchools.JudyMiller]: "Judy Miller",
  [ElementarySchools.JudyRucker]: "Judy Rucker",
  [ElementarySchools.Junction]: "Junction",
  [ElementarySchools.JuneWDavis]: "June W Davis",
  [ElementarySchools.Justin]: "Justin",
  [ElementarySchools.Justiss]: "Justiss",
  [ElementarySchools.Kahn]: "Kahn",
  [ElementarySchools.Kansas]: "Kansas",
  [ElementarySchools.Karnack]: "Karnack",
  [ElementarySchools.Karnescoun]: "Karnescoun",
  [ElementarySchools.KathrynGriffis]: "Kathryn Griffis",
  [ElementarySchools.Kawas]: "Kawas",
  [ElementarySchools.KayGranger]: "Kay Granger",
  [ElementarySchools.Keene]: "Keene",
  [ElementarySchools.Kellerharv]: "Kellerharv",
  [ElementarySchools.Kelley]: "Kelley",
  [ElementarySchools.Kemp]: "Kemp",
  [ElementarySchools.Kendall]: "Kendall",
  [ElementarySchools.Kenedy]: "Kenedy",
  [ElementarySchools.Kennard]: "Kennard",
  [ElementarySchools.Kennedy]: "Kennedy",
  [ElementarySchools.Kennedypow]: "Kennedypow",
  [ElementarySchools.Kent]: "Kent",
  [ElementarySchools.Kentwood]: "Kentwood",
  [ElementarySchools.Kerens]: "Kerens",
  [ElementarySchools.Kerr]: "Kerr",
  [ElementarySchools.Key]: "Key",
  [ElementarySchools.Keyes]: "Keyes",
  [ElementarySchools.Keys]: "Keys",
  [ElementarySchools.Kidwell]: "Kidwell",
  [ElementarySchools.Kiest]: "Kiest",
  [ElementarySchools.Kilgore]: "Kilgore",
  [ElementarySchools.Kimball]: "Kimball",
  [ElementarySchools.KinderTractArea]: "Kinder Tract Area",
  [ElementarySchools.Kindred]: "Kindred",
  [ElementarySchools.King]: "King",
  [ElementarySchools.KingsManor]: "Kings Manor",
  [ElementarySchools.Kirby]: "Kirby",
  [ElementarySchools.Kirbyville]: "Kirbyville",
  [ElementarySchools.Kirchner]: "Kirchner",
  [ElementarySchools.Kirkpatric]: "Kirkpatric",
  [ElementarySchools.Kleberg]: "Kleberg",
  [ElementarySchools.Knight]: "Knight",
  [ElementarySchools.Knox]: "Knox",
  [ElementarySchools.KnoxCity]: "Knox City",
  [ElementarySchools.Koennecke]: "Koennecke",
  [ElementarySchools.Kohlberg]: "Kohlberg",
  [ElementarySchools.Kopperl]: "Kopperl",
  [ElementarySchools.Kostoryz]: "Kostoryz",
  [ElementarySchools.Kountze]: "Kountze",
  [ElementarySchools.Kramer]: "Kramer",
  [ElementarySchools.Kriewaldrd]: "Kriewaldrd",
  [ElementarySchools.LaGrange]: "La Grange",
  [ElementarySchools.LaMesa]: "La Mesa",
  [ElementarySchools.LaUnion]: "La Union",
  [ElementarySchools.LaVega]: "La Vega",
  [ElementarySchools.LaVillita]: "La Villita",
  [ElementarySchools.Lackland]: "Lackland",
  [ElementarySchools.Lacy]: "Lacy",
  [ElementarySchools.Lagovista]: "Lagovista",
  [ElementarySchools.Lagow]: "Lagow",
  [ElementarySchools.LakePointe]: "Lake Pointe",
  [ElementarySchools.LakeTravis]: "Lake Travis",
  [ElementarySchools.Lakedallas]: "Lakedallas",
  [ElementarySchools.Lakehighla]: "Lakehighla",
  [ElementarySchools.Lakeland]: "Lakeland",
  [ElementarySchools.Lakeridge]: "Lakeridge",
  [ElementarySchools.Lakeside]: "Lakeside",
  [ElementarySchools.Lakeview]: "Lakeview",
  [ElementarySchools.Lakeway]: "Lakeway",
  [ElementarySchools.Lakewood]: "Lakewood",
  [ElementarySchools.Lamar]: "Lamar",
  [ElementarySchools.Lamkin]: "Lamkin",
  [ElementarySchools.Lancaster]: "Lancaster",
  [ElementarySchools.LanceThompson]: "Lance Thompson",
  [ElementarySchools.Landergin]: "Landergin",
  [ElementarySchools.Landry]: "Landry",
  [ElementarySchools.Laneville]: "Laneville",
  [ElementarySchools.Lanier]: "Lanier",
  [ElementarySchools.Lansberry]: "Lansberry",
  [ElementarySchools.Lapoyner]: "Lapoyner",
  [ElementarySchools.Larkspur]: "Larkspur",
  [ElementarySchools.Larson]: "Larson",
  [ElementarySchools.LarueMiller]: "Larue Miller",
  [ElementarySchools.Lascolinas]: "Lascolinas",
  [ElementarySchools.Laspalmas]: "Laspalmas",
  [ElementarySchools.Latexo]: "Latexo",
  [ElementarySchools.LauraIngallsWilder]: "Laura Ingalls Wilder",
  [ElementarySchools.Laurelmoun]: "Laurelmoun",
  [ElementarySchools.Lawhon]: "Lawhon",
  [ElementarySchools.Lawn]: "Lawn",
  [ElementarySchools.Lawndale]: "Lawndale",
  [ElementarySchools.Lawrence]: "Lawrence",
  [ElementarySchools.Lebarronp]: "Lebarronp",
  [ElementarySchools.Lee]: "Lee",
  [ElementarySchools.LeeMcshan]: "Lee Mcshan",
  [ElementarySchools.Leerobert]: "Leerobert",
  [ElementarySchools.Leeumphre]: "Leeumphre",
  [ElementarySchools.LelandEdge]: "Leland Edge",
  [ElementarySchools.Leon]: "Leon",
  [ElementarySchools.LeonSprings]: "Leon Springs",
  [ElementarySchools.Leonard]: "Leonard",
  [ElementarySchools.Leonheight]: "Leonheight",
  [ElementarySchools.LeonidesGonzalezCigarroaMd]:
    "Leonides Gonzalez Cigarroa Md",
  [ElementarySchools.LetaHornSmith]: "Leta Horn Smith",
  [ElementarySchools.Lewis]: "Lewis",
  [ElementarySchools.Lewisville]: "Lewisville",
  [ElementarySchools.Lexington]: "Lexington",
  [ElementarySchools.Leyendecke]: "Leyendecke",
  [ElementarySchools.Libby]: "Libby",
  [ElementarySchools.Liberty]: "Liberty",
  [ElementarySchools.LibertyEylau]: "Liberty-Eylau",
  [ElementarySchools.Libertyhil]: "Libertyhil",
  [ElementarySchools.Ligarde]: "Ligarde",
  [ElementarySchools.LightFarms]: "Light Farms",
  [ElementarySchools.Lillian]: "Lillian",
  [ElementarySchools.LindaLyon]: "Linda Lyon",
  [ElementarySchools.Linden]: "Linden",
  [ElementarySchools.Lindsay]: "Lindsay",
  [ElementarySchools.Linglevill]: "Linglevill",
  [ElementarySchools.Lipan]: "Lipan",
  [ElementarySchools.Lipscomb]: "Lipscomb",
  [ElementarySchools.Lisbon]: "Lisbon",
  [ElementarySchools.Liscano]: "Liscano",
  [ElementarySchools.Little]: "Little",
  [ElementarySchools.Littlebay]: "Littlebay",
  [ElementarySchools.Lively]: "Lively",
  [ElementarySchools.Liveoak]: "Liveoak",
  [ElementarySchools.Llano]: "Llano",
  [ElementarySchools.Logan]: "Logan",
  [ElementarySchools.Lohn]: "Lohn",
  [ElementarySchools.LoisLindsey]: "Lois Lindsey",
  [ElementarySchools.Lomapark]: "Lomapark",
  [ElementarySchools.Lomaterrac]: "Lomaterrac",
  [ElementarySchools.Lometa]: "Lometa",
  [ElementarySchools.London]: "London",
  [ElementarySchools.LoneStar]: "Lone Star",
  [ElementarySchools.Loneoak]: "Loneoak",
  [ElementarySchools.Lonestar]: "Lonestar",
  [ElementarySchools.Long]: "Long",
  [ElementarySchools.Longbranch]: "Longbranch",
  [ElementarySchools.Longfellow]: "Longfellow",
  [ElementarySchools.Longscreek]: "Longscreek",
  [ElementarySchools.Loraine]: "Loraine",
  [ElementarySchools.Lorena]: "Lorena",
  [ElementarySchools.Loretto]: "Loretto",
  [ElementarySchools.LosFresnos]: "Los Fresnos",
  [ElementarySchools.Losncino]: "Losncino",
  [ElementarySchools.LostPines]: "Lost Pines",
  [ElementarySchools.Lotspeich]: "Lotspeich",
  [ElementarySchools.Lott]: "Lott",
  [ElementarySchools.LouiseCabaniss]: "Louise Cabaniss",
  [ElementarySchools.Louisiana]: "Louisiana",
  [ElementarySchools.Lovejoy]: "Lovejoy",
  [ElementarySchools.Lovelady]: "Lovelady",
  [ElementarySchools.Lowe]: "Lowe",
  [ElementarySchools.Loweryrd]: "Loweryrd",
  [ElementarySchools.Lozano]: "Lozano",
  [ElementarySchools.LucilleRogersAshley]: "Lucille Rogers Ashley",
  [ElementarySchools.LuedersAvoca]: "Lueders-Avoca",
  [ElementarySchools.Luellamerr]: "Luellamerr",
  [ElementarySchools.Lujanchave]: "Lujanchave",
  [ElementarySchools.LyndonBJohnson]: "Lyndon B Johnson",
  [ElementarySchools.Lytle]: "Lytle",
  [ElementarySchools.Mabank]: "Mabank",
  [ElementarySchools.Macarthur]: "Macarthur",
  [ElementarySchools.Macdonell]: "Macdonell",
  [ElementarySchools.Macias]: "Macias",
  [ElementarySchools.Mackey]: "Mackey",
  [ElementarySchools.Macon]: "Macon",
  [ElementarySchools.MadeleyRanch]: "Madeley Ranch",
  [ElementarySchools.Madison]: "Madison",
  [ElementarySchools.Madisonville]: "Madisonville",
  [ElementarySchools.Maestevens]: "Maestevens",
  [ElementarySchools.Magee]: "Magee",
  [ElementarySchools.Maine]: "Maine",
  [ElementarySchools.Malakoff]: "Malakoff",
  [ElementarySchools.Malone]: "Malone",
  [ElementarySchools.Malvern]: "Malvern",
  [ElementarySchools.Mambrino]: "Mambrino",
  [ElementarySchools.Manor]: "Manor",
  [ElementarySchools.Manormaria]: "Manormaria",
  [ElementarySchools.Manorthoma]: "Manorthoma",
  [ElementarySchools.Manualjara]: "Manualjara",
  [ElementarySchools.MapleBrook]: "Maple Brook",
  [ElementarySchools.Maplelawn]: "Maplelawn",
  [ElementarySchools.Marblefall]: "Marblefall",
  [ElementarySchools.Marcus]: "Marcus",
  [ElementarySchools.Marcy]: "Marcy",
  [ElementarySchools.MarcyLykins]: "Marcy Lykins",
  [ElementarySchools.Marfa]: "Marfa",
  [ElementarySchools.MargaretFelty]: "Margaret Felty",
  [ElementarySchools.Margil]: "Margil",
  [ElementarySchools.Marietta]: "Marietta",
  [ElementarySchools.MarilynMiller]: "Marilyn Miller",
  [ElementarySchools.Marinecree]: "Marinecree",
  [ElementarySchools.Marion]: "Marion",
  [ElementarySchools.Markham]: "Markham",
  [ElementarySchools.Marlboro]: "Marlboro",
  [ElementarySchools.Marlin]: "Marlin",
  [ElementarySchools.Marsalis]: "Marsalis",
  [ElementarySchools.Marshall]: "Marshall",
  [ElementarySchools.Mart]: "Mart",
  [ElementarySchools.Marti]: "Marti",
  [ElementarySchools.Martinez]: "Martinez",
  [ElementarySchools.Martinjr]: "Martinjr",
  [ElementarySchools.Martinsmil]: "Martinsmil",
  [ElementarySchools.Martinwalk]: "Martinwalk",
  [ElementarySchools.Marvin]: "Marvin",
  [ElementarySchools.MaryBurksMarek]: "Mary Burks Marek",
  [ElementarySchools.MaryJoSheppard]: "Mary Jo Sheppard",
  [ElementarySchools.MaryLouDodson]: "Mary Lou Dodson",
  [ElementarySchools.Maryland]: "Maryland",
  [ElementarySchools.Mason]: "Mason",
  [ElementarySchools.Massachusetts]: "Massachusetts",
  [ElementarySchools.Mata]: "Mata",
  [ElementarySchools.Matagorda]: "Matagorda",
  [ElementarySchools.Mathews]: "Mathews",
  [ElementarySchools.Maudelogan]: "Maudelogan",
  [ElementarySchools.Maudriewal]: "Maudriewal",
  [ElementarySchools.Maverick]: "Maverick",
  [ElementarySchools.MaxHSimpson]: "Max H Simpson",
  [ElementarySchools.Maxdale]: "Maxdale",
  [ElementarySchools.Maxfield]: "Maxfield",
  [ElementarySchools.May]: "May",
  [ElementarySchools.Mayes]: "Mayes",
  [ElementarySchools.Maypearl]: "Maypearl",
  [ElementarySchools.Mays]: "Mays",
  [ElementarySchools.Mcauliffe]: "Mcauliffe",
  [ElementarySchools.Mcbay]: "Mcbay",
  [ElementarySchools.Mcclendon]: "Mcclendon",
  [ElementarySchools.LizzieNellCundiffMcclure]: "Lizzie Nell Cundiff Mcclure",
  [ElementarySchools.Mccoy]: "Mccoy",
  [ElementarySchools.Mcgaha]: "Mcgaha",
  [ElementarySchools.Mckamy]: "Mckamy",
  [ElementarySchools.Mckenzie]: "Mckenzie",
  [ElementarySchools.Mclaughlin]: "Mclaughlin",
  [ElementarySchools.Mcmillan]: "Mcmillan",
  [ElementarySchools.Mcnair]: "Mcnair",
  [ElementarySchools.Mcneil]: "Mcneil",
  [ElementarySchools.Mcqueeney]: "Mcqueeney",
  [ElementarySchools.Mcrae]: "Mcrae",
  [ElementarySchools.McSpedden]: "McSpedden",
  [ElementarySchools.Mcwhorter]: "Mcwhorter",
  [ElementarySchools.Meadowbroo]: "Meadowbroo",
  [ElementarySchools.Meadowcree]: "Meadowcree",
  [ElementarySchools.Meadowcrk]: "Meadowcrk",
  [ElementarySchools.Meadows]: "Meadows",
  [ElementarySchools.Medinavall]: "Medinavall",
  [ElementarySchools.MelbaPassmore]: "Melba Passmore",
  [ElementarySchools.Melissa]: "Melissa",
  [ElementarySchools.Memorial]: "Memorial",
  [ElementarySchools.Menard]: "Menard",
  [ElementarySchools.Mendenhall]: "Mendenhall",
  [ElementarySchools.Mendoza]: "Mendoza",
  [ElementarySchools.Menger]: "Menger",
  [ElementarySchools.Meridian]: "Meridian",
  [ElementarySchools.Meridithdu]: "Meridithdu",
  [ElementarySchools.Merkel]: "Merkel",
  [ElementarySchools.Merrifield]: "Merrifield",
  [ElementarySchools.MerrimanPark]: "Merriman Park",
  [ElementarySchools.Merrimanpk]: "Merrimanpk",
  [ElementarySchools.MesaVerde]: "Mesa Verde",
  [ElementarySchools.Mesavista]: "Mesavista",
  [ElementarySchools.Mesita]: "Mesita",
  [ElementarySchools.Mexico]: "Mexico",
  [ElementarySchools.Meyer]: "Meyer",
  [ElementarySchools.Michael]: "Michael",
  [ElementarySchools.Michigan]: "Michigan",
  [ElementarySchools.Midcrown]: "Midcrown",
  [ElementarySchools.Midwaypark]: "Midwaypark",
  [ElementarySchools.MikeandJanieReeves]: "Mike and Janie Reeves",
  [ElementarySchools.MikeMoseley]: "Mike Moseley",
  [ElementarySchools.Milam]: "Milam",
  [ElementarySchools.Milano]: "Milano",
  [ElementarySchools.Mildrdhawk]: "Mildrdhawk",
  [ElementarySchools.Mildred]: "Mildred",
  [ElementarySchools.Miles]: "Miles",
  [ElementarySchools.Milford]: "Milford",
  [ElementarySchools.Miller]: "Miller",
  [ElementarySchools.Millergrov]: "Millergrov",
  [ElementarySchools.Millerheig]: "Millerheig",
  [ElementarySchools.Millerpoin]: "Millerpoin",
  [ElementarySchools.Mills]: "Mills",
  [ElementarySchools.Millsap]: "Millsap",
  [ElementarySchools.Milton]: "Milton",
  [ElementarySchools.Mina]: "Mina",
  [ElementarySchools.Mineola]: "Mineola",
  [ElementarySchools.Minshew]: "Minshew",
  [ElementarySchools.Mireles]: "Mireles",
  [ElementarySchools.MissionVa]: "Mission Va",
  [ElementarySchools.Mississippi]: "Mississippi",
  [ElementarySchools.Missjewell]: "Missjewell",
  [ElementarySchools.Missouri]: "Missouri",
  [ElementarySchools.Mitchell]: "Mitchell",
  [ElementarySchools.Mitchellbl]: "Mitchellbl",
  [ElementarySchools.Moates]: "Moates",
  [ElementarySchools.Mockingbir]: "Mockingbir",
  [ElementarySchools.Mohawk]: "Mohawk",
  [ElementarySchools.MollyLivengoodCarter]: "Molly Livengood Carter",
  [ElementarySchools.Monahan]: "Monahan",
  [ElementarySchools.Montague]: "Montague",
  [ElementarySchools.Montaguevi]: "Montaguevi",
  [ElementarySchools.Montana]: "Montana",
  [ElementarySchools.Montanavis]: "Montanavis",
  [ElementarySchools.Montclair]: "Montclair",
  [ElementarySchools.Montgomery]: "Montgomery",
  [ElementarySchools.Moody]: "Moody",
  [ElementarySchools.Mooneyham]: "Mooneyham",
  [ElementarySchools.Moore]: "Moore",
  [ElementarySchools.MooreMh]: "Moore Mh",
  [ElementarySchools.Mooresally]: "Mooresally",
  [ElementarySchools.Moran]: "Moran",
  [ElementarySchools.Moreno]: "Moreno",
  [ElementarySchools.Morgan]: "Morgan",
  [ElementarySchools.MorganMil]: "Morgan Mil",
  [ElementarySchools.Morningsid]: "Morningsid",
  [ElementarySchools.Morningside]: "Morningside",
  [ElementarySchools.Morrill]: "Morrill",
  [ElementarySchools.Morris]: "Morris",
  [ElementarySchools.Morton]: "Morton",
  [ElementarySchools.Moseley]: "Moseley",
  [ElementarySchools.Moss]: "Moss",
  [ElementarySchools.Mosshaven]: "Mosshaven",
  [ElementarySchools.Motley]: "Motley",
  [ElementarySchools.MotleyCountySchool]: "Motley County School",
  [ElementarySchools.Mound]: "Mound",
  [ElementarySchools.Mounger]: "Mounger",
  [ElementarySchools.MountEnterprise]: "Mount Enterprise",
  [ElementarySchools.Mountainvi]: "Mountainvi",
  [ElementarySchools.Mountaubur]: "Mountaubur",
  [ElementarySchools.MrsJerryBryant]: "Mrs. Jerry Bryant",
  [ElementarySchools.Mtcalm]: "Mtcalm",
  [ElementarySchools.Mtpeak]: "Mtpeak",
  [ElementarySchools.Mtvernon]: "Mtvernon",
  [ElementarySchools.Muenster]: "Muenster",
  [ElementarySchools.Mullendore]: "Mullendore",
  [ElementarySchools.Mullin]: "Mullin",
  [ElementarySchools.Mumford]: "Mumford",
  [ElementarySchools.Munday]: "Munday",
  [ElementarySchools.Murchison]: "Murchison",
  [ElementarySchools.MurryFly]: "Murry Fly",
  [ElementarySchools.NHiMt]: "N Hi Mt",
  [ElementarySchools.Nacogdoches]: "Nacogdoches",
  [ElementarySchools.Nacona]: "Nacona",
  [ElementarySchools.NancyNeal]: "Nancy Neal",
  [ElementarySchools.NancySmith]: "Nancy Smith",
  [ElementarySchools.NaomiPress]: "Naomi Press",
  [ElementarySchools.Nash]: "Nash",
  [ElementarySchools.Natalia]: "Natalia",
  [ElementarySchools.Nathahowel]: "Nathahowel",
  [ElementarySchools.Naumann]: "Naumann",
  [ElementarySchools.Navarro]: "Navarro",
  [ElementarySchools.Neal]: "Neal",
  [ElementarySchools.Neches]: "Neches",
  [ElementarySchools.NellHillRhea]: "Nell Hill Rhea",
  [ElementarySchools.Nelson]: "Nelson",
  [ElementarySchools.Nesmith]: "Nesmith",
  [ElementarySchools.Nevada]: "Nevada",
  [ElementarySchools.NewCaney]: "New Caney",
  [ElementarySchools.NewHome]: "New Home",
  [ElementarySchools.NewMexico]: "New Mexico",
  [ElementarySchools.NewSummerfield]: "New Summerfield",
  [ElementarySchools.NewWaverly]: "New Waverly",
  [ElementarySchools.NewYork]: "New York",
  [ElementarySchools.Newcastle]: "Newcastle",
  [ElementarySchools.Newdiana]: "Newdiana",
  [ElementarySchools.Newman]: "Newman",
  [ElementarySchools.Newton]: "Newton",
  [ElementarySchools.Newtonrayzor]: "Newtonrayzor",
  [ElementarySchools.Nicaragua]: "Nicaragua",
  [ElementarySchools.Nichols]: "Nichols",
  [ElementarySchools.Nimitz]: "Nimitz",
  [ElementarySchools.Nitsch]: "Nitsch",
  [ElementarySchools.Nixon]: "Nixon",
  [ElementarySchools.Njoshua]: "Njoshua",
  [ElementarySchools.NobleReed]: "Noble Reed",
  [ElementarySchools.Nocona]: "Nocona",
  [ElementarySchools.Nolanville]: "Nolanville",
  [ElementarySchools.NormaJPaschal]: "Norma J Paschal",
  [ElementarySchools.NormandyCrossing]: "Normandy Crossing",
  [ElementarySchools.Normangee]: "Normangee",
  [ElementarySchools.Norris]: "Norris",
  [ElementarySchools.North]: "North",
  [ElementarySchools.NorthCarolina]: "North Carolina",
  [ElementarySchools.NorthCreek]: "North Creek",
  [ElementarySchools.NorthDakota]: "North Dakota",
  [ElementarySchools.NorthGrammar]: "North Grammar",
  [ElementarySchools.NorthHeights]: "North Heights",
  [ElementarySchools.NorthShore]: "North Shore",
  [ElementarySchools.Northbrook]: "Northbrook",
  [ElementarySchools.Northernhi]: "Northernhi",
  [ElementarySchools.Northeules]: "Northeules",
  [ElementarySchools.Northhopki]: "Northhopki",
  [ElementarySchools.Northhts]: "Northhts",
  [ElementarySchools.Northlake]: "Northlake",
  [ElementarySchools.Northloop]: "Northloop",
  [ElementarySchools.Northrichl]: "Northrichl",
  [ElementarySchools.Northridg]: "Northridg",
  [ElementarySchools.Northriver]: "Northriver",
  [ElementarySchools.Northside]: "Northside",
  [ElementarySchools.Northstar]: "Northstar",
  [ElementarySchools.Northwest]: "Northwest",
  [ElementarySchools.Northwood]: "Northwood",
  [ElementarySchools.Norton]: "Norton",
  [ElementarySchools.Norwood]: "Norwood",
  [ElementarySchools.NtreisTestOnly]: "Ntreis Test Only",
  [ElementarySchools.Nueces]: "Nueces",
  [ElementarySchools.OakCreek]: "Oak Creek",
  [ElementarySchools.OakDale]: "Oak Dale",
  [ElementarySchools.OakForest]: "Oak Forest",
  [ElementarySchools.OakPark]: "Oak Park",
  [ElementarySchools.OakPoint]: "Oak Point",
  [ElementarySchools.Oakgrove]: "Oakgrove",
  [ElementarySchools.Oakhurst]: "Oakhurst",
  [ElementarySchools.Oaklawn]: "Oaklawn",
  [ElementarySchools.Oakmeadow]: "Oakmeadow",
  [ElementarySchools.Oakmont]: "Oakmont",
  [ElementarySchools.Oakwood]: "Oakwood",
  [ElementarySchools.Oakwoods]: "Oakwoods",
  [ElementarySchools.Oakwoodter]: "Oakwoodter",
  [ElementarySchools.Ochoa]: "Ochoa",
  [ElementarySchools.ODell]: "O'Dell",
  [ElementarySchools.Odonnell]: "Odonnell",
  [ElementarySchools.Ogden]: "Ogden",
  [ElementarySchools.Ogle]: "Ogle",
  [ElementarySchools.Oglesby]: "Oglesby",
  [ElementarySchools.Ohenry]: "Ohenry",
  [ElementarySchools.Ohio]: "Ohio",
  [ElementarySchools.Oklahoma]: "Oklahoma",
  [ElementarySchools.OldSettlers]: "Old Settlers",
  [ElementarySchools.Oldtown]: "Oldtown",
  [ElementarySchools.Oldunion]: "Oldunion",
  [ElementarySchools.Olfen]: "Olfen",
  [ElementarySchools.OliveStephens]: "Olive Stephens",
  [ElementarySchools.Oliver]: "Oliver",
  [ElementarySchools.OliverClift]: "Oliver Clift",
  [ElementarySchools.Olmos]: "Olmos",
  [ElementarySchools.Olney]: "Olney",
  [ElementarySchools.Olsen]: "Olsen",
  [ElementarySchools.OlsenPark]: "Olsen Park",
  [ElementarySchools.Olson]: "Olson",
  [ElementarySchools.Olympia]: "Olympia",
  [ElementarySchools.OpalSmith]: "Opal Smith",
  [ElementarySchools.Oppe]: "Oppe",
  [ElementarySchools.Orecity]: "Orecity",
  [ElementarySchools.Oregon]: "Oregon",
  [ElementarySchools.Orr]: "Orr",
  [ElementarySchools.Ortiz]: "Ortiz",
  [ElementarySchools.Osheakeleh]: "Osheakeleh",
  [ElementarySchools.Other]: "Other",
  [ElementarySchools.OuidaSpringer]: "Ouida Springer",
  [ElementarySchools.Overton]: "Overton",
  [ElementarySchools.Owen]: "Owen",
  [ElementarySchools.Owens]: "Owens",
  [ElementarySchools.Ozona]: "Ozona",
  [ElementarySchools.Packsaddle]: "Packsaddle",
  [ElementarySchools.Paducah]: "Paducah",
  [ElementarySchools.PaintCreek]: "Paint Creek",
  [ElementarySchools.PaintRock]: "Paint Rock",
  [ElementarySchools.Palmer]: "Palmer",
  [ElementarySchools.PaloPinto]: "Palo Pinto",
  [ElementarySchools.Paloalto]: "Paloalto",
  [ElementarySchools.PalomaCreek]: "Paloma Creek",
  [ElementarySchools.Panama]: "Panama",
  [ElementarySchools.Panhandle]: "Panhandle",
  [ElementarySchools.PantherCreek]: "Panther Creek",
  [ElementarySchools.Paradise]: "Paradise",
  [ElementarySchools.ParamountTerrace]: "Paramount Terrace",
  [ElementarySchools.Park]: "Park",
  [ElementarySchools.ParkLakes]: "Park Lakes",
  [ElementarySchools.Parker]: "Parker",
  [ElementarySchools.Parkglen]: "Parkglen",
  [ElementarySchools.Parkland]: "Parkland",
  [ElementarySchools.Parkview]: "Parkview",
  [ElementarySchools.Parkvillag]: "Parkvillag",
  [ElementarySchools.Parkway]: "Parkway",
  [ElementarySchools.Parmley]: "Parmley",
  [ElementarySchools.Parnell]: "Parnell",
  [ElementarySchools.Parsons]: "Parsons",
  [ElementarySchools.Pasemann]: "Pasemann",
  [ElementarySchools.Pasodale]: "Pasodale",
  [ElementarySchools.PateAm]: "Pate Am",
  [ElementarySchools.Patlan]: "Patlan",
  [ElementarySchools.PatriciaDeanBoswellMccall]: "Patricia Dean Boswell Mccall",
  [ElementarySchools.Patterson]: "Patterson",
  [ElementarySchools.PattonSprings]: "Patton Springs",
  [ElementarySchools.PaulBelton]: "Paul Belton",
  [ElementarySchools.Peabody]: "Peabody",
  [ElementarySchools.Pearcy]: "Pearcy",
  [ElementarySchools.Pease]: "Pease",
  [ElementarySchools.Peaster]: "Peaster",
  [ElementarySchools.Pebblecree]: "Pebblecree",
  [ElementarySchools.Pebblehill]: "Pebblehill",
  [ElementarySchools.Pecancreek]: "Pecancreek",
  [ElementarySchools.Pecanvalle]: "Pecanvalle",
  [ElementarySchools.Peebles]: "Peebles",
  [ElementarySchools.Peeler]: "Peeler",
  [ElementarySchools.Peete]: "Peete",
  [ElementarySchools.Penelope]: "Penelope",
  [ElementarySchools.Pennsylvania]: "Pennsylvania",
  [ElementarySchools.Penny]: "Penny",
  [ElementarySchools.Perales]: "Perales",
  [ElementarySchools.PercyWNeblett]: "Percy W Neblett",
  [ElementarySchools.Perot]: "Perot",
  [ElementarySchools.Perrin]: "Perrin",
  [ElementarySchools.Pershing]: "Pershing",
  [ElementarySchools.Pershingpa]: "Pershingpa",
  [ElementarySchools.Peru]: "Peru",
  [ElementarySchools.PetersColony]: "Peters Colony",
  [ElementarySchools.Peterson]: "Peterson",
  [ElementarySchools.Petrolia]: "Petrolia",
  [ElementarySchools.Petronila]: "Petronila",
  [ElementarySchools.Pettus]: "Pettus",
  [ElementarySchools.Phillips]: "Phillips",
  [ElementarySchools.PhillipsM]: "Phillips M",
  [ElementarySchools.PhylissNesmith]: "Phyliss Nesmith",
  [ElementarySchools.Pickett]: "Pickett",
  [ElementarySchools.Pierce]: "Pierce",
  [ElementarySchools.PilotPoint]: "Pilot Point",
  [ElementarySchools.PineForest]: "Pine Forest",
  [ElementarySchools.PineRidge]: "Pine Ridge",
  [ElementarySchools.Pinetree]: "Pinetree",
  [ElementarySchools.Pink]: "Pink",
  [ElementarySchools.Pinkerton]: "Pinkerton",
  [ElementarySchools.Pirrung]: "Pirrung",
  [ElementarySchools.Pirtle]: "Pirtle",
  [ElementarySchools.PleasantGrove]: "Pleasant Grove",
  [ElementarySchools.PleasantRun]: "Pleasant Run",
  [ElementarySchools.PleasantValley]: "Pleasant Valley",
  [ElementarySchools.Pleasantgr]: "Pleasantgr",
  [ElementarySchools.PlumCreek]: "Plum Creek",
  [ElementarySchools.Plummer]: "Plummer",
  [ElementarySchools.PointComfort]: "Point Comfort",
  [ElementarySchools.Polk]: "Polk",
  [ElementarySchools.Polser]: "Polser",
  [ElementarySchools.Ponder]: "Ponder",
  [ElementarySchools.Pondspring]: "Pondspring",
  [ElementarySchools.Poolville]: "Poolville",
  [ElementarySchools.Pope]: "Pope",
  [ElementarySchools.PortIsabel]: "Port Isabel",
  [ElementarySchools.PortNeches]: "Port Neches",
  [ElementarySchools.PortOConnor]: "Port O Connor",
  [ElementarySchools.Porter]: "Porter",
  [ElementarySchools.Post]: "Post",
  [ElementarySchools.Postma]: "Postma",
  [ElementarySchools.Potranco]: "Potranco",
  [ElementarySchools.Pottsboro]: "Pottsboro",
  [ElementarySchools.Powell]: "Powell",
  [ElementarySchools.Poynter]: "Poynter",
  [ElementarySchools.PrairieLea]: "Prairie Lea",
  [ElementarySchools.PrairieTrail]: "Prairie Trail",
  [ElementarySchools.PrairieVa]: "Prairie Va",
  [ElementarySchools.Prairiecre]: "Prairiecre",
  [ElementarySchools.Prairievie]: "Prairievie",
  [ElementarySchools.Presa]: "Presa",
  [ElementarySchools.Prescott]: "Prescott",
  [ElementarySchools.Presidio]: "Presidio",
  [ElementarySchools.Prestonhol]: "Prestonhol",
  [ElementarySchools.Prestonwoo]: "Prestonwoo",
  [ElementarySchools.PrestwickK8Stem]: "Prestwick K-8 Stem",
  [ElementarySchools.Price]: "Price",
  [ElementarySchools.Priddy]: "Priddy",
  [ElementarySchools.Providence]: "Providence",
  [ElementarySchools.Puckett]: "Puckett",
  [ElementarySchools.PuertoRico]: "Puerto Rico",
  [ElementarySchools.Pullen]: "Pullen",
  [ElementarySchools.Purefoy]: "Purefoy",
  [ElementarySchools.Purl]: "Purl",
  [ElementarySchools.PurpleSage]: "Purple Sage",
  [ElementarySchools.Purplesage]: "Purplesage",
  [ElementarySchools.Putnam]: "Putnam",
  [ElementarySchools.Pyburn]: "Pyburn",
  [ElementarySchools.Quitman]: "Quitman",
  [ElementarySchools.RAHall]: "R A Hall",
  [ElementarySchools.RSteveFolsom]: "R Steve Folsom",
  [ElementarySchools.RJWilson]: "R.J.Wilson",
  [ElementarySchools.Race]: "Race",
  [ElementarySchools.Raguet]: "Raguet",
  [ElementarySchools.Rains]: "Rains",
  [ElementarySchools.Rainwater]: "Rainwater",
  [ElementarySchools.RalphandMaryLynnBoyer]: "Ralph and Mary Lynn Boyer",
  [ElementarySchools.Ramey]: "Ramey",
  [ElementarySchools.Ramona]: "Ramona",
  [ElementarySchools.Randall]: "Randall",
  [ElementarySchools.Randolph]: "Randolph",
  [ElementarySchools.Range]: "Range",
  [ElementarySchools.Ranger]: "Ranger",
  [ElementarySchools.Rankin]: "Rankin",
  [ElementarySchools.Rann]: "Rann",
  [ElementarySchools.Rasor]: "Rasor",
  [ElementarySchools.Ray]: "Ray",
  [ElementarySchools.Rayburn]: "Rayburn",
  [ElementarySchools.Rayeallen]: "Rayeallen",
  [ElementarySchools.Rayzor]: "Rayzor",
  [ElementarySchools.Reagan]: "Reagan",
  [ElementarySchools.Rebeccacrk]: "Rebeccacrk",
  [ElementarySchools.RedLick]: "Red Lick",
  [ElementarySchools.RedOak]: "Red Oak",
  [ElementarySchools.Redlandoak]: "Redlandoak",
  [ElementarySchools.Redrock]: "Redrock",
  [ElementarySchools.Redsands]: "Redsands",
  [ElementarySchools.Redwater]: "Redwater",
  [ElementarySchools.Reecescree]: "Reecescree",
  [ElementarySchools.Reed]: "Reed",
  [ElementarySchools.ReevesHinger]: "Reeves-Hinger",
  [ElementarySchools.Reevhinger]: "Reevhinger",
  [ElementarySchools.Regencypla]: "Regencypla",
  [ElementarySchools.Reid]: "Reid",
  [ElementarySchools.Reilly]: "Reilly",
  [ElementarySchools.Reinhardt]: "Reinhardt",
  [ElementarySchools.Remingtnpt]: "Remingtnpt",
  [ElementarySchools.Remynse]: "Remynse",
  [ElementarySchools.Reno]: "Reno",
  [ElementarySchools.Reynolds]: "Reynolds",
  [ElementarySchools.Rhodes]: "Rhodes",
  [ElementarySchools.Rice]: "Rice",
  [ElementarySchools.RichardJLee]: "Richard J Lee",
  [ElementarySchools.Richards]: "Richards",
  [ElementarySchools.RichardsonHeights]: "Richardson Heights",
  [ElementarySchools.Richardste]: "Richardste",
  [ElementarySchools.Richland]: "Richland",
  [ElementarySchools.Riddle]: "Riddle",
  [ElementarySchools.Ridgecrest]: "Ridgecrest",
  [ElementarySchools.Ridgeview]: "Ridgeview",
  [ElementarySchools.Ridgewood]: "Ridgewood",
  [ElementarySchools.Ridgleahil]: "Ridgleahil",
  [ElementarySchools.Ringgold]: "Ringgold",
  [ElementarySchools.RioHondo]: "Rio Hondo",
  [ElementarySchools.RioVista]: "Rio Vista",
  [ElementarySchools.Risdacadem]: "Risdacadem",
  [ElementarySchools.Rishardshe]: "Rishardshe",
  [ElementarySchools.RisingStar]: "Rising Star",
  [ElementarySchools.RitaDrabek]: "Rita Drabek",
  [ElementarySchools.RiverRidge]: "River Ridge",
  [ElementarySchools.Rivera]: "Rivera",
  [ElementarySchools.Riverchase]: "Riverchase",
  [ElementarySchools.Rivercrest]: "Rivercrest",
  [ElementarySchools.Riversidep]: "Riversidep",
  [ElementarySchools.Rivertrail]: "Rivertrail",
  [ElementarySchools.RjRichey]: "Rj Richey",
  [ElementarySchools.Roanforest]: "Roanforest",
  [ElementarySchools.Roanoke]: "Roanoke",
  [ElementarySchools.Roark]: "Roark",
  [ElementarySchools.Robb]: "Robb",
  [ElementarySchools.Roberson]: "Roberson",
  [ElementarySchools.RobertCrippen]: "Robert Crippen",
  [ElementarySchools.RobertKing]: "Robert King",
  [ElementarySchools.RobertLPuster]: "Robert L. Puster",
  [ElementarySchools.Robertlee]: "Robertlee",
  [ElementarySchools.Roberts]: "Roberts",
  [ElementarySchools.RobertsRoad]: "Roberts Road",
  [ElementarySchools.Robertson]: "Robertson",
  [ElementarySchools.Robinson]: "Robinson",
  [ElementarySchools.Roby]: "Roby",
  [ElementarySchools.Rochelle]: "Rochelle",
  [ElementarySchools.Rochester]: "Rochester",
  [ElementarySchools.Rockbrook]: "Rockbrook",
  [ElementarySchools.Rockdale]: "Rockdale",
  [ElementarySchools.Rockenbaug]: "Rockenbaug",
  [ElementarySchools.Rockprairi]: "Rockprairi",
  [ElementarySchools.Rocksprings]: "Rocksprings",
  [ElementarySchools.Rodriguez]: "Rodriguez",
  [ElementarySchools.Rogers]: "Rogers",
  [ElementarySchools.Rojax]: "Rojax",
  [ElementarySchools.RollingHills]: "Rolling Hills",
  [ElementarySchools.Rollinghil]: "Rollinghil",
  [ElementarySchools.Roosevelt]: "Roosevelt",
  [ElementarySchools.RoqueGuerraJr]: "Roque Guerra Jr",
  [ElementarySchools.Roquemore]: "Roquemore",
  [ElementarySchools.RosaParksMillbrook]: "Rosa Parks-Millbrook",
  [ElementarySchools.Roscoe]: "Roscoe",
  [ElementarySchools.RoseGarden]: "Rose Garden",
  [ElementarySchools.Rosemeade]: "Rosemeade",
  [ElementarySchools.Rosemont]: "Rosemont",
  [ElementarySchools.RosemontPark]: "Rosemont Park",
  [ElementarySchools.Rosenberg]: "Rosenberg",
  [ElementarySchools.Rositavall]: "Rositavall",
  [ElementarySchools.Rotan]: "Rotan",
  [ElementarySchools.Rountree]: "Rountree",
  [ElementarySchools.Rowe]: "Rowe",
  [ElementarySchools.Roxton]: "Roxton",
  [ElementarySchools.RoyWRoberts]: "Roy W. Roberts",
  [ElementarySchools.Royalridge]: "Royalridge",
  [ElementarySchools.Royalwood]: "Royalwood",
  [ElementarySchools.Rugel]: "Rugel",
  [ElementarySchools.Rule]: "Rule",
  [ElementarySchools.Runge]: "Runge",
  [ElementarySchools.Runyon]: "Runyon",
  [ElementarySchools.Rush]: "Rush",
  [ElementarySchools.Rusk]: "Rusk",
  [ElementarySchools.Russell]: "Russell",
  [ElementarySchools.RussellSchupmann]: "Russell Schupmann",
  [ElementarySchools.RusticOak]: "Rustic Oak",
  [ElementarySchools.RuthandHaroldFrazier]: "Ruth and Harold Frazier",
  [ElementarySchools.RuthCherry]: "Ruth Cherry",
  [ElementarySchools.Rutherford]: "Rutherford",
  [ElementarySchools.Ryan]: "Ryan",
  [ElementarySchools.Ryanws]: "Ryanws",
  [ElementarySchools.SAndS]: "S And S",
  [ElementarySchools.Sabinal]: "Sabinal",
  [ElementarySchools.Sabine]: "Sabine",
  [ElementarySchools.Sagamorehi]: "Sagamorehi",
  [ElementarySchools.Sageland]: "Sageland",
  [ElementarySchools.Saginaw]: "Saginaw",
  [ElementarySchools.Saigling]: "Saigling",
  [ElementarySchools.SaintJo]: "Saint Jo",
  [ElementarySchools.Salazar]: "Salazar",
  [ElementarySchools.Saldivar]: "Saldivar",
  [ElementarySchools.Saltillo]: "Saltillo",
  [ElementarySchools.SamJohnson]: "Sam Johnson",
  [ElementarySchools.Sambrano]: "Sambrano",
  [ElementarySchools.Samrayburn]: "Samrayburn",
  [ElementarySchools.Samrosen]: "Samrosen",
  [ElementarySchools.SanAugustine]: "San Augustine",
  [ElementarySchools.SanJacinto]: "San Jacinto",
  [ElementarySchools.SanSaba]: "San Saba",
  [ElementarySchools.Sanandres]: "Sanandres",
  [ElementarySchools.Sanborn]: "Sanborn",
  [ElementarySchools.Sanchez]: "Sanchez",
  [ElementarySchools.SandbrockRanch]: "Sandbrock Ranch",
  [ElementarySchools.Sanders]: "Sanders",
  [ElementarySchools.Sanger]: "Sanger",
  [ElementarySchools.Sanjacinto]: "Sanjacinto",
  [ElementarySchools.Sanluis]: "Sanluis",
  [ElementarySchools.Sanpedro]: "Sanpedro",
  [ElementarySchools.SantaAnna]: "Santa Anna",
  [ElementarySchools.SantaFe]: "Santa Fe",
  [ElementarySchools.SantaRita]: "Santa Rita",
  [ElementarySchools.Santamaria]: "Santamaria",
  [ElementarySchools.Santo]: "Santo",
  [ElementarySchools.Santonino]: "Santonino",
  [ElementarySchools.Saugustine]: "Saugustine",
  [ElementarySchools.Savannah]: "Savannah",
  [ElementarySchools.SavannahLakes]: "Savannah Lakes",
  [ElementarySchools.Savoy]: "Savoy",
  [ElementarySchools.Scarborough]: "Scarborough",
  [ElementarySchools.Schanen]: "Schanen",
  [ElementarySchools.Scheh]: "Scheh",
  [ElementarySchools.Schell]: "Schell",
  [ElementarySchools.Schenck]: "Schenck",
  [ElementarySchools.Schertz]: "Schertz",
  [ElementarySchools.Schulz]: "Schulz",
  [ElementarySchools.Schulze]: "Schulze",
  [ElementarySchools.Schurz]: "Schurz",
  [ElementarySchools.Schuster]: "Schuster",
  [ElementarySchools.Scotland]: "Scotland",
  [ElementarySchools.Scotsdale]: "Scotsdale",
  [ElementarySchools.Scott]: "Scott",
  [ElementarySchools.Scurry]: "Scurry",
  [ElementarySchools.Seabourn]: "Seabourn",
  [ElementarySchools.Seagoville]: "Seagoville",
  [ElementarySchools.Secomines]: "Secomines",
  [ElementarySchools.Seele]: "Seele",
  [ElementarySchools.Seguin]: "Seguin",
  [ElementarySchools.Sellers]: "Sellers",
  [ElementarySchools.Sem]: "Sem",
  [ElementarySchools.SenderaRanch]: "Sendera Ranch",
  [ElementarySchools.Sequoyah]: "Sequoyah",
  [ElementarySchools.SereneHills]: "Serene Hills",
  [ElementarySchools.Serna]: "Serna",
  [ElementarySchools.Sevenhills]: "Sevenhills",
  [ElementarySchools.Seymour]: "Seymour",
  [ElementarySchools.Shackelfor]: "Shackelfor",
  [ElementarySchools.ShadyGrove]: "Shady Grove",
  [ElementarySchools.ShadyShores]: "Shady Shores",
  [ElementarySchools.Shadybrook]: "Shadybrook",
  [ElementarySchools.Shadycrest]: "Shadycrest",
  [ElementarySchools.Shadygrove]: "Shadygrove",
  [ElementarySchools.Shadyoaks]: "Shadyoaks",
  [ElementarySchools.Shands]: "Shands",
  [ElementarySchools.SharonShannon]: "Sharon Shannon",
  [ElementarySchools.Shaw]: "Shaw",
  [ElementarySchools.Shawnee]: "Shawnee",
  [ElementarySchools.Sheffield]: "Sheffield",
  [ElementarySchools.Sheldon]: "Sheldon",
  [ElementarySchools.Shepard]: "Shepard",
  [ElementarySchools.Sherrod]: "Sherrod",
  [ElementarySchools.SherryandPaulHamm]: "Sherry and Paul Hamm",
  [ElementarySchools.Shields]: "Shields",
  [ElementarySchools.Shive]: "Shive",
  [ElementarySchools.Short]: "Short",
  [ElementarySchools.Sides]: "Sides",
  [ElementarySchools.Sidney]: "Sidney",
  [ElementarySchools.SidneyHPoynter]: "Sidney H Poynter",
  [ElementarySchools.Siebert]: "Siebert",
  [ElementarySchools.SierraBlanca]: "Sierra Blanca",
  [ElementarySchools.Sierravist]: "Sierravist",
  [ElementarySchools.Sigler]: "Sigler",
  [ElementarySchools.Silberstei]: "Silberstei",
  [ElementarySchools.Silvercree]: "Silvercree",
  [ElementarySchools.Silvercrest]: "Silvercrest",
  [ElementarySchools.Silverlake]: "Silverlake",
  [ElementarySchools.Sims]: "Sims",
  [ElementarySchools.Sinclair]: "Sinclair",
  [ElementarySchools.Sivellsben]: "Sivellsben",
  [ElementarySchools.Skaggs]: "Skaggs",
  [ElementarySchools.Skyharbour]: "Skyharbour",
  [ElementarySchools.Skyview]: "Skyview",
  [ElementarySchools.Slack]: "Slack",
  [ElementarySchools.Slaughter]: "Slaughter",
  [ElementarySchools.SleepyHollow]: "Sleepy Hollow",
  [ElementarySchools.Slidell]: "Slidell",
  [ElementarySchools.Slocum]: "Slocum",
  [ElementarySchools.Smith]: "Smith",
  [ElementarySchools.Smithfield]: "Smithfield",
  [ElementarySchools.Snook]: "Snook",
  [ElementarySchools.Snowheight]: "Snowheight",
  [ElementarySchools.Snyder]: "Snyder",
  [ElementarySchools.Somerset]: "Somerset",
  [ElementarySchools.Somerville]: "Somerville",
  [ElementarySchools.Sonntag]: "Sonntag",
  [ElementarySchools.SonnyAndAllegraNance]: "Sonny And Allegra Nance",
  [ElementarySchools.Sonora]: "Sonora",
  [ElementarySchools.Souder]: "Souder",
  [ElementarySchools.SourLake]: "Sour Lake",
  [ElementarySchools.South]: "South",
  [ElementarySchools.SouthAfrica]: "South Africa",
  [ElementarySchools.SouthAthens]: "South Athens",
  [ElementarySchools.SouthDakota]: "South Dakota",
  [ElementarySchools.SouthGeorgia]: "South Georgia",
  [ElementarySchools.SouthKnoll]: "South Knoll",
  [ElementarySchools.SouthWard]: "South Ward",
  [ElementarySchools.Southbirdv]: "Southbirdv",
  [ElementarySchools.Southbosqu]: "Southbosqu",
  [ElementarySchools.Southdavis]: "Southdavis",
  [ElementarySchools.Southeast]: "Southeast",
  [ElementarySchools.Southeules]: "Southeules",
  [ElementarySchools.Southftwor]: "Southftwor",
  [ElementarySchools.Southhills]: "Southhills",
  [ElementarySchools.Southhimou]: "Southhimou",
  [ElementarySchools.Southlawn]: "Southlawn",
  [ElementarySchools.Southloop]: "Southloop",
  [ElementarySchools.Southmarsh]: "Southmarsh",
  [ElementarySchools.Southridge]: "Southridge",
  [ElementarySchools.Southside]: "Southside",
  [ElementarySchools.Southwest]: "Southwest",
  [ElementarySchools.Southwoodv]: "Southwoodv",
  [ElementarySchools.Spain]: "Spain",
  [ElementarySchools.Sparks]: "Sparks",
  [ElementarySchools.Sparta]: "Sparta",
  [ElementarySchools.Spears]: "Spears",
  [ElementarySchools.Specht]: "Specht",
  [ElementarySchools.Speeglevil]: "Speeglevil",
  [ElementarySchools.Speer]: "Speer",
  [ElementarySchools.Spicer]: "Spicer",
  [ElementarySchools.Spicewood]: "Spicewood",
  [ElementarySchools.Spigner]: "Spigner",
  [ElementarySchools.SpringCanyon]: "Spring Canyon",
  [ElementarySchools.SpringCreek]: "Spring Creek",
  [ElementarySchools.SpringValley]: "Spring Valley",
  [ElementarySchools.Springardn]: "Springardn",
  [ElementarySchools.Springdale]: "Springdale",
  [ElementarySchools.Springer]: "Springer",
  [ElementarySchools.Springlkpk]: "Springlkpk",
  [ElementarySchools.Springmead]: "Springmead",
  [ElementarySchools.Springridge]: "Springridge",
  [ElementarySchools.Springtown]: "Springtown",
  [ElementarySchools.Springvall]: "Springvall",
  [ElementarySchools.Spur]: "Spur",
  [ElementarySchools.Ssdillow]: "Ssdillow",
  [ElementarySchools.StLucia]: "St. Lucia",
  [ElementarySchools.StVincent]: "St. Vincent",
  [ElementarySchools.Stafford]: "Stafford",
  [ElementarySchools.Stahl]: "Stahl",
  [ElementarySchools.Stanfield]: "Stanfield",
  [ElementarySchools.Stanton]: "Stanton",
  [ElementarySchools.StantonSmith]: "Stanton Smith",
  [ElementarySchools.Staples]: "Staples",
  [ElementarySchools.Stark]: "Stark",
  [ElementarySchools.Starkey]: "Starkey",
  [ElementarySchools.Starks]: "Starks",
  [ElementarySchools.Starrett]: "Starrett",
  [ElementarySchools.Startzville]: "Startzville",
  [ElementarySchools.Steele]: "Steele",
  [ElementarySchools.Steinerran]: "Steinerran",
  [ElementarySchools.Stemmons]: "Stemmons",
  [ElementarySchools.Stevenspar]: "Stevenspar",
  [ElementarySchools.Stewart]: "Stewart",
  [ElementarySchools.StewartCreek]: "Stewart Creek",
  [ElementarySchools.StewartsCreek]: "Stewarts Creek",
  [ElementarySchools.Stinson]: "Stinson",
  [ElementarySchools.Stjoseph]: "Stjoseph",
  [ElementarySchools.Stockdale]: "Stockdale",
  [ElementarySchools.Stonegate]: "Stonegate",
  [ElementarySchools.Stoneharry]: "Stoneharry",
  [ElementarySchools.Stoneoak]: "Stoneoak",
  [ElementarySchools.Stonewall]: "Stonewall",
  [ElementarySchools.Storm]: "Storm",
  [ElementarySchools.Story]: "Story",
  [ElementarySchools.Stowe]: "Stowe",
  [ElementarySchools.Strawn]: "Strawn",
  [ElementarySchools.Street]: "Street",
  [ElementarySchools.Stribling]: "Stribling",
  [ElementarySchools.Stuard]: "Stuard",
  [ElementarySchools.Stultsrd]: "Stultsrd",
  [ElementarySchools.Sudan]: "Sudan",
  [ElementarySchools.SueCrouch]: "Sue Crouch",
  [ElementarySchools.SueEvelynRattan]: "Sue Evelyn Rattan",
  [ElementarySchools.Sugarloaf]: "Sugarloaf",
  [ElementarySchools.Sulphurblu]: "Sulphurblu",
  [ElementarySchools.Sulphursp]: "Sulphursp",
  [ElementarySchools.Sulross]: "Sulross",
  [ElementarySchools.Sumeer]: "Sumeer",
  [ElementarySchools.Sundown]: "Sundown",
  [ElementarySchools.Sundownln]: "Sundownln",
  [ElementarySchools.Sunnyvale]: "Sunnyvale",
  [ElementarySchools.Sunrise]: "Sunrise",
  [ElementarySchools.Sunset]: "Sunset",
  [ElementarySchools.Sunvalley]: "Sunvalley",
  [ElementarySchools.SusieFuentes]: "Susie Fuentes",
  [ElementarySchools.Swift]: "Swift",
  [ElementarySchools.Swinburn]: "Swinburn",
  [ElementarySchools.Sycamore]: "Sycamore",
  [ElementarySchools.Syrratt]: "Syrratt",
  [ElementarySchools.TaSims]: "Ta Sims",
  [ElementarySchools.Tadlock]: "Tadlock",
  [ElementarySchools.Taft]: "Taft",
  [ElementarySchools.Tally]: "Tally",
  [ElementarySchools.Tanglewood]: "Tanglewood",
  [ElementarySchools.Tarver]: "Tarver",
  [ElementarySchools.Tarverrend]: "Tarverrend",
  [ElementarySchools.Tatum]: "Tatum",
  [ElementarySchools.Taylor]: "Taylor",
  [ElementarySchools.Tennessee]: "Tennessee",
  [ElementarySchools.Terrell]: "Terrell",
  [ElementarySchools.Terry]: "Terry",
  [ElementarySchools.TgMccord]: "Tg Mccord",
  [ElementarySchools.TheMeadows]: "The Meadows",
  [ElementarySchools.ThePhilippines]: "The Philippines",
  [ElementarySchools.Thevillage]: "Thevillage",
  [ElementarySchools.Thomas]: "Thomas",
  [ElementarySchools.Thompson]: "Thompson",
  [ElementarySchools.ThompsonLearningCenter]: "Thompson Learning Center",
  [ElementarySchools.Thornton]: "Thornton",
  [ElementarySchools.Thousandoa]: "Thousandoa",
  [ElementarySchools.Thrall]: "Thrall",
  [ElementarySchools.Threeriver]: "Threeriver",
  [ElementarySchools.Threeway]: "Threeway",
  [ElementarySchools.Throckmort]: "Throckmort",
  [ElementarySchools.Thunderbird]: "Thunderbird",
  [ElementarySchools.ThurgoodMarshall]: "Thurgood Marshall",
  [ElementarySchools.Tibbals]: "Tibbals",
  [ElementarySchools.Tice]: "Tice",
  [ElementarySchools.Tierradels]: "Tierradels",
  [ElementarySchools.TimberCreek]: "Timber Creek",
  [ElementarySchools.Timbercreek]: "Timbercreek",
  [ElementarySchools.Timberline]: "Timberline",
  [ElementarySchools.TimberwoodPark]: "Timberwood Park",
  [ElementarySchools.Timpson]: "Timpson",
  [ElementarySchools.Tioga]: "Tioga",
  [ElementarySchools.Tipps]: "Tipps",
  [ElementarySchools.Tisinger]: "Tisinger",
  [ElementarySchools.Titche]: "Titche",
  [ElementarySchools.Tobias]: "Tobias",
  [ElementarySchools.Tolar]: "Tolar",
  [ElementarySchools.Tolbert]: "Tolbert",
  [ElementarySchools.TomBean]: "Tom Bean",
  [ElementarySchools.Tornillo]: "Tornillo",
  [ElementarySchools.Tosch]: "Tosch",
  [ElementarySchools.Towncenter]: "Towncenter",
  [ElementarySchools.Townley]: "Townley",
  [ElementarySchools.Townsell]: "Townsell",
  [ElementarySchools.Tradewind]: "Tradewind",
  [ElementarySchools.Travis]: "Travis",
  [ElementarySchools.Trenton]: "Trenton",
  [ElementarySchools.Trimmier]: "Trimmier",
  [ElementarySchools.Trinidad]: "Trinidad",
  [ElementarySchools.Troup]: "Troup",
  [ElementarySchools.Troy]: "Troy",
  [ElementarySchools.Truett]: "Truett",
  [ElementarySchools.TurnerWj]: "Turner  Wj",
  [ElementarySchools.Turneradel]: "Turneradel",
  [ElementarySchools.Turnermart]: "Turnermart",
  [ElementarySchools.Twain]: "Twain",
  [ElementarySchools.Tye]: "Tye",
  [ElementarySchools.Tyler]: "Tyler",
  [ElementarySchools.Tynan]: "Tynan",
  [ElementarySchools.Underwood]: "Underwood",
  [ElementarySchools.UnionPark]: "Union Park",
  [ElementarySchools.Uniongrove]: "Uniongrove",
  [ElementarySchools.Unionhill]: "Unionhill",
  [ElementarySchools.University]: "University",
  [ElementarySchools.Urbanpark]: "Urbanpark",
  [ElementarySchools.Valley]: "Valley",
  [ElementarySchools.ValleyMil]: "Valley Mil",
  [ElementarySchools.ValleyRanch]: "Valley Ranch",
  [ElementarySchools.ValleyRidge]: "Valley Ridge",
  [ElementarySchools.ValleyView]: "Valley View",
  [ElementarySchools.Valleycree]: "Valleycree",
  [ElementarySchools.Valleyranc]: "Valleyranc",
  [ElementarySchools.Valleyview]: "Valleyview",
  [ElementarySchools.VanAlstyne]: "Van Alstyne",
  [ElementarySchools.VanBuren]: "Van Buren",
  [ElementarySchools.VanZandt]: "Van Zandt",
  [ElementarySchools.Vandagrif]: "Vandagrif",
  [ElementarySchools.Vanvleck]: "Vanvleck",
  [ElementarySchools.Vaughan]: "Vaughan",
  [ElementarySchools.VAUGHN]: "VAUGHN",
  [ElementarySchools.Vega]: "Vega",
  [ElementarySchools.Velasco]: "Velasco",
  [ElementarySchools.Venablevil]: "Venablevil",
  [ElementarySchools.Venus]: "Venus",
  [ElementarySchools.Veribest]: "Veribest",
  [ElementarySchools.Vernon]: "Vernon",
  [ElementarySchools.Versiawill]: "Versiawill",
  [ElementarySchools.Vestal]: "Vestal",
  [ElementarySchools.Vickery]: "Vickery",
  [ElementarySchools.Vickerymea]: "Vickerymea",
  [ElementarySchools.Vidor]: "Vidor",
  [ElementarySchools.Vilas]: "Vilas",
  [ElementarySchools.VintageOaksArea]: "Vintage Oaks Area",
  [ElementarySchools.Virginia]: "Virginia",
  [ElementarySchools.Viridian]: "Viridian",
  [ElementarySchools.Vistadelso]: "Vistadelso",
  [ElementarySchools.Vistahills]: "Vistahills",
  [ElementarySchools.Vitovsky]: "Vitovsky",
  [ElementarySchools.Vogel]: "Vogel",
  [ElementarySchools.Voigt]: "Voigt",
  [ElementarySchools.Waco]: "Waco",
  [ElementarySchools.Wainwright]: "Wainwright",
  [ElementarySchools.WakeVillage]: "Wake Village",
  [ElementarySchools.Wakefield]: "Wakefield",
  [ElementarySchools.Walker]: "Walker",
  [ElementarySchools.WalkerVanguard]: "Walker Vanguard",
  [ElementarySchools.Walkercrk]: "Walkercrk",
  [ElementarySchools.Wallace]: "Wallace",
  [ElementarySchools.WallyWatkins]: "Wally Watkins",
  [ElementarySchools.WalnutCreek]: "Walnut Creek",
  [ElementarySchools.WalnutGrove]: "Walnut Grove",
  [ElementarySchools.WalnutSp]: "Walnut Sp",
  [ElementarySchools.WalnutSprings]: "Walnut Springs",
  [ElementarySchools.Walnutbend]: "Walnutbend",
  [ElementarySchools.Walnuthill]: "Walnuthill",
  [ElementarySchools.Walnutsp]: "Walnutsp",
  [ElementarySchools.Walsh]: "Walsh",
  [ElementarySchools.WaltDisney]: "Walt Disney",
  [ElementarySchools.Walzem]: "Walzem",
  [ElementarySchools.Ward]: "Ward",
  [ElementarySchools.Ware]: "Ware",
  [ElementarySchools.Warren]: "Warren",
  [ElementarySchools.Washington]: "Washington",
  [ElementarySchools.Waskom]: "Waskom",
  [ElementarySchools.Watauga]: "Watauga",
  [ElementarySchools.Waterford]: "Waterford",
  [ElementarySchools.Waters]: "Waters",
  [ElementarySchools.Watts]: "Watts",
  [ElementarySchools.Waverlypar]: "Waverlypar",
  [ElementarySchools.WayneACox]: "Wayne A Cox",
  [ElementarySchools.Weatherfor]: "Weatherfor",
  [ElementarySchools.Webb]: "Webb",
  [ElementarySchools.Webster]: "Webster",
  [ElementarySchools.Wedgeworth]: "Wedgeworth",
  [ElementarySchools.Weinert]: "Weinert",
  [ElementarySchools.Weiss]: "Weiss",
  [ElementarySchools.Welch]: "Welch",
  [ElementarySchools.Wellington]: "Wellington",
  [ElementarySchools.Wells]: "Wells",
  [ElementarySchools.Wellsbranc]: "Wellsbranc",
  [ElementarySchools.West]: "West",
  [ElementarySchools.WestEnd]: "West End",
  [ElementarySchools.WestSabine]: "West Sabine",
  [ElementarySchools.WestTexas]: "West Texas",
  [ElementarySchools.WestUniversity]: "West University",
  [ElementarySchools.WestVirginia]: "West Virginia",
  [ElementarySchools.Westavenue]: "Westavenue",
  [ElementarySchools.Westbirdvi]: "Westbirdvi",
  [ElementarySchools.Westbrook]: "Westbrook",
  [ElementarySchools.Westcliff]: "Westcliff",
  [ElementarySchools.Westcreek]: "Westcreek",
  [ElementarySchools.WesternPlateau]: "Western Plateau",
  [ElementarySchools.Westernhil]: "Westernhil",
  [ElementarySchools.Westhandle]: "Westhandle",
  [ElementarySchools.Westhurst]: "Westhurst",
  [ElementarySchools.Westlawn]: "Westlawn",
  [ElementarySchools.Westmain]: "Westmain",
  [ElementarySchools.WestnHill]: "Westn Hill",
  [ElementarySchools.Westoso]: "Westoso",
  [ElementarySchools.Westpark]: "Westpark",
  [ElementarySchools.Westrusk]: "Westrusk",
  [ElementarySchools.Westside]: "Westside",
  [ElementarySchools.Westward]: "Westward",
  [ElementarySchools.Westwind]: "Westwind",
  [ElementarySchools.Westwood]: "Westwood",
  [ElementarySchools.Wetmore]: "Wetmore",
  [ElementarySchools.Wheatley]: "Wheatley",
  [ElementarySchools.Wheeler]: "Wheeler",
  [ElementarySchools.Whitaker]: "Whitaker",
  [ElementarySchools.White]: "White",
  [ElementarySchools.Whiteface]: "Whiteface",
  [ElementarySchools.Whiteoak]: "Whiteoak",
  [ElementarySchools.Whiterock]: "Whiterock",
  [ElementarySchools.Whitesboro]: "Whitesboro",
  [ElementarySchools.Whitestone]: "Whitestone",
  [ElementarySchools.Whitewrigh]: "Whitewrigh",
  [ElementarySchools.Whitis]: "Whitis",
  [ElementarySchools.Whitleyrd]: "Whitleyrd",
  [ElementarySchools.Whitney]: "Whitney",
  [ElementarySchools.Whitt]: "Whitt",
  [ElementarySchools.Whittier]: "Whittier",
  [ElementarySchools.Wiederstein]: "Wiederstein",
  [ElementarySchools.Wildorado]: "Wildorado",
  [ElementarySchools.Willett]: "Willett",
  [ElementarySchools.Williampas]: "Williampas",
  [ElementarySchools.Williams]: "Williams",
  [ElementarySchools.Williamson]: "Williamson",
  [ElementarySchools.Willislane]: "Willislane",
  [ElementarySchools.WillowBend]: "Willow Bend",
  [ElementarySchools.WillowCreek]: "Willow Creek",
  [ElementarySchools.WillowWood]: "Willow Wood",
  [ElementarySchools.Willowrun]: "Willowrun",
  [ElementarySchools.Willowspri]: "Willowspri",
  [ElementarySchools.Wills]: "Wills",
  [ElementarySchools.Wilmerhutc]: "Wilmerhutc",
  [ElementarySchools.Wilmeth]: "Wilmeth",
  [ElementarySchools.Wilshire]: "Wilshire",
  [ElementarySchools.Wilson]: "Wilson",
  [ElementarySchools.Wimbish]: "Wimbish",
  [ElementarySchools.Windcrest]: "Windcrest",
  [ElementarySchools.WindsongRanch]: "Windsong Ranch",
  [ElementarySchools.Windsor]: "Windsor",
  [ElementarySchools.WindsorPark]: "Windsor Park",
  [ElementarySchools.Windthorst]: "Windthorst",
  [ElementarySchools.Winfield]: "Winfield",
  [ElementarySchools.Winnetka]: "Winnetka",
  [ElementarySchools.Winnsboro]: "Winnsboro",
  [ElementarySchools.Winona]: "Winona",
  [ElementarySchools.Winston]: "Winston",
  [ElementarySchools.Winters]: "Winters",
  [ElementarySchools.Wise]: "Wise",
  [ElementarySchools.Withers]: "Withers",
  [ElementarySchools.Woden]: "Woden",
  [ElementarySchools.Wolfecity]: "Wolfecity",
  [ElementarySchools.Wolflin]: "Wolflin",
  [ElementarySchools.Wolford]: "Wolford",
  [ElementarySchools.Wood]: "Wood",
  [ElementarySchools.Woodcreek]: "Woodcreek",
  [ElementarySchools.Woodcrest]: "Woodcrest",
  [ElementarySchools.Wooden]: "Wooden",
  [ElementarySchools.Woodlake]: "Woodlake",
  [ElementarySchools.Woodlandht]: "Woodlandht",
  [ElementarySchools.Woodlands]: "Woodlands",
  [ElementarySchools.Woodlandsp]: "Woodlandsp",
  [ElementarySchools.Woodlawn]: "Woodlawn",
  [ElementarySchools.Woodlawnhl]: "Woodlawnhl",
  [ElementarySchools.Woodridge]: "Woodridge",
  [ElementarySchools.Woodriver]: "Woodriver",
  [ElementarySchools.Woods]: "Woods",
  [ElementarySchools.Woodsboro]: "Woodsboro",
  [ElementarySchools.Woodson]: "Woodson",
  [ElementarySchools.Woodstone]: "Woodstone",
  [ElementarySchools.Woodville]: "Woodville",
  [ElementarySchools.Woodway]: "Woodway",
  [ElementarySchools.WorthHeig]: "Worth Heig",
  [ElementarySchools.Wortham]: "Wortham",
  [ElementarySchools.Wright]: "Wright",
  [ElementarySchools.Wyatt]: "Wyatt",
  [ElementarySchools.WylieEast]: "Wylie East",
  [ElementarySchools.WylieWest]: "Wylie West",
  [ElementarySchools.Wyoming]: "Wyoming",
  [ElementarySchools.Yale]: "Yale",
  [ElementarySchools.Yantis]: "Yantis",
  [ElementarySchools.Yeager]: "Yeager",
  [ElementarySchools.Yermo]: "Yermo",
  [ElementarySchools.Young]: "Young",
  [ElementarySchools.Ysleta]: "Ysleta",
  [ElementarySchools.Zachry]: "Zachry",
  [ElementarySchools.Zaragoza]: "Zaragoza",
  [ElementarySchools.Zavala]: "Zavala",
  [ElementarySchools.Zephyr]: "Zephyr",
  [ElementarySchools.RosamondSherley]: "Rosamond Sherley"
};
