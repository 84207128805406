import axios from "axios";

import { MarketCode } from "@homesusa/core";
import { getGatewayMarket } from "core/utils";

const gatewayUrl = process.env.REACT_APP_API_GATEWAY;

export const approveXmlCommunity =
  (market: MarketCode) =>
  async (id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/sale-communities`;
    return axios.patch(`${apiUrl}/${id}/approve`);
  };

export const approveXmlPlan =
  (market: MarketCode) =>
  async (id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;
    return axios.patch(`${apiUrl}/${id}/approve`);
  };

export const rejectXmlPlan =
  (market: MarketCode) =>
  async (id: string): Promise<void> => {
    const gatewayMarket = getGatewayMarket(market);
    const apiUrl = `${gatewayUrl}/api/${gatewayMarket}/plans`;
    return axios.patch(`${apiUrl}/${id}/reject`);
  };
